var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "h-100", attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        { staticClass: "header mt-3 pb-3 w-100", attrs: { id: "top-area" } },
        [
          _c("b-col", { attrs: { cols: "3", md: "12" } }, [
            _c("h2", [_vm._v(_vm._s(_vm.$t("index.logging")))]),
            _c("p", { staticClass: "d-none d-md-block" }, [
              _vm._v(_vm._s(_vm.$t("tutorial.loggingExample")))
            ])
          ]),
          _c(
            "b-col",
            { staticClass: "d-md-none" },
            [
              _c("multiselect", {
                attrs: {
                  options: _vm.filteredBookings,
                  "track-by": "id",
                  "custom-label": _vm.labelGenerator,
                  value: _vm.compSelected,
                  searchable: false,
                  "allow-empty": false
                },
                on: { select: _vm.selectBooking }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.loading
        ? _c("b-row", { attrs: { id: "content-area" } }, [
            _c("div", { staticClass: "spinner" })
          ])
        : _c(
            "b-row",
            { attrs: { id: "content-area" } },
            [
              _c(
                "b-col",
                {
                  ref: "sidebar",
                  staticClass: "d-none d-md-block h-100 overflow list",
                  attrs: { md: "4", lg: "2" }
                },
                [
                  _c("b-form-input", {
                    staticClass: "search",
                    attrs: {
                      id: "search",
                      placeholder: _vm.$t("actions.search")
                    },
                    model: {
                      value: _vm.search,
                      callback: function($$v) {
                        _vm.search = $$v
                      },
                      expression: "search"
                    }
                  }),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-success",
                      staticStyle: { width: "100%" },
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.togglePaid()
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("buttons.paidOnly")) +
                          " " +
                          _vm._s(_vm.paid === true ? "☑" : "☐") +
                          " "
                      )
                    ]
                  ),
                  _vm._l(_vm.filteredBookings, function(booking) {
                    return _c(
                      "div",
                      {
                        key: booking.id,
                        staticClass: "pt-3 pb-3 item",
                        class: { selected: _vm.compSelected.id === booking.id },
                        attrs: { id: "l_" + booking.id },
                        on: {
                          click: function($event) {
                            return _vm.selectBooking(booking)
                          }
                        }
                      },
                      [
                        _c("p", { staticClass: "mb-0" }, [
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v(_vm._s(booking.campaignName))
                          ]),
                          _vm._v(" on")
                        ]),
                        _c("p", { staticClass: "mb-0 font-weight-bold" }, [
                          _vm._v(_vm._s(booking.displayName))
                        ]),
                        _c("p", [_vm._v("Start: " + _vm._s(booking.startDate))])
                      ]
                    )
                  })
                ],
                2
              ),
              _c(
                "b-col",
                {
                  staticClass: "h-100 overflow",
                  attrs: { cols: "12", md: "8", lg: "10" }
                },
                [
                  _c(
                    "transition",
                    { attrs: { name: "fade" } },
                    [
                      _vm.compSelected !== {} && _vm.filteredBookings.length > 0
                        ? _c("router-view", {
                            attrs: { booking: _vm.compSelected }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }