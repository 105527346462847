var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gallery" },
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "addimage",
            "hide-footer": "",
            title: _vm.$t("displays.addImage")
          }
        },
        [
          _c(
            "b-form",
            [
              _c("b-form-file", {
                attrs: {
                  accept: "image/*",
                  state: Boolean(_vm.file),
                  placeholder: "Choose an image or drop it here...",
                  "drop-placeholder": "Drop file here..."
                },
                model: {
                  value: _vm.file,
                  callback: function($$v) {
                    _vm.file = $$v
                  },
                  expression: "file"
                }
              }),
              _c(
                "b-button",
                {
                  staticClass: "float-right mt-1",
                  attrs: { variant: "primary", disabled: _vm.file === null },
                  on: { click: _vm.uploadImages }
                },
                [_vm._v("Upload ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        [
          [
            !_vm.uploading
              ? _c(
                  "b-carousel",
                  {
                    attrs: {
                      id: "display-gallery",
                      interval: _vm.edit ? 0 : 4000,
                      controls: _vm.imageIndex.length > 1,
                      indicators: _vm.imageIndex.length > 1,
                      fade: "",
                      "img-height": "300",
                      background: "black"
                    },
                    model: {
                      value: _vm.selected,
                      callback: function($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected"
                    }
                  },
                  [
                    _vm.imageIndex.length > 0
                      ? _c(
                          "div",
                          _vm._l(_vm.imageIndex, function(image, index) {
                            return _c("b-carousel-slide", {
                              key: index,
                              attrs: { id: String(index) },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "img",
                                    fn: function() {
                                      return [
                                        _c("b-img", {
                                          staticClass: "d-block",
                                          attrs: {
                                            height: "300",
                                            src: _vm.toUrl(image.image),
                                            center: ""
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          }),
                          1
                        )
                      : _c(
                          "b-carousel-slide",
                          {
                            attrs: {
                              caption: "No Image",
                              "img-alt": "No Images",
                              "img-height": "300"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "img",
                                  fn: function() {
                                    return [
                                      _c("b-img", {
                                        staticClass: "d-block",
                                        attrs: {
                                          height: "300",
                                          src:
                                            "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22300%22%20height%3D%22300%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20%25%7Bw%7D%20%25%7Bh%7D%22%20preserveAspectRatio%3D%22none%22%3E%3Crect%20width%3D%22100%25%22%20height%3D%22100%25%22%20style%3D%22fill%3Atransparent%3B%22%3E%3C%2Frect%3E%3C%2Fsvg%3E",
                                          center: ""
                                        }
                                      })
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              1131601130
                            )
                          },
                          [
                            _c("p", [
                              _vm._v(
                                " Please add some images of your display if you intend to make it public. "
                              )
                            ])
                          ]
                        )
                  ],
                  1
                )
              : _vm._e()
          ],
          _c("div", [
            _c(
              "div",
              {
                staticClass: "mt-1",
                staticStyle: { display: "flex", "justify-content": "center" }
              },
              [
                _vm.edit
                  ? _c(
                      "b-button-group",
                      { staticClass: "mx-1", attrs: { size: "sm" } },
                      [
                        _vm.imageIndex.length > 0
                          ? _c(
                              "b-button",
                              {
                                attrs: { variant: "danger" },
                                on: {
                                  click: function($event) {
                                    return _vm.deleteImage(
                                      _vm.imageIndex[_vm.selected]
                                    )
                                  }
                                }
                              },
                              [_vm._v("Delete ")]
                            )
                          : _vm._e(),
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.$bvModal.show("addimage")
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("displays.addImage")))]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }