var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "p-0 m-0" },
    [
      _c(
        "b-col",
        { staticClass: "m-2" },
        [
          _c("multiselect", {
            attrs: {
              placeholder: "🔍 " + _vm.$t("type to search"),
              options: _vm.transformedTeams,
              "track-by": "teamId",
              label: "name",
              "allow-empty": false
            },
            on: { input: _vm.getTeamID },
            model: {
              value: _vm.selectedTeam,
              callback: function($$v) {
                _vm.selectedTeam = $$v
              },
              expression: "selectedTeam"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }