var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c("h4", [_vm._v(_vm._s(_vm.$t("user.update")))]),
          _vm.errors.length > 0
            ? _c("div", { staticClass: "col-12 alert alert-danger" }, [
                _c(
                  "ul",
                  _vm._l(_vm.errors, function(error, index) {
                    return _c("li", { key: index }, [_vm._v(_vm._s(error))])
                  }),
                  0
                )
              ])
            : _vm._e(),
          _c(
            "b-form-group",
            {
              attrs: { label: _vm.$t("user.username"), "label-for": "username" }
            },
            [
              _c("b-form-input", {
                attrs: { id: "username" },
                model: {
                  value: _vm.update.username,
                  callback: function($$v) {
                    _vm.$set(_vm.update, "username", $$v)
                  },
                  expression: "update.username"
                }
              })
            ],
            1
          ),
          _c(
            "b-form-group",
            { attrs: { label: _vm.$t("user.email"), "label-for": "email" } },
            [
              _c("b-form-input", {
                attrs: { id: "email" },
                model: {
                  value: _vm.update.email,
                  callback: function($$v) {
                    _vm.$set(_vm.update, "email", $$v)
                  },
                  expression: "update.email"
                }
              })
            ],
            1
          ),
          !_vm.$auth.user.admin
            ? _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t("user.currentPassword"),
                    "label-for": "current-password"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: { id: "current-password", type: "password" },
                    model: {
                      value: _vm.update.password,
                      callback: function($$v) {
                        _vm.$set(_vm.update, "password", $$v)
                      },
                      expression: "update.password"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.$t("user.newPassword"),
                        description: "Must be at least 8 characters.",
                        "label-for": "new-password"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "new-password",
                          type: "password",
                          state: _vm.update.newPassword
                            ? _vm.update.newPassword.length >= 8
                            : null
                        },
                        model: {
                          value: _vm.update.newPassword,
                          callback: function($$v) {
                            _vm.$set(_vm.update, "newPassword", $$v)
                          },
                          expression: "update.newPassword"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.$t("user.confirmPassword"),
                        description: "Re-type the password.",
                        "label-for": "confirm-password"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "confirm-password",
                          type: "password",
                          state:
                            _vm.update.newPassword &&
                            _vm.update.confirmPassword &&
                            _vm.update.newPassword.length >= 8
                              ? _vm.update.confirmPassword ===
                                _vm.update.newPassword
                              : null
                        },
                        model: {
                          value: _vm.update.confirmPassword,
                          callback: function($$v) {
                            _vm.$set(_vm.update, "confirmPassword", $$v)
                          },
                          expression: "update.confirmPassword"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-3 float-right",
              attrs: { variant: "primary" },
              on: {
                click: function($event) {
                  return _vm.updateAccount()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("buttons.update")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }