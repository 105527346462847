var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { attrs: { header: _vm.$t("event.log") } },
    [
      _c("b-card-text", { staticStyle: {} }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "loader"
          },
          [_c("div", { staticClass: "spinner" })]
        ),
        _vm.logs.length > 0
          ? _c("div", [
              _c(
                "div",
                [
                  _c(
                    "b-table-simple",
                    { attrs: { striped: "" } },
                    [
                      _c(
                        "b-thead",
                        [
                          _c(
                            "b-tr",
                            [
                              _c("b-th", [
                                _vm._v(_vm._s(_vm.$t("dateTime.date")))
                              ]),
                              _c("b-th", [_vm._v(_vm._s(_vm.$t("event.ip")))]),
                              _c("b-th", [
                                _vm._v(_vm._s(_vm.$t("event.action")))
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-tbody",
                        _vm._l(_vm.logs, function(log) {
                          return _c(
                            "b-tr",
                            { key: log.timestamp },
                            [
                              _c("b-td", [_vm._v(_vm._s(log.timestamp))]),
                              _c("b-td", [_vm._v(_vm._s(log.ip))]),
                              _c("b-td", [_vm._v(_vm._s(log.message))])
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          : _c("div", [_c("span", [_vm._v(_vm._s(_vm.$t("message.noLogs")))])])
      ]),
      _vm.logsMeta.totalPages > 1
        ? _c(
            "div",
            { staticClass: "card-footer" },
            [
              _c(
                "b-button-group",
                [
                  _vm.logsMeta.totalPages > 1 && _vm.currentPage != 1
                    ? _c(
                        "b-button",
                        {
                          attrs: { variant: "outline-secondary" },
                          on: {
                            click: function($event) {
                              return _vm.fetchLogs(_vm.logsMeta.previousPage)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("buttons.previous")))]
                      )
                    : _vm._e(),
                  _vm.logsMeta.totalPages > 1 &&
                  _vm.currentPage != _vm.logsMeta.totalPages
                    ? _c(
                        "b-button",
                        {
                          attrs: { variant: "outline-secondary" },
                          on: {
                            click: function($event) {
                              return _vm.fetchLogs(_vm.logsMeta.nextPage)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("buttons.next")))]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }