<template>
  <div id="app" class="w-100 h-100">
    <b-navbar
      id="navbar"
      toggleable="lg"
      type="dark"
      variant="dark"
      fixed="top"
    >
      <b-navbar-brand :href="publicUrl"
        ><img src="/static/logo.svg" height="34" />
        <b-icon
          animation="cylon-vertical"
          class="ml-2"
          v-if="isTest"
          icon="exclamation-circle-fill"
          variant="warning"
          font-scale="1.5"
        ></b-icon>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item v-if="$auth.user.authenticated" right>
            <b-nav-item-dropdown right>
              <template slot="button-content">
                <v-icon name="user" scale="1.5" class="icon" />
                {{ $auth.user.username }}</template
              >
              <b-dropdown-item @click="$router.push({ path: '/account' })">{{
                $t("account.my")
              }}</b-dropdown-item>
              <b-dropdown-item @click="logout()">{{
                $t("account.logout")
              }}</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-nav-item>
          <b-nav-item v-if="!$auth.user.authenticated" right>
            <a href="#" class="nav-link" v-b-modal.login>
              <v-icon name="sign-in-alt" scale="1.5" class="icon" />
              {{ $t("account.login") }}
            </a>
          </b-nav-item>
          <b-nav-item v-if="!$auth.user.authenticated" right>
            <a href="#" class="nav-link" v-b-modal.register>
              <v-icon name="user-plus" scale="1.5" class="icon" />
              {{ $t("account.register") }}
            </a>
          </b-nav-item>
          <b-nav-item right href="https://support.adverpost.com/">
            <a
              target="_blank"
              href="https://support.adverpost.com/"
              class="nav-link"
            >
              <v-icon name="question-circle" scale="1.5" class="icon" />
              {{ $t("dashboard.common.support") }}
            </a>
          </b-nav-item>
          <b-nav-item right>
            <b-nav-item-dropdown right>
              <template slot="button-content">
                <v-icon name="globe" scale="1.5" class="icon" />
                {{ languages[this.$i18n.locale] }}</template
              >
              <b-dropdown-item @click="lang('en')">English</b-dropdown-item>
              <b-dropdown-item @click="lang('zh')">中文</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-nav-item>
          <div
            v-if="$auth.user.authenticated"
            class="navigation-box navbar-toggler"
          >
            <b-nav-form class="w-100" form-class="w-100">
              <team-selector
                class="w-100"
                @updateTeam="updateTeam"
                :resetTeamsTrigger="resetTeamsTrigger"
              />
            </b-nav-form>
            <b-nav-item
              v-for="(link, index) in links"
              :to="link.uri"
              @click="$emit('hide')"
              :key="index"
            >
              <div v-if="!link.children" class="navigation-box-item">
                <v-icon :name="link.icon" scale="1.5" class="mr-2" />
                {{ $t(link.key) }}
              </div>
              <div v-if="link.children" class="navigation-box-item">
                <v-icon :name="link.icon" scale="1.5" class="mr-2" />
                {{ $t(link.key) }}
                <b-nav-item
                  v-for="(link, index) in link.children"
                  class="ml-3"
                  :to="link.uri"
                  @click="$emit('hide')"
                  :key="index"
                >
                  <div class="navigation-box-item">
                    <v-icon :name="link.icon" scale="1.5" class="mr-2" />
                    {{ $t(link.key) }}
                  </div>
                </b-nav-item>
              </div>
            </b-nav-item>
          </div>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-container fluid class="pl-0 pb-0 pr-0 h-100" @click="unDrop()">
      <div class="row m-0 h-100">
        <sidebar
          :links="links"
          :active="sidebarActive"
          :user="$auth.user"
          :resetTeamsTrigger="resetTeamsTrigger"
          @updateTeam="updateTeam"
          @hide="sidebarActive = false"
        ></sidebar>
        <div
          class="col-12 pb-0 pr-0 pl-0 pl-md-1 h-100"
          v-bind:class="{
            faded: sidebarActive,
            'col-md-12 col-lg-10': $auth.user.authenticated,
          }"
        >
          <transition name="fade">
            <router-view :activeTeam="activeTeam"></router-view>
          </transition>
        </div>
      </div>
    </b-container>
    <notifications></notifications>
    <b-modal id="login" title="" hide-header no-fade hide-footer>
      <login @resetTeams="$nextTick(() => resetTeamsTrigger++)"></login>
    </b-modal>
    <b-modal id="register" title="" centered hide-header no-fade hide-footer>
      <register></register>
    </b-modal>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import Login from "@/lib/auth/components/Login.vue";
import Register from "@/lib/auth/components/Register.vue";
import TeamSelector from "@/components/TeamSelector.vue";

import _ from "lodash";
import verge from "verge";

import "vue-awesome/icons/user";
import "vue-awesome/icons/sign-in-alt";
import "vue-awesome/icons/user-plus";
import "vue-awesome/icons/question-circle";
import "vue-awesome/icons/globe";
import "vue-awesome/icons/cogs";
import "vue-awesome/icons/calendar";
import "vue-awesome/icons/table";
import "vue-awesome/icons/map";
import "vue-awesome/icons/film";
import "vue-awesome/icons/code-branch";
import "vue-awesome/icons/users";
import "vue-awesome/icons/shopping-cart";
import "vue-awesome/icons/clipboard-list";
import "vue-awesome/icons/sticky-note";
import "vue-awesome/icons/photo-video";
import "vue-awesome/icons/desktop";

export default {
  name: "App",
  components: {
    TeamSelector,
    Sidebar,
    Register,
    Login,
  },
  data: function () {
    return {
      publicUrl: process.env.VUE_APP_PUBLIC_URL,
      languages: {
        en: "English",
        zh: "中文",
      },
      resetTeamsTrigger: 0,
      activeTeam: { name: "none selected", id: Number(0) },
      cart: this.$cart.store,
      links: [
        {
          key: "index.dashboard",
          uri: "/dashboard",
          icon: "cogs",
        },
        {
          key: "schedule.map",
          uri: "/scheduling/map",
          icon: "map",
          noadmin: true,
        },
        {
          key: "schedule.ipam",
          uri: "/scheduling/ipam",
          icon: "table",
        },
        {
          key: "index.displaymgt",
          uri: "/displays",
          icon: "cogs",
        },
        {
          key: "index.sources",
          uri: "/sources",
          icon: "code-branch",
        },
        {
          key: "index.campaigns",
          uri: "/campaigns",
          icon: "film",
        },

        {
          key: "index.media",
          uri: "/media",
          icon: "photo-video",
        },
        {
          key: "index.logging",
          uri: "/logging",
          icon: "clipboard-list",
        },
        {
          key: "index.teammgt",
          uri: "/teams",
          // admin: true,
          icon: "users",
        },
        {
          key: "index.admin",
          icon: "cogs",
          admin: true,
          children: [
            {
              key: "index.usermgt",
              uri: "/users",
              icon: "user",
            },
            {
              key: "index.news",
              uri: "/news",
              icon: "sticky-note",
            },
          ],
        },
      ],
      sidebarActive: false,
      dropdownOpen: false,
      langDropdown: false,
      isTest: process.env.VUE_APP_API_ROOT.includes("adverdev"),
      beforeUnload_time: 0,
      gap_time: 0,
      logoutTimer: null,
      timeoutInactivity: process.env.VUE_APP_INACTIVITY_OUT * 60 * 1000,
    };
  },
  mounted() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("beforeunload", this.handleBeforeUnload);
    window.addEventListener("unload", this.handleUnload);
    this.resetTeamsTrigger++;
    this.startLogoutTimer();
    window.addEventListener("mousemove", () => this.resetLogoutTimer());
    window.addEventListener("keypress", () => this.resetLogoutTimer());
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
    window.removeEventListener("unload", this.handleUnload);
    clearTimeout(this.timeoutInactivity);
    window.removeEventListener("mousemove", () => this.resetLogoutTimer());
    window.removeEventListener("keypress", () => this.resetLogoutTimer());
  },
  watch: {},
  methods: {
    triggerUpdate() {
      this.resetTeamsTrigger++;
    },
    updateTeam(team) {
      this.resetTeamsTrigger++;
      this.activeTeam = team;
    },
    handleResize: _.debounce(function () {
      // unfortunately there is no js-free way to do this because the navbar element in bootstrap 4 does not have a height variable
      let container = document.querySelector(".container-fluid");
      let navbar = document.getElementById("navbar");
      container.style.paddingTop =
        parseInt(verge.rectangle(navbar).height) + "px";
    }, 100),
    unDrop() {
      this.dropdownOpen = false;
      this.langDropdown = false;
    },
    lang(locale) {
      localStorage.setItem("locale", locale);
      this.$i18n.locale = locale;
      this.$router.go(0);
    },
    logout: function () {
      this.resetTeamsTrigger++;
      this.dropdownOpen = false;
      this.$cart.store = [];
      this.$auth.logout("/");
    },
    toggleShow() {
      event.target.classList.toggle("show");
    },

    handleBeforeUnload() {
      this.beforeUnload_time = new Date().getTime();
    },

    handleUnload() {
      this.gap_time = new Date().getTime() - this.beforeUnload_time;
      if (this.gap_time <= 5) {
        this.logout();
      }
    },

    startLogoutTimer() {
      this.logoutTimer = setTimeout(() => {
        this.logout();
      }, this.timeoutInactivity);
    },

    resetLogoutTimer() {
      if (this.$auth?.user?.id !== 0) {
        console.log(`mouse move || key press`);
        clearTimeout(this.logoutTimer);
        this.startLogoutTimer();
        let curT = new Date();
        console.log(
          `logout is: ${curT.getHours()}:${curT.getMinutes()}:${curT.getSeconds()} + ${
            process.env.VUE_APP_INACTIVITY_OUT
          } minutes`
        );
      }
    },
  },
};
</script>

<style lang="scss">
@import "node_modules/bootstrap/scss/_functions.scss";
@import "node_modules/bootstrap/scss/_variables.scss";
@import "node_modules/bootstrap/scss/mixins/_breakpoints.scss";

@include media-breakpoint-up(lg) {
  .dropdown-toggle {
    display: inline-block;
  }

  .link-toggle {
    display: inline-block;
  }

  .logo {
    display: inline-block;
  }
}

@include media-breakpoint-down(md) {
  .nav-link {
    padding-right: 0;
    padding-left: 0;
  }
}

@include media-breakpoint-up(xs) {
  .toggle-center {
    align-self: center;
    cursor: pointer;
  }

  .fade-enter-active {
    transition: opacity 0.5s;
  }

  .fade-enter {
    opacity: 0;
  }

  .content {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .content.faded {
    background: rgba(0, 0, 0, 0.7);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
}

.navigation-box {
  background-color: white;
  text-align: left;
  font-size: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  overflow-y: scroll;
  max-height: 30em;
}

.navigation-box-item {
  color: black;
  &:hover {
    color: lighten(black, 20%);
    text-decoration: none;
  }
}

.icon {
  color: white;
}

.nav-link {
  &.inline {
    display: inline;
  }
}

a:focus {
  color: darken(white, 20%);
}

.flatpickr-calendar {
  z-index: 1080 !important;
}
</style>
