var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "b-row",
            [
              _c("b-col", { staticClass: "text-center" }, [
                _vm._v("Active Team: "),
                _c("em", [_vm._v(_vm._s(_vm.activeTeam.name))])
              ])
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c("b-form-file", {
                    attrs: {
                      multiple: "",
                      disabled: _vm.queue.length > 0,
                      accept: "audio/*, image/*, video/*",
                      placeholder: "Choose a file or drop it here...",
                      "drop-placeholder": "Drop file here...",
                      "file-name-formatter": _vm.formatNames
                    },
                    model: {
                      value: _vm.files,
                      callback: function($$v) {
                        _vm.files = $$v
                      },
                      expression: "files"
                    }
                  }),
                  _vm.files.length > 0
                    ? _c("h5", { staticClass: "m-2" }, [
                        _vm._v("Selected Files:")
                      ])
                    : _vm._e(),
                  _c(
                    "b-list-group",
                    { staticClass: "mt-2" },
                    _vm._l(_vm.files, function(file) {
                      return _c(
                        "b-list-group-item",
                        {
                          key: file.name,
                          staticClass: "d-flex align-items-center"
                        },
                        [
                          _vm.status[file.name] == "pending"
                            ? _c("b-avatar", {
                                attrs: { variant: "light", icon: "clock" }
                              })
                            : _vm.status[file.name] == "completed"
                            ? _c("b-avatar", {
                                attrs: {
                                  variant: "success",
                                  icon: "check-square"
                                }
                              })
                            : _vm.status[file.name] == "error"
                            ? _c("b-avatar", {
                                attrs: { variant: "danger", icon: "x-circle" }
                              })
                            : _vm.status[file.name] == "uploading"
                            ? _c(
                                "b-avatar",
                                { attrs: { variant: "light" } },
                                [
                                  _c("b-icon", {
                                    staticClass: "h3 align-bottom pt-1",
                                    attrs: {
                                      variant: "primary",
                                      animation: "cylon-vertical",
                                      icon: "cloud-arrow-up"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm.status[file.name] == "processing"
                            ? _c(
                                "b-avatar",
                                { attrs: { variant: "light" } },
                                [
                                  _c("b-icon", {
                                    staticClass: "h3",
                                    attrs: {
                                      variant: "primary",
                                      animation: "spin",
                                      icon: "arrow-clockwise"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "b-col",
                            { attrs: { cols: "9" } },
                            [
                              _c("div", [
                                _vm._v(" " + _vm._s(file.name) + " ")
                              ]),
                              _vm.status[file.name] == "error"
                                ? [
                                    _c("em", [
                                      _vm._v(
                                        "an error occurred when uploading :("
                                      )
                                    ])
                                  ]
                                : [
                                    _vm.status[file.name] == "uploading" ||
                                    _vm.status[file.name] == "pending"
                                      ? _c("b-progress", {
                                          staticClass: "mb-2 mt-1",
                                          attrs: {
                                            value: _vm.progress[file.name],
                                            max: "100"
                                          }
                                        })
                                      : _vm.status[file.name] == "processing"
                                      ? _c("b-progress", {
                                          staticClass: "mb-2 mt-1",
                                          attrs: {
                                            variant: "danger",
                                            value: _vm.remote[file.name],
                                            max: "100"
                                          }
                                        })
                                      : _vm._e()
                                  ]
                            ],
                            2
                          ),
                          _c(
                            "b-col",
                            { staticClass: "m-0 p-0", attrs: { cols: "1" } },
                            [
                              _c("div", [
                                _vm._v(
                                  " (" +
                                    _vm._s(
                                      parseFloat(
                                        (file.size / 1024 / 1024).toFixed(1)
                                      )
                                    ) +
                                    "MB) "
                                )
                              ]),
                              _vm.status[file.name] == "uploading"
                                ? _c(
                                    "b-badge",
                                    {
                                      attrs: {
                                        href: "#",
                                        variant: "warning",
                                        pill: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.cancelFile(file.name)
                                        }
                                      }
                                    },
                                    [_vm._v("CANCEL")]
                                  )
                                : _vm.status[file.name] == "completed"
                                ? _c(
                                    "b-badge",
                                    {
                                      attrs: {
                                        href: "#",
                                        variant: "dark",
                                        pill: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.removeFile(file.name)
                                        }
                                      }
                                    },
                                    [_vm._v("REMOVE")]
                                  )
                                : _vm.status[file.name] == "processing"
                                ? _c("b-badge", {
                                    attrs: {
                                      href: "#",
                                      variant: "dark",
                                      pill: ""
                                    }
                                  })
                                : Object.keys(_vm.queue).length == 0
                                ? _c(
                                    "b-badge",
                                    {
                                      attrs: {
                                        href: "#",
                                        variant: "danger",
                                        pill: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.removeFile(file.name)
                                        }
                                      }
                                    },
                                    [_vm._v("REMOVE")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "mt-4 col-3 btn-block mx-auto",
                      attrs: {
                        disabled: Object.keys(_vm.queue).length > 0,
                        variant: "success",
                        value: "Upload"
                      },
                      on: { click: _vm.upload }
                    },
                    [_vm._v("Upload")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }