var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-th", [
    _c(
      "div",
      { staticClass: "ipam-header text-center" },
      [
        _c(
          "b-link",
          {
            staticClass: "text-dark",
            attrs: {
              to: {
                name: "display-view",
                params: { displayId: _vm.display.displayId }
              }
            }
          },
          [_vm._v(_vm._s(_vm.display.name))]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }