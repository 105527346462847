var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h-100 dashboard p-2" },
    [
      _c("h2", {}, [_vm._v(_vm._s(_vm.$t("dashboard.headers.dashboard")))]),
      _c("h4", {}, [
        _vm._v("for "),
        _c("i", [_vm._v(_vm._s(_vm.activeTeam.name))])
      ]),
      _c("dash", {
        attrs: {
          displays: _vm.filteredDisplays,
          activeTeam: _vm.activeTeam,
          refreshTime: _vm.refreshTime
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }