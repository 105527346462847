var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-100 h-100", attrs: { id: "app" } },
    [
      _c(
        "b-navbar",
        {
          attrs: {
            id: "navbar",
            toggleable: "lg",
            type: "dark",
            variant: "dark",
            fixed: "top"
          }
        },
        [
          _c(
            "b-navbar-brand",
            { attrs: { href: _vm.publicUrl } },
            [
              _c("img", { attrs: { src: "/static/logo.svg", height: "34" } }),
              _vm.isTest
                ? _c("b-icon", {
                    staticClass: "ml-2",
                    attrs: {
                      animation: "cylon-vertical",
                      icon: "exclamation-circle-fill",
                      variant: "warning",
                      "font-scale": "1.5"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
          _c(
            "b-collapse",
            { attrs: { id: "nav-collapse", "is-nav": "" } },
            [
              _c(
                "b-navbar-nav",
                { staticClass: "ml-auto" },
                [
                  _vm.$auth.user.authenticated
                    ? _c(
                        "b-nav-item",
                        { attrs: { right: "" } },
                        [
                          _c(
                            "b-nav-item-dropdown",
                            { attrs: { right: "" } },
                            [
                              _c(
                                "template",
                                { slot: "button-content" },
                                [
                                  _c("v-icon", {
                                    staticClass: "icon",
                                    attrs: { name: "user", scale: "1.5" }
                                  }),
                                  _vm._v(" " + _vm._s(_vm.$auth.user.username))
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.$router.push({
                                        path: "/account"
                                      })
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("account.my")))]
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.logout()
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("account.logout")))]
                              )
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.$auth.user.authenticated
                    ? _c("b-nav-item", { attrs: { right: "" } }, [
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "b-modal",
                                rawName: "v-b-modal.login",
                                modifiers: { login: true }
                              }
                            ],
                            staticClass: "nav-link",
                            attrs: { href: "#" }
                          },
                          [
                            _c("v-icon", {
                              staticClass: "icon",
                              attrs: { name: "sign-in-alt", scale: "1.5" }
                            }),
                            _vm._v(" " + _vm._s(_vm.$t("account.login")) + " ")
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  !_vm.$auth.user.authenticated
                    ? _c("b-nav-item", { attrs: { right: "" } }, [
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "b-modal",
                                rawName: "v-b-modal.register",
                                modifiers: { register: true }
                              }
                            ],
                            staticClass: "nav-link",
                            attrs: { href: "#" }
                          },
                          [
                            _c("v-icon", {
                              staticClass: "icon",
                              attrs: { name: "user-plus", scale: "1.5" }
                            }),
                            _vm._v(
                              " " + _vm._s(_vm.$t("account.register")) + " "
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _c(
                    "b-nav-item",
                    {
                      attrs: {
                        right: "",
                        href: "https://support.adverpost.com/"
                      }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            target: "_blank",
                            href: "https://support.adverpost.com/"
                          }
                        },
                        [
                          _c("v-icon", {
                            staticClass: "icon",
                            attrs: { name: "question-circle", scale: "1.5" }
                          }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("dashboard.common.support")) +
                              " "
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "b-nav-item",
                    { attrs: { right: "" } },
                    [
                      _c(
                        "b-nav-item-dropdown",
                        { attrs: { right: "" } },
                        [
                          _c(
                            "template",
                            { slot: "button-content" },
                            [
                              _c("v-icon", {
                                staticClass: "icon",
                                attrs: { name: "globe", scale: "1.5" }
                              }),
                              _vm._v(
                                " " + _vm._s(_vm.languages[this.$i18n.locale])
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.lang("en")
                                }
                              }
                            },
                            [_vm._v("English")]
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.lang("zh")
                                }
                              }
                            },
                            [_vm._v("中文")]
                          )
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _vm.$auth.user.authenticated
                    ? _c(
                        "div",
                        { staticClass: "navigation-box navbar-toggler" },
                        [
                          _c(
                            "b-nav-form",
                            {
                              staticClass: "w-100",
                              attrs: { "form-class": "w-100" }
                            },
                            [
                              _c("team-selector", {
                                staticClass: "w-100",
                                attrs: {
                                  resetTeamsTrigger: _vm.resetTeamsTrigger
                                },
                                on: { updateTeam: _vm.updateTeam }
                              })
                            ],
                            1
                          ),
                          _vm._l(_vm.links, function(link, index) {
                            return _c(
                              "b-nav-item",
                              {
                                key: index,
                                attrs: { to: link.uri },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("hide")
                                  }
                                }
                              },
                              [
                                !link.children
                                  ? _c(
                                      "div",
                                      { staticClass: "navigation-box-item" },
                                      [
                                        _c("v-icon", {
                                          staticClass: "mr-2",
                                          attrs: {
                                            name: link.icon,
                                            scale: "1.5"
                                          }
                                        }),
                                        _vm._v(
                                          " " + _vm._s(_vm.$t(link.key)) + " "
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                link.children
                                  ? _c(
                                      "div",
                                      { staticClass: "navigation-box-item" },
                                      [
                                        _c("v-icon", {
                                          staticClass: "mr-2",
                                          attrs: {
                                            name: link.icon,
                                            scale: "1.5"
                                          }
                                        }),
                                        _vm._v(
                                          " " + _vm._s(_vm.$t(link.key)) + " "
                                        ),
                                        _vm._l(link.children, function(
                                          link,
                                          index
                                        ) {
                                          return _c(
                                            "b-nav-item",
                                            {
                                              key: index,
                                              staticClass: "ml-3",
                                              attrs: { to: link.uri },
                                              on: {
                                                click: function($event) {
                                                  return _vm.$emit("hide")
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "navigation-box-item"
                                                },
                                                [
                                                  _c("v-icon", {
                                                    staticClass: "mr-2",
                                                    attrs: {
                                                      name: link.icon,
                                                      scale: "1.5"
                                                    }
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.$t(link.key)) +
                                                      " "
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        })
                                      ],
                                      2
                                    )
                                  : _vm._e()
                              ]
                            )
                          })
                        ],
                        2
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-container",
        {
          staticClass: "pl-0 pb-0 pr-0 h-100",
          attrs: { fluid: "" },
          on: {
            click: function($event) {
              return _vm.unDrop()
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "row m-0 h-100" },
            [
              _c("sidebar", {
                attrs: {
                  links: _vm.links,
                  active: _vm.sidebarActive,
                  user: _vm.$auth.user,
                  resetTeamsTrigger: _vm.resetTeamsTrigger
                },
                on: {
                  updateTeam: _vm.updateTeam,
                  hide: function($event) {
                    _vm.sidebarActive = false
                  }
                }
              }),
              _c(
                "div",
                {
                  staticClass: "col-12 pb-0 pr-0 pl-0 pl-md-1 h-100",
                  class: {
                    faded: _vm.sidebarActive,
                    "col-md-12 col-lg-10": _vm.$auth.user.authenticated
                  }
                },
                [
                  _c(
                    "transition",
                    { attrs: { name: "fade" } },
                    [
                      _c("router-view", {
                        attrs: { activeTeam: _vm.activeTeam }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c("notifications"),
      _c(
        "b-modal",
        {
          attrs: {
            id: "login",
            title: "",
            "hide-header": "",
            "no-fade": "",
            "hide-footer": ""
          }
        },
        [
          _c("login", {
            on: {
              resetTeams: function($event) {
                _vm.$nextTick(function() {
                  return _vm.resetTeamsTrigger++
                })
              }
            }
          })
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "register",
            title: "",
            centered: "",
            "hide-header": "",
            "no-fade": "",
            "hide-footer": ""
          }
        },
        [_c("register")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }