var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-wrap justify-content-center" },
    _vm._l(_vm.computedDisplays, function(display) {
      return _c(
        "div",
        { key: display.displayId },
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-modal",
                  rawName: "v-b-modal",
                  value: "display_" + display.displayId,
                  expression: "'display_' + display.displayId"
                }
              ],
              staticClass: "display m-1 p-2 border border-radius",
              attrs: { variant: "light" }
            },
            [
              _c("display-preview", {
                staticClass: "preview",
                attrs: { display: display, refreshTime: _vm.refreshTime }
              }),
              _c(
                "div",
                { style: { whiteSpace: "nowrap", textOverflow: "ellipsis" } },
                [_c("small", [_vm._v(_vm._s(display.name))])]
              )
            ],
            1
          ),
          _c(
            "b-modal",
            {
              attrs: {
                size: "lg",
                "no-fade": "",
                "hide-header": "",
                "hide-header-close": "",
                "hide-footer": "",
                centered: "",
                id: "display_" + display.displayId
              }
            },
            [_c("display-dashboard", { attrs: { display: display } })],
            1
          )
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }