var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.errors.length > 0
        ? _c("div", { staticClass: "alert alert-danger" }, [
            _c(
              "ul",
              _vm._l(_vm.errors, function(error, index) {
                return _c("li", { key: index }, [_vm._v(_vm._s(error))])
              }),
              0
            )
          ])
        : _vm._e(),
      _c(
        "b-container",
        { staticClass: "mt-3" },
        [
          _c(
            "b-row",
            { staticClass: "mb-3" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12", lg: "6" } },
                [
                  _c(
                    "b-card",
                    { attrs: { header: _vm.$t("common.information") } },
                    [
                      _c(
                        "b-card-text",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t("common.name"),
                                "label-for": "name"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "name",
                                  placeholder: _vm.$t("common.name")
                                },
                                model: {
                                  value: _vm.editDisplay.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editDisplay, "name", $$v)
                                  },
                                  expression: "editDisplay.name"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t("common.description"),
                                "label-for": "description"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "description",
                                  placeholder: _vm.$t("common.description"),
                                  type: "text"
                                },
                                model: {
                                  value: _vm.editDisplay.description,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.editDisplay,
                                      "description",
                                      $$v
                                    )
                                  },
                                  expression: "editDisplay.description"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t("displays.type"),
                                "label-for": "display-type"
                              }
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  id: "display-type",
                                  options: _vm.options.types
                                },
                                model: {
                                  value: _vm.editDisplay.type,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editDisplay, "type", $$v)
                                  },
                                  expression: "editDisplay.type"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t("privacy.private"),
                                "label-for": "private"
                              }
                            },
                            [
                              _c("b-form-checkbox", {
                                attrs: { id: "private" },
                                model: {
                                  value: _vm.editDisplay.private,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editDisplay, "private", $$v)
                                  },
                                  expression: "editDisplay.private"
                                }
                              })
                            ],
                            1
                          ),
                          _vm.editDisplay.private == 0
                            ? _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.$t("displays.publicUrl"),
                                    "label-for": "slug"
                                  }
                                },
                                [
                                  _c("p", [
                                    _vm._v("https:" + _vm._s(_vm.publicUrl))
                                  ]),
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "slug",
                                      placeholder: _vm.$t(
                                        "Enter public URL here"
                                      )
                                    },
                                    model: {
                                      value: _vm.editDisplay.slug,
                                      callback: function($$v) {
                                        _vm.$set(_vm.editDisplay, "slug", $$v)
                                      },
                                      expression: "editDisplay.slug"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12", lg: "6" } },
                [
                  _c(
                    "b-card",
                    [
                      _c(
                        "b-card-text",
                        [
                          _c("display-gallery", {
                            attrs: {
                              displayId: _vm.editDisplay.displayId,
                              edit: true
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mb-3" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12", lg: "6" } },
                [
                  _c(
                    "b-card",
                    { attrs: { header: _vm.$t("common.dimensions") } },
                    [
                      _c(
                        "b-card-text",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t("displays.physicalWidth"),
                                "label-for": "physical-width"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "physical-width",
                                  placeholder: _vm.$t("displays.physicalWidth")
                                },
                                model: {
                                  value: _vm.editDisplay.physicalWidth,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.editDisplay,
                                      "physicalWidth",
                                      $$v
                                    )
                                  },
                                  expression: "editDisplay.physicalWidth"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t("displays.physicalHeight"),
                                "label-for": "physical-height"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "physical-height",
                                  placeholder: _vm.$t("displays.physicalHeight")
                                },
                                model: {
                                  value: _vm.editDisplay.physicalHeight,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.editDisplay,
                                      "physicalHeight",
                                      $$v
                                    )
                                  },
                                  expression: "editDisplay.physicalHeight"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t("displays.pixelWidth"),
                                "label-for": "pixel-width"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "pixel-width",
                                  placeholder: _vm.$t("displays.pixelWidth")
                                },
                                model: {
                                  value: _vm.editDisplay.pixelWidth,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editDisplay, "pixelWidth", $$v)
                                  },
                                  expression: "editDisplay.pixelWidth"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t("displays.pixelHeight"),
                                "label-for": "pixel-height"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "pixel-height",
                                  placeholder: _vm.$t("displays.pixelHeight")
                                },
                                model: {
                                  value: _vm.editDisplay.pixelHeight,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.editDisplay,
                                      "pixelHeight",
                                      $$v
                                    )
                                  },
                                  expression: "editDisplay.pixelHeight"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t("displays.rotation"),
                                "label-for": "rotation"
                              }
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  id: "rotation",
                                  options: _vm.options.rotations
                                },
                                model: {
                                  value: _vm.editDisplay.rotate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editDisplay, "rotate", $$v)
                                  },
                                  expression: "editDisplay.rotate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12", lg: "6" } },
                [
                  _c(
                    "b-card",
                    { attrs: { header: _vm.$t("displays.location") } },
                    [
                      _c(
                        "b-card-text",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t("displays.mobile"),
                                "label-for": "mobile"
                              }
                            },
                            [
                              _c("b-form-checkbox", {
                                attrs: { id: "mobile" },
                                model: {
                                  value: _vm.editDisplay.mobile,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editDisplay, "mobile", $$v)
                                  },
                                  expression: "editDisplay.mobile"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t("displays.location"),
                                "label-for": "location",
                                id: "location"
                              }
                            },
                            [
                              _c(
                                "b-form-radio",
                                {
                                  attrs: {
                                    id: "displayAddress",
                                    value: "Address"
                                  },
                                  model: {
                                    value: _vm.locationSelect,
                                    callback: function($$v) {
                                      _vm.locationSelect = $$v
                                    },
                                    expression: "locationSelect"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("displays.byAdd")))]
                              ),
                              _c(
                                "b-form-radio",
                                {
                                  attrs: {
                                    id: "displayLatLong",
                                    value: "LatLong"
                                  },
                                  model: {
                                    value: _vm.locationSelect,
                                    callback: function($$v) {
                                      _vm.locationSelect = $$v
                                    },
                                    expression: "locationSelect"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("displays.byLatLn")))]
                              )
                            ],
                            1
                          ),
                          _vm.locationSelect === "Address"
                            ? _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.$t("displays.address"),
                                    "label-for": "physical-height"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: { id: "address" },
                                    model: {
                                      value: _vm.address,
                                      callback: function($$v) {
                                        _vm.address = $$v
                                      },
                                      expression: "address"
                                    }
                                  }),
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { name: "button" },
                                      on: {
                                        click: _vm.fetchLocationFromAddress
                                      }
                                    },
                                    [_vm._v("Search")]
                                  )
                                ],
                                1
                              )
                            : _c(
                                "b-form-group",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: _vm.$t("displays.latitude"),
                                        "label-for": "latitude"
                                      }
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: { id: "latitude" },
                                        model: {
                                          value: _vm.coords.latitude,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.coords,
                                              "latitude",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "coords.latitude"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: _vm.$t("displays.longitude"),
                                        "label-for": "longitude"
                                      }
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: { id: "longitude" },
                                        model: {
                                          value: _vm.coords.longitude,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.coords,
                                              "longitude",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "coords.longitude"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                          _c(
                            "div",
                            { staticClass: "map" },
                            [
                              _c(
                                "v-map",
                                {
                                  attrs: {
                                    zoom: _vm.zoom,
                                    center: [
                                      _vm.coords.latitude,
                                      _vm.coords.longitude
                                    ]
                                  },
                                  on: {
                                    "l-zoom": _vm.zoomChange,
                                    "l-dblclick": _vm.dblclicked
                                  }
                                },
                                [
                                  _c("v-tilelayer", {
                                    attrs: {
                                      options: { maxZoom: 13 },
                                      url: _vm.mapUrl
                                    }
                                  }),
                                  _c("v-marker", {
                                    attrs: {
                                      "lat-lng": [
                                        _vm.coords.latitude,
                                        _vm.coords.longitude
                                      ],
                                      icon: _vm.icon,
                                      draggable: ""
                                    },
                                    on: { dragend: _vm.moved }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mb-3" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12", lg: "6" } },
                [
                  _c(
                    "b-card",
                    { attrs: { header: _vm.$t("displays.timezoneCurrency") } },
                    [
                      _c(
                        "b-card-text",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t("displays.timezone"),
                                "label-for": "timezone"
                              }
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  id: "timezone",
                                  options: _vm.options.timezones
                                },
                                model: {
                                  value: _vm.editDisplay.timezone,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editDisplay, "timezone", $$v)
                                  },
                                  expression: "editDisplay.timezone"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t("displays.currency"),
                                "label-for": "currency"
                              }
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  id: "currency",
                                  options: _vm.options.currencies
                                },
                                model: {
                                  value: _vm.editDisplay.currency,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editDisplay, "currency", $$v)
                                  },
                                  expression: "editDisplay.currency"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12", lg: "6" } },
                [
                  _c(
                    "b-card",
                    { attrs: { header: _vm.$t("common.tags") } },
                    [
                      _c(
                        "b-card-text",
                        [
                          _c("multiselect", {
                            attrs: {
                              options: _vm.alltags,
                              value: _vm.selected,
                              multiple: true,
                              taggable: true,
                              placeholder: _vm.$t("displays.addTags")
                            },
                            on: {
                              tag: _vm.addTag,
                              select: _vm.chooseTag,
                              remove: _vm.removeTag
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mb-3" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12", lg: "12" } },
                [
                  _c(
                    "b-card",
                    { attrs: { header: _vm.$t("displays.brightness") } },
                    [
                      _c(
                        "b-card-text",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t("displays.brightnessControl"),
                                "label-for": "brightness-control"
                              }
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  id: "brightness-control",
                                  options: _vm.options.brightnessControls
                                },
                                model: {
                                  value: _vm.editDisplay.brightnessControl,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.editDisplay,
                                      "brightnessControl",
                                      $$v
                                    )
                                  },
                                  expression: "editDisplay.brightnessControl"
                                }
                              })
                            ],
                            1
                          ),
                          _vm.editDisplay.brightnessControl === "scheduled"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "label",
                                    { attrs: { for: "timed-brightness" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("displays.brightnessCurve")
                                        )
                                      )
                                    ]
                                  ),
                                  _c("curve", {
                                    attrs: { id: "curve" },
                                    model: {
                                      value: _vm.editDisplay.brightnessCurve,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editDisplay,
                                          "brightnessCurve",
                                          $$v
                                        )
                                      },
                                      expression: "editDisplay.brightnessCurve"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mb-3" },
            [
              _vm.$auth.user.admin
                ? _c(
                    "b-col",
                    { attrs: { cols: "12", lg: "12" } },
                    [
                      _c(
                        "b-card",
                        { attrs: { header: _vm.$t("common.admin") } },
                        [
                          _c(
                            "b-card-text",
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.$t("displays.acid"),
                                    "label-for": "acid"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "acid",
                                      placeholder: _vm.$t("displays.acid")
                                    },
                                    model: {
                                      value: _vm.editDisplay.acid,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editDisplay,
                                          "acid",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "editDisplay.acid"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.$t("displays.hwid"),
                                    "label-for": "hwid"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "hwid",
                                      placeholder: _vm.$t("displays.hwid")
                                    },
                                    model: {
                                      value: _vm.editDisplay.hardwareId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editDisplay,
                                          "hardwareId",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "editDisplay.hardwareId"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.$t("common.approval"),
                                    "label-for": "approval",
                                    id: "approval"
                                  }
                                },
                                [
                                  _c(
                                    "b-form-radio",
                                    {
                                      attrs: {
                                        id: "pending",
                                        value: "pending"
                                      },
                                      model: {
                                        value: _vm.editDisplay.approval,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.editDisplay,
                                            "approval",
                                            $$v
                                          )
                                        },
                                        expression: "editDisplay.approval"
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("approval.pending")))]
                                  ),
                                  _c(
                                    "b-form-radio",
                                    {
                                      attrs: {
                                        id: "approved",
                                        value: "approved"
                                      },
                                      model: {
                                        value: _vm.editDisplay.approval,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.editDisplay,
                                            "approval",
                                            $$v
                                          )
                                        },
                                        expression: "editDisplay.approval"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("approval.approved"))
                                      )
                                    ]
                                  ),
                                  _c(
                                    "b-form-radio",
                                    {
                                      attrs: { id: "denied", value: "denied" },
                                      model: {
                                        value: _vm.editDisplay.approval,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.editDisplay,
                                            "approval",
                                            $$v
                                          )
                                        },
                                        expression: "editDisplay.approval"
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("approval.denied")))]
                                  )
                                ],
                                1
                              ),
                              _c("label", { attrs: { for: "team" } }, [
                                _vm._v(_vm._s(_vm.$t("common.team")))
                              ]),
                              _c("multiselect", {
                                attrs: {
                                  options: _vm.multiselectTeams,
                                  "track-by": "teamId",
                                  label: "name",
                                  placeholder: _vm.editDisplay.teamName
                                },
                                on: { input: _vm.setTeamID },
                                model: {
                                  value: _vm.selectedTeam,
                                  callback: function($$v) {
                                    _vm.selectedTeam = $$v
                                  },
                                  expression: "selectedTeam"
                                }
                              }),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.$t("displays.demoMode"),
                                    "label-for": "demo"
                                  }
                                },
                                [
                                  _c("b-form-checkbox", {
                                    attrs: { id: "demo" },
                                    model: {
                                      value: _vm.editDisplay.demoMode,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editDisplay,
                                          "demoMode",
                                          $$v
                                        )
                                      },
                                      expression: "editDisplay.demoMode"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "form-group save",
              attrs: { id: "displayCreateButton" }
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.updateDisplay()
                    }
                  }
                },
                [_vm._v(" Save ")]
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }