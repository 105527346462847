var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        { attrs: { id: "showteam", size: "xl", "hide-footer": "" } },
        [_c("team", { attrs: { teamId: _vm.teamId } })],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: { id: "showleave", title: _vm.$t("actions.leave"), size: "md" }
        },
        [
          _vm._v(
            " " +
              _vm._s(_vm.$t("message.leaveTeam")) +
              " " +
              _vm._s(_vm.team.name) +
              " "
          ),
          _c(
            "div",
            { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
            [
              _c(
                "b-button",
                {
                  staticClass: "mr-3",
                  attrs: { type: "button", variant: "danger" },
                  on: {
                    click: function($event) {
                      return _vm.leaveTeam(_vm.team.id)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("answers.yes")))]
              ),
              _c(
                "b-button",
                {
                  attrs: { type: "button", variant: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.$bvModal.hide("showleave")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("answers.no")))]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "newteam",
            title: _vm.$t("teams.newTeam"),
            size: "lg",
            "hide-footer": ""
          }
        },
        [_c("new-team")],
        1
      ),
      _c(
        "b-card",
        {
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function() {
                return [
                  _vm._v(" " + _vm._s(_vm.$t("teams.teams")) + " "),
                  _vm.showCreate
                    ? _c(
                        "b-button",
                        {
                          staticClass: "float-right pointer",
                          attrs: { variant: "primary", size: "sm" },
                          on: {
                            click: function($event) {
                              return _vm.$bvModal.show("newteam")
                            }
                          }
                        },
                        [_vm._v("✚")]
                      )
                    : _vm._e()
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("b-card-text", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.loading,
                    expression: "loading"
                  }
                ],
                staticClass: "loader"
              },
              [_c("div", { staticClass: "spinner" })]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.loading,
                    expression: "!loading"
                  }
                ]
              },
              [
                _vm.teams.length > 0
                  ? _c(
                      "div",
                      [
                        _c(
                          "ul",
                          { staticClass: "list-group" },
                          _vm._l(_vm.teams, function(team) {
                            return _c(
                              "li",
                              {
                                key: team.teamId,
                                staticClass:
                                  "list-group-item list-group-item-action justify-content-between pointer",
                                on: {
                                  click: function($event) {
                                    return _vm.viewTeam(team.teamId)
                                  }
                                }
                              },
                              [
                                _vm._v(" " + _vm._s(team.name) + " "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "badge badge-default badge-danger",
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.showLeaveTeam(
                                          team.name,
                                          team.teamId
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("actions.leave")))]
                                )
                              ]
                            )
                          }),
                          0
                        ),
                        _c(
                          "b-button-group",
                          { staticClass: "mt-2" },
                          [
                            _vm.teamsMeta.totalPages > 1 && _vm.currentPage != 1
                              ? _c(
                                  "b-button",
                                  {
                                    attrs: { variant: "outline-secondary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.fetchTeams(
                                          _vm.teamsMeta.previousPage
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("buttons.previous")))]
                                )
                              : _vm._e(),
                            _vm.teamsMeta.totalPages > 1 &&
                            _vm.currentPage != _vm.teamsMeta.totalPages
                              ? _c(
                                  "b-button",
                                  {
                                    attrs: { variant: "outline-secondary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.fetchTeams(
                                          _vm.teamsMeta.nextPage
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("buttons.next")))]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _c("div", [
                      _c("span", [_vm._v(_vm._s(_vm.$t("message.noTeam")))])
                    ])
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }