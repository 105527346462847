var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { staticClass: "text-center align-middle" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "m-0 p-1" },
                [
                  _c(
                    "b-link",
                    { attrs: { to: { name: "campaigns", params: {} } } },
                    [
                      _c("b-icon", {
                        staticClass: "border rounded-circle p-2 bg-light",
                        attrs: {
                          variant: "danger",
                          icon: "aspect-ratio",
                          "font-scale": "3"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c("b-col", { staticClass: "m-0 p-1" }, [
                _c("b", [_vm._v(_vm._s(_vm.stats.total))]),
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("dashboard.campaignStats.campaigns")) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-col",
        { staticClass: "text-center align-middle" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "m-0 p-1" },
                [
                  _c(
                    "b-link",
                    { attrs: { to: { name: "IPAM", params: {} } } },
                    [
                      _c("b-icon", {
                        staticClass: "border rounded-circle p-2 bg-light",
                        attrs: {
                          variant: "success",
                          icon: "play-btn",
                          "font-scale": "3"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c("b-col", { staticClass: "m-0 p-1" }, [
                _c("b", [_vm._v(_vm._s(_vm.stats.playing))]),
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("dashboard.campaignStats.playingCampaigns")) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-col",
        { staticClass: "text-center align-middle" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "m-0 p-1" },
                [
                  _c(
                    "b-link",
                    { attrs: { to: { name: "IPAM", params: {} } } },
                    [
                      _c("b-icon", {
                        staticClass: "border rounded-circle p-2 bg-light",
                        attrs: {
                          variant: "primary",
                          icon: "calendar-check",
                          "font-scale": "3"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c("b-col", { staticClass: "m-0 p-1" }, [
                _c("b", [_vm._v(_vm._s(_vm.stats.scheduled))]),
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("dashboard.campaignStats.schedCampaigns")) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-col",
        { staticClass: "text-center align-middle" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "m-0 p-1" },
                [
                  _c("b-icon", {
                    staticClass: "border rounded-circle p-2 bg-light",
                    attrs: {
                      variant: "warning",
                      icon: "calendar4-range",
                      "font-scale": "3"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c("b-col", { staticClass: "m-0 p-1" }, [
                _c("b", [_vm._v(_vm._s(_vm.stats.pending))]),
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("dashboard.campaignStats.waitApproval")) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }