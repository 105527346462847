var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "h-100", attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        {
          staticClass: "header mt-3 pb-1 w-100",
          attrs: { "no-gutters": "", id: "top-area" }
        },
        [
          _c("b-col", [
            _c("h2", [_vm._v(_vm._s(_vm.$t("campaigns.campaigns")))]),
            _c("p", { staticClass: "d-none d-md-block" }, [
              _vm._v(_vm._s(_vm.$t("tutorial.campaignsExample")))
            ])
          ]),
          _c(
            "b-col",
            {
              staticClass: "d-md-none m-0 p-0 text-right",
              attrs: { cols: "5" }
            },
            [
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "b-modal",
                      rawName: "v-b-modal.new",
                      modifiers: { new: true }
                    }
                  ],
                  attrs: { variant: "outline-danger" }
                },
                [
                  _vm._v("Create "),
                  _c("b-icon", {
                    staticClass: "d-inline",
                    attrs: { icon: "plus-circle-fill" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "d-md-none", attrs: { cols: "12" } },
            [
              _c("multiselect", {
                attrs: {
                  options: _vm.filtered,
                  "track-by": "campaignId",
                  label: "name",
                  value: _vm.compSelected,
                  searchable: false,
                  "allow-empty": false
                },
                on: { select: _vm.selectCampaign },
                scopedSlots: _vm._u([
                  {
                    key: "singleLabel",
                    fn: function() {
                      return [
                        _c("div", { staticClass: "trim" }, [
                          _vm._v(_vm._s(_vm.compSelected.name))
                        ])
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.loading
        ? _c("b-row", { attrs: { id: "content-area" } }, [
            _c("div", { staticClass: "spinner" })
          ])
        : _c(
            "b-row",
            { attrs: { id: "content-area" } },
            [
              _c(
                "b-col",
                {
                  ref: "sidebar",
                  staticClass: "d-none d-md-block h-100 overflow list",
                  attrs: { md: "4", lg: "2" }
                },
                [
                  _c("b-form-input", {
                    staticClass: "search",
                    attrs: {
                      id: "search",
                      placeholder: _vm.$t("actions.search")
                    },
                    model: {
                      value: _vm.search,
                      callback: function($$v) {
                        _vm.search = $$v
                      },
                      expression: "search"
                    }
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.new",
                          modifiers: { new: true }
                        }
                      ],
                      staticClass: "new-item item"
                    },
                    [
                      _c("v-icon", {
                        attrs: {
                          name: "plus",
                          scale: "1.5",
                          label: "New Campaign"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.filtered.length > 0
                    ? _vm._l(_vm.filtered, function(campaign) {
                        return _c(
                          "div",
                          {
                            key: campaign.campaignId,
                            staticClass: "pt-3 pb-3 item overflow",
                            class: {
                              selected:
                                _vm.compSelected !== {} &&
                                _vm.compSelected.campaignId ===
                                  campaign.campaignId
                            },
                            attrs: { id: "c_" + campaign.campaignId },
                            on: {
                              click: function($event) {
                                return _vm.selectCampaign(campaign)
                              }
                            }
                          },
                          [
                            _vm._v(" " + _vm._s(campaign.name) + " "),
                            campaign.displays && campaign.displays.length === 1
                              ? _c(
                                  "b-badge",
                                  { attrs: { variant: "success" } },
                                  [_vm._v("Scheduled")]
                                )
                              : _vm._e(),
                            campaign.displays && campaign.displays.length > 1
                              ? _c(
                                  "b-badge",
                                  { attrs: { variant: "primary" } },
                                  [_vm._v("Multiple")]
                                )
                              : _vm._e(),
                            _c("div", { staticClass: "text-muted" }, [
                              _c("small", [
                                _c("b", [
                                  _vm._v(
                                    _vm._s(
                                      campaign.media.data.reduce(function(
                                        a,
                                        c
                                      ) {
                                        return Math.max(a, c.end)
                                      },
                                      0)
                                    )
                                  )
                                ]),
                                _vm._v(" seconds, "),
                                _c("b", [
                                  _vm._v(
                                    _vm._s(
                                      Number(
                                        campaign.media.data.reduce(function(
                                          a,
                                          c
                                        ) {
                                          return Math.max(a, c.size)
                                        },
                                        0) /
                                          1024 /
                                          1024
                                      ).toFixed(2)
                                    )
                                  )
                                ]),
                                _vm._v(" MB ")
                              ])
                            ])
                          ],
                          1
                        )
                      })
                    : this.activeTeam.id == 0
                    ? [
                        _c(
                          "div",
                          { staticClass: "pt-4 text-muted text-center" },
                          [_vm._v(_vm._s(_vm.$t("campaigns.noTeam")))]
                        )
                      ]
                    : [
                        _c(
                          "div",
                          { staticClass: "pt-4 text-muted text-center" },
                          [_vm._v(_vm._s(_vm.$t("campaigns.noCampaigns")))]
                        )
                      ]
                ],
                2
              ),
              _c(
                "b-col",
                {
                  staticClass: "h-100 overflow",
                  attrs: { cols: "12", md: "8", lg: "10" }
                },
                [
                  _c(
                    "transition",
                    { attrs: { name: "fade" } },
                    [
                      _vm.filtered.length > 0 &&
                      _vm.compSelected !== {} &&
                      _vm.$route.params.campaignId
                        ? _c("router-view", {
                            attrs: {
                              activeTeam: _vm.activeTeam,
                              campaign: _vm.compSelected
                            },
                            on: {
                              close: function($event) {
                                return _vm.closeEdit()
                              },
                              delete: function($event) {
                                return _vm.fetchCampaigns(null)
                              },
                              update: function($event) {
                                return _vm.fetchCampaigns(null)
                              },
                              refresh: _vm.refreshCampaigns,
                              refreshCampaign: _vm.refreshSelected
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "new",
            size: "md",
            title: _vm.$t("campaigns.newCampaign"),
            "hide-footer": ""
          }
        },
        [
          _vm.errors.length > 0
            ? _c("div", { staticClass: "alert alert-danger" }, [
                _c(
                  "ul",
                  _vm._l(_vm.errors, function(error, index) {
                    return _c("li", { key: index }, [
                      _vm._v(" " + _vm._s(error) + " ")
                    ])
                  }),
                  0
                )
              ])
            : _vm._e(),
          _c(
            "b-row",
            {
              on: {
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.createCampaign()
                }
              }
            },
            [
              _c(
                "b-col",
                { attrs: { cols: "12", id: "campaignInformation" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.$t("campaigns.campaignName"),
                        "label-for": "name"
                      }
                    },
                    [
                      _c("b-form-input", {
                        staticClass: "form-control",
                        attrs: {
                          id: "name",
                          placeholder: _vm.$t("campaigns.campaignName")
                        },
                        model: {
                          value: _vm.newName,
                          callback: function($$v) {
                            _vm.newName = $$v
                          },
                          expression: "newName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.$t("common.team") } },
                    [
                      _c("b-input", {
                        attrs: { disabled: "", value: _vm.activeTeam.name }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mt-2",
                          attrs: { variant: "primary" },
                          on: { click: _vm.createCampaign }
                        },
                        [_vm._v(_vm._s(_vm.$t("buttons.create")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.compSelected !== {} || _vm.loading
        ? _c(
            "b-modal",
            {
              attrs: {
                id: "edit-new",
                size: "xl",
                title: _vm.compSelected.name,
                "hide-footer": ""
              }
            },
            [
              _c("campaign-editor", {
                attrs: {
                  activeTeam: _vm.activeTeam,
                  campaign: _vm.compSelected
                },
                on: {
                  saved: function($event) {
                    return _vm.closeEdit()
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }