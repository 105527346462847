var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("canvas", {
      ref: "canvas",
      attrs: { id: _vm.chartId, width: _vm.width, height: _vm.height }
    }),
    _vm.inputs
      ? _c(
          "div",
          { ref: "inputs", staticClass: "input-values" },
          _vm._l(_vm.value, function(val, key) {
            return _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.value[key],
                  expression: "value[key]"
                }
              ],
              key: key,
              staticClass: "input-value text-center",
              attrs: { type: "number", min: "0", max: "100" },
              domProps: { value: _vm.value[key] },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.value, key, $event.target.value)
                }
              }
            })
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }