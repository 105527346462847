var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "pricecalc",
            "hide-footer": "",
            size: "lg",
            title: "Pricing Calculator"
          }
        },
        [
          _c("pricing-calculator", {
            attrs: {
              blockTime: _vm.display.blockTime,
              maxTimePurchasable: _vm.display.maxTimePurchasable,
              showButton: ""
            },
            on: {
              close: function($event) {
                return _vm.$bvModal.hide("pricecalc")
              }
            },
            model: {
              value: _vm.defaultSchedule.pricePerSecond,
              callback: function($$v) {
                _vm.$set(_vm.defaultSchedule, "pricePerSecond", $$v)
              },
              expression: "defaultSchedule.pricePerSecond"
            }
          })
        ],
        1
      ),
      _vm.errors.length > 0
        ? _c("div", { staticClass: "alert alert-danger" }, [
            _c(
              "ul",
              _vm._l(_vm.errors, function(error, index) {
                return _c("li", { key: index }, [
                  _vm._v(" " + _vm._s(error) + " ")
                ])
              }),
              0
            )
          ])
        : _vm._e(),
      _c(
        "b-container",
        { staticClass: "mt-3" },
        [
          _c(
            "b-row",
            { staticClass: "h-100" },
            [
              Object.keys(_vm.selected).length === 0
                ? _c(
                    "b-col",
                    { attrs: { cols: "12", lg: "3" } },
                    [
                      _c(
                        "b-row",
                        { staticClass: "mb-3" },
                        [
                          _c(
                            "b-card",
                            {
                              attrs: {
                                header: _vm.$t("displayPricing.default")
                              }
                            },
                            [
                              _c(
                                "b-card-text",
                                [
                                  _c(
                                    "div",
                                    {
                                      on: {
                                        keyup: function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          return _vm.saveDefault()
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: _vm.$t("common.name"),
                                            "label-for": "defaultName"
                                          }
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: { id: "defaultName" },
                                            model: {
                                              value:
                                                _vm.defaultSchedule
                                                  .scheduleName,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.defaultSchedule,
                                                  "scheduleName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "defaultSchedule.scheduleName"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: _vm.$t("displayPricing.pps"),
                                            "label-for": "price"
                                          }
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: { id: "price" },
                                            model: {
                                              value:
                                                _vm.defaultSchedule
                                                  .pricePerSecond,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.defaultSchedule,
                                                  "pricePerSecond",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "defaultSchedule.pricePerSecond"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "float-left mt-1",
                                      attrs: { variant: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.$bvModal.show("pricecalc")
                                        }
                                      }
                                    },
                                    [_vm._v("Pricing Calculator")]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "float-right mt-1",
                                      attrs: {
                                        name: "button",
                                        variant: "primary"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.saveDefault()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("buttons.save"))
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mb-3" },
                        [
                          _c(
                            "b-card",
                            {
                              attrs: {
                                header: _vm.$t("displayPricing.options")
                              }
                            },
                            [
                              _c("b-card-text", [
                                _c(
                                  "div",
                                  {
                                    on: {
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.saveDefault()
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.$t("displays.loop"),
                                          "label-for": "loop-time"
                                        }
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { id: "loop-time" },
                                          model: {
                                            value: _vm.display.blockTime,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.display,
                                                "blockTime",
                                                $$v
                                              )
                                            },
                                            expression: "display.blockTime"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.$t(
                                            "displays.maxTimePurchasable"
                                          ),
                                          "label-for": "max-time"
                                        }
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { id: "max-time" },
                                          model: {
                                            value:
                                              _vm.display.maxTimePurchasable,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.display,
                                                "maxTimePurchasable",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "display.maxTimePurchasable"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "float-right mt-1",
                                        attrs: {
                                          name: "button",
                                          variant: "primary"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.updateDisplay()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("buttons.save"))
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              Object.keys(_vm.selected).length === 0
                ? _c(
                    "b-col",
                    { attrs: { cols: "12", lg: "9" } },
                    [
                      _c("calendar", {
                        staticClass: "m-2",
                        attrs: {
                          pricingSchedules: _vm.pricingSchedules,
                          display: _vm.display,
                          defaultSchedule: _vm.defaultSchedule,
                          defaultDate: _vm.defaultDate
                        },
                        on: {
                          selected: function($event) {
                            _vm.selected = arguments[0]
                          },
                          refresh: _vm.fetchPricingSchedules
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              Object.keys(_vm.selected).length !== 0
                ? _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("day-schedule", {
                        staticClass: "m-2",
                        attrs: {
                          pricingSchedules: _vm.pricingSchedules,
                          display: _vm.display,
                          defaultSchedule: _vm.defaultSchedule,
                          date: _vm.selected
                        },
                        on: {
                          finished: _vm.finish,
                          refresh: _vm.fetchPricingSchedules
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }