import { render, staticRenderFns } from "./Privacy.vue?vue&type=template&id=67731330&"
import script from "./Privacy.vue?vue&type=script&lang=js&"
export * from "./Privacy.vue?vue&type=script&lang=js&"
import style0 from "./Privacy.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/srv/staging_vue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('67731330')) {
      api.createRecord('67731330', component.options)
    } else {
      api.reload('67731330', component.options)
    }
    module.hot.accept("./Privacy.vue?vue&type=template&id=67731330&", function () {
      api.rerender('67731330', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/Frontstuff/Privacy.vue"
export default component.exports