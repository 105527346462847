var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.registered
      ? _c(
          "div",
          [
            _c("h2", [_vm._v(_vm._s(_vm.$t("account.register")))]),
            _c("p", { staticClass: "p-2" }, [
              _vm._v(_vm._s(_vm.$t("register.registerInfo")))
            ]),
            _vm.errors.length > 0
              ? _c(
                  "div",
                  _vm._l(_vm.errors, function(error, index) {
                    return _c(
                      "b-alert",
                      { key: index, attrs: { show: "", variant: "danger" } },
                      [_vm._v(" " + _vm._s(error) + " ")]
                    )
                  }),
                  1
                )
              : _vm._e(),
            _c(
              "b-form",
              {
                attrs: { novalidate: "" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.submit()
                  }
                }
              },
              [
                _c(
                  "b-form-group",
                  {
                    staticClass: "m-2",
                    attrs: {
                      label: _vm.$t("register.registerName"),
                      description: _vm.$t("register.userNameDecs")
                    }
                  },
                  [
                    _c("b-form-input", {
                      staticClass: "form-control",
                      attrs: {
                        id: "username",
                        required: "",
                        type: "text",
                        placeholder: _vm.$t("register.registerName"),
                        autocomplete: "username",
                        state: _vm.credentials.username
                          ? _vm.credentials.username.length < 32
                          : null
                      },
                      model: {
                        value: _vm.credentials.username,
                        callback: function($$v) {
                          _vm.$set(_vm.credentials, "username", $$v)
                        },
                        expression: "credentials.username"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "b-form-group",
                  { staticStyle: { display: "none" } },
                  [
                    _c("b-form-input", {
                      staticClass: "form-control",
                      attrs: {
                        required: "",
                        type: "date",
                        placeholder: "dd/mm/yyyy"
                      },
                      model: {
                        value: _vm.dateOfBirth,
                        callback: function($$v) {
                          _vm.dateOfBirth = $$v
                        },
                        expression: "dateOfBirth"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "b-form-group",
                  {
                    staticClass: "m-2",
                    attrs: {
                      label: _vm.$t("register.email"),
                      description: _vm.$t("register.emailDecs")
                    }
                  },
                  [
                    _c("b-form-input", {
                      staticClass: "form-control",
                      attrs: {
                        id: "email",
                        required: "",
                        type: "text",
                        placeholder: _vm.$t("register.email"),
                        autocomplete: "email"
                      },
                      model: {
                        value: _vm.credentials.email,
                        callback: function($$v) {
                          _vm.$set(_vm.credentials, "email", $$v)
                        },
                        expression: "credentials.email"
                      }
                    })
                  ],
                  1
                ),
                _c("hr"),
                _c(
                  "b-row",
                  { staticClass: "p-2" },
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "6" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: _vm.$t("register.password"),
                              description: _vm.$t("register.pwdDecs")
                            }
                          },
                          [
                            _c("b-form-input", {
                              staticClass: "form-control",
                              attrs: {
                                id: "password",
                                required: "",
                                type: "password",
                                placeholder: _vm.$t("register.password"),
                                autocomplete: "new-password",
                                state: _vm.credentials.password
                                  ? _vm.credentials.password.length >= 8
                                  : null
                              },
                              model: {
                                value: _vm.credentials.password,
                                callback: function($$v) {
                                  _vm.$set(_vm.credentials, "password", $$v)
                                },
                                expression: "credentials.password"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "6" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: _vm.$t("register.rePws"),
                              description: _vm.$t("register.rePwsDecs")
                            }
                          },
                          [
                            _c("b-form-input", {
                              staticClass: "form-control",
                              attrs: {
                                id: "passwordConfirm",
                                required: "",
                                type: "password",
                                placeholder: _vm.$t("register.rePws"),
                                autocomplete: "new-password",
                                state:
                                  _vm.credentials.password &&
                                  _vm.credentials.passwordConfirm &&
                                  _vm.credentials.password.length >= 8
                                    ? _vm.credentials.passwordConfirm ===
                                      _vm.credentials.password
                                    : null
                              },
                              model: {
                                value: _vm.credentials.passwordConfirm,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.credentials,
                                    "passwordConfirm",
                                    $$v
                                  )
                                },
                                expression: "credentials.passwordConfirm"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("hr"),
                _c(
                  "b-collapse",
                  {
                    staticClass: "mt-2",
                    model: {
                      value: _vm.usecard,
                      callback: function($$v) {
                        _vm.usecard = $$v
                      },
                      expression: "usecard"
                    }
                  },
                  [
                    _c(
                      "b-card",
                      [
                        _c(
                          "label",
                          {
                            attrs: { id: "cardHelp", "data-placement": "top" }
                          },
                          [
                            _vm._v(" Payment Details "),
                            _c("small", [_vm._v("(what's this?) ")]),
                            _vm._v(" "),
                            _c("v-icon", { attrs: { name: "question-circle" } })
                          ],
                          1
                        ),
                        _c(
                          "b-popover",
                          {
                            attrs: {
                              target: "cardHelp",
                              triggers: "hover focus"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("popover.registerWithCard")) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              target: "_blank",
                              href: "https://stripe.com"
                            }
                          },
                          [
                            _c("img", {
                              staticClass: "p-2 float-right",
                              attrs: { src: "/static/powered_by_stripe.png" }
                            })
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "p-2" },
                          [
                            Object.keys(_vm.source).length === 0
                              ? _c("stripe-credit-card", {
                                  attrs: { submitText: "Verify Card" },
                                  on: {
                                    tokenCreated: function($event) {
                                      _vm.source = $event
                                    }
                                  }
                                })
                              : _c(
                                  "div",
                                  [
                                    _vm.source.card.object === "card"
                                      ? _c("v-icon", {
                                          staticClass: "mr-2",
                                          staticStyle: { color: "black" },
                                          attrs: {
                                            scale: "1",
                                            name:
                                              "cc-" +
                                              _vm.source.card.brand.toLowerCase()
                                          }
                                        })
                                      : _vm._e(),
                                    _c("span", [
                                      _vm._v(
                                        " **** **** **** " +
                                          _vm._s(_vm.source.card.last4)
                                      )
                                    ])
                                  ],
                                  1
                                )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                !_vm.usecard
                  ? _c(
                      "b-button",
                      {
                        staticClass: "mt-2",
                        attrs: { variant: "primary" },
                        on: {
                          click: function($event) {
                            _vm.usecard = !_vm.usecard
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("register.addPay")))]
                    )
                  : _vm.usecard
                  ? _c(
                      "b-button",
                      {
                        staticClass: "mt-2",
                        attrs: {
                          variant:
                            Object.keys(_vm.source).length > 0
                              ? "danger"
                              : "primary"
                        },
                        on: {
                          click: function($event) {
                            _vm.usecard = !_vm.usecard
                            _vm.source = {}
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            Object.keys(_vm.source).length > 0
                              ? "Remove Details"
                              : "Cancel Adding Payment Details"
                          )
                        )
                      ]
                    )
                  : _vm._e(),
                !_vm.usecard
                  ? _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover.hover.top",
                            value:
                              "Accounts created without payment details will require manual activation.",
                            expression:
                              "'Accounts created without payment details will require manual activation.'",
                            modifiers: { hover: true, top: true }
                          }
                        ],
                        staticClass: "float-right m-2",
                        attrs: { variant: "primary", type: "submit()" }
                      },
                      [_vm._v(_vm._s(_vm.$t("register.create")))]
                    )
                  : Object.keys(_vm.source).length > 0
                  ? _c(
                      "b-button",
                      {
                        staticClass: "float-right m-2",
                        attrs: { variant: "primary", type: "submit()" }
                      },
                      [_vm._v(_vm._s(_vm.$t("register.create")))]
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      : _vm._e(),
    _vm.registered
      ? _c("div", [
          _c("h2", [
            _vm._v(
              _vm._s(_vm.user.username) +
                " " +
                _vm._s(_vm.$t("register.registerSuc"))
            )
          ]),
          Object.keys(_vm.source).length > 0
            ? _c("p", [
                _vm._v(
                  _vm._s(_vm.$t("register.registerEmail")) +
                    " " +
                    _vm._s(_vm.user.email)
                )
              ])
            : _c("p", [_vm._v(_vm._s(_vm.$t("register.registerActi")))])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }