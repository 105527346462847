import Login from './components/Login.vue'
import Register from './components/Register.vue'
import Auth from './main.js'

export default function install(Vue, options) {
  let auth = new Auth(Vue, options)
  let router = options.router

  Object.defineProperty(Vue.prototype, '$auth', {
    get: function get() { return auth },
  })

  Vue.component(Login)
  Vue.component(Register)

  router.beforeEach(
    (to, from, next) => {
      if (to.matched.some(record => record.meta.admin)) {
        if (!auth.user.admin) {
          next({
            path: '/',
            query: { redirect: to.fullPath }
          })
        }
      }

      if (to.matched.some(record => record.meta.auth)) {
        if (!auth.user.authenticated) {
          next({
            path: '/login',
            query: { redirect: to.fullPath }
          })
        }
      }
      next()
    }
  )

  Vue.prototype.$axios.interceptors.request.use(
    (request) => {
      if (!request.url.includes(process.env.VUE_APP_NETWORK_ENDPOINT)) {
        request.headers.common['Application-Token'] = process.env.VUE_APP_APPLICATION_TOKEN
        if (request.url !== 'auth/refresh' && request.url !== 'auth/authorise' && request.url !== 'auth/register' && request.url !== 'auth/resetPassword') {
          if (auth.user.tokenExpired()) {
            // auth.refresh(false).then(
            //   () => {
            //     request.headers.common['Authorization'] = auth.getAuthHeader()
            //     return request
            //   }
            // ).catch(
            //   () => {
            //     auth.logout('/')
            //   }
            // )
            auth.logout('/')
          } else {
            if (auth.user.id !== 0) {
              request.headers.common['Authorization'] = auth.getAuthHeader()
            }
            return request
          }
        } else {
          return request
        }
      } else {
        return request
      }
    }
  )

  // if auth token and refreshtoken are expired on load
  // remove from storage & clear the user
  // else attempt to refresh the access token
  if (auth.user.tokenExpired() && auth.user.refreshTokenExpired()) {
    auth.logout()
  } else if (!auth.user.refreshTokenExpired()) {
    auth.refresh(true).then(
      () => {
      }
    ).catch(
      () => {
        auth.logout('/')
      }
    )
  }
}
