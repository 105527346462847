<template>
  <div class="h-100 dashboard p-2">
    <h2 class="">{{$t('dashboard.headers.dashboard')}}</h2>
    <h4 class="">for <i>{{ activeTeam.name }}</i></h4>
    <dash :displays="filteredDisplays" :activeTeam="activeTeam" :refreshTime="refreshTime"></dash>
  </div>
</template>

<style scoped lang="scss">
.dashboard {
  overflow-x: hidden;
  overflow-y: auto;
}
</style>

<script>
import Dash from './Components/Dash.vue'
import teamContext from '@/mixins/teamContext'

export default {
  mixins: [teamContext],
  components: {
    Dash
  },
  data () {
    return {
      displays: [],
      displaysMeta: {},
      displayIndex: 0,
      oldHeaders: {},
      dash: true,
      refreshTime: 5,
      interval: {}
    }
  },
  mounted () {
    this.fetchDisplays()
    this.interval = setInterval(this.fetchDisplays, (this.refreshTime * 60 * 1000))
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  methods: {
    fetchDisplays () {
      var scope = this
      let url = 'v1/displays?limit=all'
      this.$axios.get(url).then(
        (response) => {
          let displays = response.data.data
          this.displays = displays
          this.displaysMeta = response.data.meta
        }).catch(
        function (error) {
          if (!error.response) {
            scope.$notifications.add({
              title: 'Error',
              type: 'warning',
              body: error.message,
              timeout: true
            })
          } else if (error.response.status !== 404) {
            scope.$notifications.add({
              title: 'Error',
              type: 'warning',
              body: error.response.data.message,
              timeout: true
            })
          }
          scope.displays = []
        }
      )
    },
  },
  computed: {
    filteredDisplays() {
      return this.displays.filter((display) => {
        if (display.teamId == this.activeTeam.id) {
          return true
        }
      })
    },
  },
  watch: {
    refreshTime () {
      clearInterval(this.interval)
      this.interval = setInterval(this.fetchDisplays, (this.refreshTime * 60 * 1000))
    }
  }
}
</script>
