var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "", "text-center": "" } },
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "delete-modal",
            title: _vm.$t("actions.delete") + " " + _vm.display.name
          }
        },
        [
          _vm._v(" " + _vm._s(_vm.$t("message.displayDelete")) + " "),
          _c("div", { attrs: { slot: "modal-footer" }, slot: "modal-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn delete-button btn-primary left",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.deleteDisplay()
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("answers.yes")))]
            ),
            _c(
              "button",
              {
                staticClass: "btn delete-button btn-primary right",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.$bvModal.hide("delete-modal")
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("answers.no")))]
            )
          ])
        ]
      ),
      _c("b-col", { staticClass: "d-none d-md-block" }, [
        _c(
          "div",
          { staticClass: "d-flex p-1 justify-content-between border-bottom" },
          [
            _c("h2", [_vm._v(_vm._s(_vm.display.name))]),
            _c(
              "b-button-toolbar",
              [
                _c(
                  "b-button-group",
                  { staticClass: "delete-button" },
                  [
                    _vm._l(_vm.buttons, function(button) {
                      return _c(
                        "b-button",
                        {
                          key: button.route,
                          attrs: {
                            disabled:
                              _vm.$router.currentRoute.name === button.route,
                            variant:
                              _vm.$router.currentRoute.name === button.route
                                ? "primary"
                                : "outline-primary",
                            to: {
                              name: button.route,
                              params: { displayId: _vm.display.displayId }
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t(button.label)))]
                      )
                    }),
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "danger" },
                        on: {
                          click: function($event) {
                            return _vm.$bvModal.show("delete-modal")
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("actions.delete")))]
                    )
                  ],
                  2
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("b-col", { staticClass: "d-md-none" }, [
        _c(
          "div",
          { staticClass: "d-flex p-1 justify-content-between border-bottom" },
          [
            _c("h3", [_vm._v(_vm._s(_vm.display.name))]),
            _c(
              "b-dropdown",
              {
                staticClass: "m-2",
                attrs: {
                  id: "dropdown-1",
                  text: _vm.$t("actions.actions"),
                  variant: "outline-dark",
                  size: "lg"
                }
              },
              [
                _vm._l(_vm.buttons, function(button) {
                  return _c(
                    "b-dropdown-item",
                    {
                      key: button.route,
                      attrs: {
                        to: {
                          name: button.route,
                          params: { displayId: _vm.display.displayId }
                        }
                      }
                    },
                    [_c("h5", [_vm._v(_vm._s(_vm.$t(button.label)))])]
                  )
                }),
                _c(
                  "b-dropdown-item",
                  {
                    on: {
                      click: function($event) {
                        return _vm.$bvModal.show("delete-modal")
                      }
                    }
                  },
                  [_c("h5", [_vm._v(_vm._s(_vm.$t("actions.delete")))])]
                )
              ],
              2
            )
          ],
          1
        )
      ]),
      _c(
        "b-col",
        { staticClass: "h-100 display-main", attrs: { cols: "12" } },
        [
          _c(
            "transition",
            { attrs: { name: "fade" } },
            [
              _vm.display !== {}
                ? _c("router-view", {
                    attrs: {
                      activeTeam: _vm.activeTeam,
                      display: Object.assign({}, _vm.display)
                    },
                    on: {
                      refreshDisplays: function($event) {
                        return _vm.$emit("refreshDisplays")
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }