var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "preview-container" },
    [
      _vm.showLive
        ? [
            _c("div", { staticClass: "live" }, [
              _vm._v("⏺ live "),
              _c("small", [_vm._v("(+" + _vm._s(_vm.time) + "s)")])
            ]),
            _c("b-img", {
              staticClass: "preview",
              style: _vm.computedStyle,
              attrs: {
                "fluid-grow": "",
                block: "",
                src: _vm.imageUrl,
                alt: "LOADING.."
              }
            })
          ]
        : [
            _c("b-img", {
              staticClass: "preview",
              style: _vm.computedStyle,
              attrs: {
                "fluid-grow": "",
                block: "",
                src: _vm.imageUrl,
                alt: "LOADING.."
              }
            })
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }