<template lang="html">
  <b-row>
    <b-col class="text-center align-middle">
      <b-row>
        <b-col class="m-0 p-1">
          <b-link :to="{ name: 'media', params: {} }">
            <b-icon class="border rounded-circle p-2 bg-light" variant="dark" icon="image" font-scale="3" ></b-icon>
          </b-link>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="m-0 p-1">
            <b>{{images.total}}</b>
            {{$tc('dashboard.mediaStats.image', images.total)}}
        </b-col>
      </b-row>
    </b-col>

    <b-col class="text-center align-middle">
      <b-row>
        <b-col class="m-0 p-1">
          <b-link :to="{ name: 'media', params: {} }">
            <b-icon class="border rounded-circle p-2 bg-light" variant="dark" icon="film" font-scale="3" ></b-icon>
          </b-link>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="m-0 p-1">
            <b>{{videos.total}}</b>
            {{$tc('dashboard.mediaStats.video', images.total)}}
        </b-col>
      </b-row>
    </b-col>

    <b-col class="text-center align-middle">
      <b-row>
        <b-col class="m-0 p-1">
          <b-icon class="border rounded-circle p-2 bg-light" variant="dark" icon="soundwave" font-scale="3" ></b-icon>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="m-0 p-1">
            <b>{{audio.total}}</b>
            {{$t('dashboard.mediaStats.audio')}}
        </b-col>
      </b-row>
    </b-col>

    <b-col class="text-center align-middle">
      <b-row>
        <b-col class="m-0 p-1">
          <b-icon class="border rounded-circle p-2 bg-light" variant="dark" icon="hdd" font-scale="3" ></b-icon>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="m-0 p-1">
            <b>{{totalUsage | humanFileSize}}</b>
            {{$t('dashboard.mediaStats.used')}}
        </b-col>
      </b-row>
    </b-col>

  </b-row>
</template>

<script>
export default {
  props: {
    refreshTime: {
      type: Number,
      required: false,
      default: 5
    }
  },
  data () {
    return {
      images: {
        size: 0,
        total: 0
      },
      videos: {
        size: 0,
        total: 0
      },
      audio: {
        size: 0,
        total: 0
      },
      interval: {}
    }
  },
  mounted () {
    this.fetchMediaStats()
    this.interval = setInterval(this.fetchMediaStats, (this.refreshTime * 60 * 1000))
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  computed: {
    totalUsage () {
      return this.images.size + this.videos.size + this.audio.size
    }
  },
  methods: {
    fetchMediaStats () {
      let url = 'v1/media/stats'
      this.$axios.get(url).then(
        (response) => {
          this.images = response.data.data.images
          this.videos = response.data.data.videos
          this.audio = response.data.data.audio
        },
        () => {
          this.images = {
            size: 0,
            total: 0
          }
          this.videos = {
            size: 0,
            total: 0
          }
          this.audio = {
            size: 0,
            total: 0
          }
        }
      )
    }
  },
  watch: {
    refreshTime () {
      clearInterval(this.interval)
      this.interval = setInterval(this.fetchMediaStats, (this.refreshTime * 60 * 1000))
    }
  }
}
</script>

<style lang="css">
</style>
