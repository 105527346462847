var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-breadcrumb",
        [
          _c(
            "b-breadcrumb-item",
            {
              attrs: { active: _vm.section === 0 },
              on: {
                click: function($event) {
                  _vm.section = 0
                }
              }
            },
            [_vm._v("Hardware Information")]
          ),
          _c(
            "b-breadcrumb-item",
            {
              attrs: { active: _vm.section === 1 },
              on: {
                click: function($event) {
                  _vm.section = 1
                }
              }
            },
            [_vm._v("Display Preferences")]
          ),
          _c(
            "b-breadcrumb-item",
            {
              attrs: { active: _vm.section === 2 },
              on: {
                click: function($event) {
                  _vm.section = 2
                }
              }
            },
            [_vm._v("Scheduling Preferences")]
          ),
          _vm.display.brightnessControl === "scheduled"
            ? _c(
                "b-breadcrumb-item",
                {
                  attrs: { active: _vm.section === 4 },
                  on: {
                    click: function($event) {
                      _vm.section = 4
                    }
                  }
                },
                [_vm._v(" Brightness")]
              )
            : _vm._e(),
          _c(
            "b-breadcrumb-item",
            {
              attrs: { active: _vm.section === 3 },
              on: {
                click: function($event) {
                  _vm.section = 3
                }
              }
            },
            [_vm._v("Location")]
          )
        ],
        1
      ),
      _vm.section === 0
        ? _c(
            "div",
            [
              _c("h3", [_vm._v("Hardware Information")]),
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "hwidset",
                    description: "Enter the key displayed on your screen",
                    label: "Hardware ID",
                    "label-for": "hwid",
                    "invalid-feedback":
                      this.keyErrors.hwid.state &&
                      this.keyErrors.hwid.tried === this.display.hardwareId
                        ? this.keyErrors.hwid.message
                        : "Invalid Hardware ID",
                    state: _vm.hwidValid
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: { id: "hwid", state: _vm.hwidValid, trim: "" },
                    model: {
                      value: _vm.display.hardwareId,
                      callback: function($$v) {
                        _vm.$set(_vm.display, "hardwareId", $$v)
                      },
                      expression: "display.hardwareId"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "typeset",
                            description: "Select the type of display",
                            label: "Display Type",
                            "label-for": "type"
                          }
                        },
                        [
                          _c("b-form-select", {
                            attrs: { id: "type", options: _vm.typeOptions },
                            model: {
                              value: _vm.display.type,
                              callback: function($$v) {
                                _vm.$set(_vm.display, "type", $$v)
                              },
                              expression: "display.type"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "rotationset",
                            description: "Select the display rotation",
                            label: "Rotation",
                            "label-for": "rotation"
                          }
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              disabled: _vm.display.type !== "LCD",
                              id: "rotation",
                              options: _vm.rotateOptions
                            },
                            model: {
                              value: _vm.display.rotate,
                              callback: function($$v) {
                                _vm.$set(_vm.display, "rotate", $$v)
                              },
                              expression: "display.rotate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "resolutionset",
                            description:
                              "Enter the display resolution (WxH, pixels)",
                            label: "Resolution",
                            "label-for": "resolution"
                          }
                        },
                        [
                          _c(
                            "b-input-group",
                            { attrs: { id: "resolution" } },
                            [
                              _c(
                                "b-input-group-prepend",
                                { attrs: { "is-text": "" } },
                                [_vm._v("Width")]
                              ),
                              _c("b-form-input", {
                                attrs: {
                                  id: "resolution-width",
                                  type: "number",
                                  min: "0"
                                },
                                model: {
                                  value: _vm.display.pixelWidth,
                                  callback: function($$v) {
                                    _vm.$set(_vm.display, "pixelWidth", $$v)
                                  },
                                  expression: "display.pixelWidth"
                                }
                              }),
                              _c(
                                "b-input-group-prepend",
                                { attrs: { "is-text": "" } },
                                [_vm._v("Height")]
                              ),
                              _c("b-form-input", {
                                attrs: {
                                  id: "resolution-height",
                                  type: "number",
                                  min: "0"
                                },
                                model: {
                                  value: _vm.display.pixelHeight,
                                  callback: function($$v) {
                                    _vm.$set(_vm.display, "pixelHeight", $$v)
                                  },
                                  expression: "display.pixelHeight"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "sizeset",
                            description:
                              "Enter the physical resolution (WxH, millimetres)",
                            label: "Physical Size",
                            "label-for": "size"
                          }
                        },
                        [
                          _c(
                            "b-input-group",
                            { attrs: { id: "size" } },
                            [
                              _c(
                                "b-input-group-prepend",
                                { attrs: { "is-text": "" } },
                                [_vm._v("Width")]
                              ),
                              _c("b-form-input", {
                                attrs: {
                                  id: "size-width",
                                  type: "number",
                                  min: "1"
                                },
                                model: {
                                  value: _vm.display.physicalWidth,
                                  callback: function($$v) {
                                    _vm.$set(_vm.display, "physicalWidth", $$v)
                                  },
                                  expression: "display.physicalWidth"
                                }
                              }),
                              _c(
                                "b-input-group-prepend",
                                { attrs: { "is-text": "" } },
                                [_vm._v("Height")]
                              ),
                              _c("b-form-input", {
                                attrs: {
                                  id: "size-height",
                                  type: "number",
                                  min: "1"
                                },
                                model: {
                                  value: _vm.display.physicalHeight,
                                  callback: function($$v) {
                                    _vm.$set(_vm.display, "physicalHeight", $$v)
                                  },
                                  expression: "display.physicalHeight"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-button",
                {
                  staticClass: "float-right mt-3 mt-3",
                  attrs: { variant: "primary" },
                  on: {
                    click: function($event) {
                      _vm.section = 1
                    }
                  }
                },
                [_vm._v("Next")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.section === 1
        ? _c(
            "div",
            [
              _c("h3", [_vm._v("Display Preferences")]),
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "nameset",
                    description: "Enter the desired name for this display",
                    label: "Display Name",
                    "label-for": "name",
                    "invalid-feedback": "Invalid Name",
                    state: _vm.nameValid
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: { id: "name", state: _vm.nameValid, trim: "" },
                    model: {
                      value: _vm.display.displayName,
                      callback: function($$v) {
                        _vm.$set(_vm.display, "displayName", $$v)
                      },
                      expression: "display.displayName"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "descriptionset",
                    description: "Enter a short description of the display",
                    label: "Description",
                    "label-for": "description",
                    "invalid-feedback": "A valid description is required",
                    state: _vm.descriptionValid
                  }
                },
                [
                  _c("b-form-textarea", {
                    attrs: {
                      id: "description",
                      placeholder: "Enter something...",
                      state: _vm.descriptionValid,
                      trim: "",
                      "max-rows": "3"
                    },
                    model: {
                      value: _vm.display.description,
                      callback: function($$v) {
                        _vm.$set(_vm.display, "description", $$v)
                      },
                      expression: "display.description"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "publicset",
                    label: "Display Publicity",
                    "label-for": "public"
                  }
                },
                [
                  _c("b-form-radio-group", {
                    attrs: {
                      id: "public",
                      options: _vm.publicOptions,
                      "button-variant": "outline-primary",
                      buttons: ""
                    },
                    model: {
                      value: _vm.display.private,
                      callback: function($$v) {
                        _vm.$set(_vm.display, "private", $$v)
                      },
                      expression: "display.private"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-button",
                {
                  staticClass: "mt-3",
                  attrs: { variant: "primary" },
                  on: {
                    click: function($event) {
                      _vm.section = 0
                    }
                  }
                },
                [_vm._v("Previous")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "float-right mt-3",
                  attrs: { variant: "primary" },
                  on: {
                    click: function($event) {
                      _vm.section = 2
                    }
                  }
                },
                [_vm._v("Next")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.section === 2
        ? _c(
            "div",
            [
              _c("h3", [_vm._v("Scheduling Preferences")]),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "blocktimeset",
                            description:
                              "Enter the total amount of time bookable on the display",
                            label: "Block Time",
                            "label-for": "blocktime"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "blocktime",
                              type: "number",
                              min: "1",
                              max: "3600"
                            },
                            model: {
                              value: _vm.display.blockTime,
                              callback: function($$v) {
                                _vm.$set(_vm.display, "blockTime", $$v)
                              },
                              expression: "display.blockTime"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "controlset",
                            description:
                              "Please select how brightness should be managed",
                            label: "Brightness Control",
                            "label-for": "control"
                          }
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              id: "control",
                              options: _vm.brightnessControls
                            },
                            model: {
                              value: _vm.display.brightnessControl,
                              callback: function($$v) {
                                _vm.$set(_vm.display, "brightnessControl", $$v)
                              },
                              expression: "display.brightnessControl"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "currencyset",
                            description:
                              "Please select the currency for transactions",
                            label: "Currency",
                            "label-for": "currency"
                          }
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              id: "currency",
                              disabled: _vm.display.private,
                              options: _vm.currencyOptions
                            },
                            model: {
                              value: _vm.display.currency,
                              callback: function($$v) {
                                _vm.$set(_vm.display, "currency", $$v)
                              },
                              expression: "display.currency"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "maxtimeset",
                            description:
                              "Enter the maximum length of a campaign bookable on the display",
                            label: "Max Time",
                            "label-for": "maxtime"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "maxtime",
                              disabled: _vm.display.private,
                              type: "number",
                              min: "1",
                              max: _vm.display.blockTime
                            },
                            model: {
                              value: _vm.display.maxTimePurchasable,
                              callback: function($$v) {
                                _vm.$set(_vm.display, "maxTimePurchasable", $$v)
                              },
                              expression: "display.maxTimePurchasable"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "baselineset",
                    description: "Enter the baseline price for bookings",
                    label: "Baseline Price",
                    "label-for": "baseline"
                  }
                },
                [
                  _c(
                    "b-input-group",
                    { attrs: { id: "baseline" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          disabled: _vm.display.private,
                          type: "number",
                          min: "0"
                        },
                        model: {
                          value: _vm.display.baselinePrice,
                          callback: function($$v) {
                            _vm.$set(_vm.display, "baselinePrice", $$v)
                          },
                          expression: "display.baselinePrice"
                        }
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-toggle",
                                  rawName: "v-b-toggle.pricecalc",
                                  modifiers: { pricecalc: true }
                                }
                              ],
                              attrs: { disabled: _vm.display.private }
                            },
                            [_vm._v("Show Price Calculator")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-collapse",
                { attrs: { id: "pricecalc" } },
                [
                  _c(
                    "b-card",
                    [
                      _c("pricing-calculator", {
                        attrs: {
                          blockTime: _vm.display.blockTime,
                          maxTimePurchasable: _vm.display.maxTimePurchasable
                        },
                        model: {
                          value: _vm.display.baselinePrice,
                          callback: function($$v) {
                            _vm.$set(_vm.display, "baselinePrice", $$v)
                          },
                          expression: "display.baselinePrice"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-button",
                {
                  staticClass: "mt-3",
                  attrs: { variant: "primary" },
                  on: {
                    click: function($event) {
                      _vm.section = 1
                    }
                  }
                },
                [_vm._v("Previous")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "float-right mt-3",
                  attrs: { variant: "primary" },
                  on: {
                    click: function($event) {
                      _vm.display.brightnessControl === "scheduled"
                        ? (_vm.section = 4)
                        : (_vm.section = 3)
                    }
                  }
                },
                [_vm._v("Next")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.section === 4
        ? _c(
            "div",
            [
              _c("h3", [_vm._v("Brightness")]),
              _c("curve", {
                attrs: { id: "curve" },
                model: {
                  value: _vm.display.brightnessCurve,
                  callback: function($$v) {
                    _vm.$set(_vm.display, "brightnessCurve", $$v)
                  },
                  expression: "display.brightnessCurve"
                }
              }),
              _c(
                "b-button",
                {
                  staticClass: "mt-3",
                  attrs: { variant: "primary" },
                  on: {
                    click: function($event) {
                      _vm.section = 2
                    }
                  }
                },
                [_vm._v("Previous")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "float-right mt-3",
                  attrs: { variant: "primary" },
                  on: {
                    click: function($event) {
                      _vm.section = 3
                    }
                  }
                },
                [_vm._v("Next")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.section === 3
        ? _c(
            "div",
            [
              _c("h3", [_vm._v("Location")]),
              _c(
                "b-form-group",
                { attrs: { id: "searchset", "label-for": "search" } },
                [
                  _c(
                    "b-input-group",
                    { attrs: { size: "sm", id: "search" } },
                    [
                      _c("b-form-input", {
                        attrs: { placeholder: "Search for a location" },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "primary" },
                              on: { click: _vm.fetchLocationFromAddress }
                            },
                            [_vm._v("Search")]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "secondary" },
                              on: {
                                click: function($event) {
                                  _vm.coordShow = !_vm.coordShow
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.coordShow ? "Hide" : "Show") +
                                  " Coordinates "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mb-2 mt-2", staticStyle: { height: "300px" } },
                [
                  _c(
                    "l-map",
                    {
                      attrs: {
                        zoom: _vm.mapSettings.zoom,
                        center: _vm.mapCenter,
                        options: _vm.mapSettings.options
                      }
                    },
                    [
                      _c("l-tile-layer", {
                        attrs: {
                          options: { maxZoom: 13 },
                          url: _vm.mapSettings.url,
                          attribution: _vm.mapSettings.attribution
                        }
                      }),
                      _c("l-marker", {
                        attrs: {
                          icon: _vm.mapSettings.iconLed,
                          "lat-lng": {
                            lat: _vm.display.latitude,
                            lng: _vm.display.longitude
                          },
                          draggable: ""
                        },
                        on: { dragend: _vm.moved }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-collapse",
                {
                  staticClass: "mt-2",
                  attrs: { id: "coord-collapse" },
                  model: {
                    value: _vm.coordShow,
                    callback: function($$v) {
                      _vm.coordShow = $$v
                    },
                    expression: "coordShow"
                  }
                },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "coordsset",
                        label: "Location Coordinates",
                        "label-for": "coords"
                      }
                    },
                    [
                      _c(
                        "b-input-group",
                        { attrs: { id: "coords" } },
                        [
                          _c(
                            "b-input-group-prepend",
                            { attrs: { "is-text": "" } },
                            [_vm._v("latitude")]
                          ),
                          _c("b-form-input", {
                            attrs: { id: "coords-latitude", type: "number" },
                            model: {
                              value: _vm.display.latitude,
                              callback: function($$v) {
                                _vm.$set(_vm.display, "latitude", $$v)
                              },
                              expression: "display.latitude"
                            }
                          }),
                          _c(
                            "b-input-group-prepend",
                            { attrs: { "is-text": "" } },
                            [_vm._v("longitude")]
                          ),
                          _c("b-form-input", {
                            attrs: { id: "coords-longitude", type: "number" },
                            model: {
                              value: _vm.display.longitude,
                              callback: function($$v) {
                                _vm.$set(_vm.display, "longitude", $$v)
                              },
                              expression: "display.longitude"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "timezoneset",
                            description:
                              "Select the timezone the display is in",
                            label: "Time Zone",
                            "label-for": "timezone"
                          }
                        },
                        [
                          _c("b-form-select", {
                            attrs: { id: "timezone", options: _vm.timezones },
                            model: {
                              value: _vm.display.timezone,
                              callback: function($$v) {
                                _vm.$set(_vm.display, "timezone", $$v)
                              },
                              expression: "display.timezone"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "mobilityset",
                            description:
                              "Select whether the display is mobile or not",
                            label: "Mobility",
                            "label-for": "mobility"
                          }
                        },
                        [
                          _c("b-form-radio-group", {
                            attrs: {
                              id: "mobility",
                              options: _vm.mobileOptions,
                              "button-variant": "outline-primary",
                              buttons: ""
                            },
                            model: {
                              value: _vm.display.mobile,
                              callback: function($$v) {
                                _vm.$set(_vm.display, "mobile", $$v)
                              },
                              expression: "display.mobile"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.$auth.user.admin
                ? _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "acidset",
                        description:
                          "A value entered here will be used instead of an automatically generated ACID",
                        label: "ACID Override",
                        "label-for": "acid"
                      }
                    },
                    [
                      _c(
                        "b-input-group",
                        { attrs: { id: "acid" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              placeholder: _vm.display.hardwareId.replace(
                                /\./gi,
                                "-"
                              ),
                              trim: ""
                            },
                            model: {
                              value: _vm.acidOverride,
                              callback: function($$v) {
                                _vm.acidOverride = $$v
                              },
                              expression: "acidOverride"
                            }
                          }),
                          _c(
                            "b-input-group-append",
                            { attrs: { "is-text": "" } },
                            [_vm._v("." + _vm._s(_vm.geoData))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-button",
                {
                  staticClass: "mt-3",
                  attrs: { variant: "primary" },
                  on: {
                    click: function($event) {
                      _vm.display.brightnessControl === "scheduled"
                        ? (_vm.section = 4)
                        : (_vm.section = 2)
                    }
                  }
                },
                [_vm._v("Previous")]
              ),
              !_vm.keyErrors.internal
                ? _c(
                    "b-button",
                    {
                      staticClass: "float-right mt-3",
                      attrs: { variant: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.validate()
                        }
                      }
                    },
                    [_vm._v("Save Display ")]
                  )
                : _c("span", { staticClass: "float-right mt-3" }, [
                    _vm._v("Please contact Adverpost support")
                  ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }