var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "centered" },
    [
      _c(
        "b-card",
        { attrs: { header: _vm.$t("account.passwordReset") } },
        [
          _c(
            "b-card-text",
            [
              _vm.errors.length > 0
                ? _c("div", { staticClass: "alert alert-danger" }, [
                    _c(
                      "ul",
                      _vm._l(_vm.errors, function(error) {
                        return _c("li", { key: error }, [
                          _vm._v(" " + _vm._s(error) + " ")
                        ])
                      }),
                      0
                    )
                  ])
                : _vm._e(),
              !_vm.sent
                ? _c(
                    "b-form",
                    {
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.submit()
                        }
                      }
                    },
                    [
                      _c("p", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("message.resetPasswordDo")) + " "
                        )
                      ]),
                      _c(
                        "b-row",
                        { staticClass: "p-2" },
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "6" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Password:",
                                    description:
                                      "Must be at least 8 characters."
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    staticClass: "mb-1",
                                    attrs: {
                                      type: "password",
                                      placeholder: _vm.$t("user.newPassword"),
                                      state: _vm.password
                                        ? _vm.password.length >= 8
                                        : null
                                    },
                                    model: {
                                      value: _vm.password,
                                      callback: function($$v) {
                                        _vm.password = $$v
                                      },
                                      expression: "password"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { cols: "6" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Confirm Password:",
                                    description: "Re-type the password."
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    staticClass: "mb-1",
                                    attrs: {
                                      type: "password",
                                      placeholder: _vm.$t(
                                        "user.confirmPassword"
                                      ),
                                      state:
                                        _vm.password &&
                                        _vm.confirmPassword &&
                                        _vm.password.length >= 8
                                          ? _vm.confirmPassword === _vm.password
                                          : null
                                    },
                                    model: {
                                      value: _vm.confirmPassword,
                                      callback: function($$v) {
                                        _vm.confirmPassword = $$v
                                      },
                                      expression: "confirmPassword"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "mb-3 float-right",
                          attrs: { variant: "primary", type: "submit" }
                        },
                        [_vm._v(_vm._s(_vm.$t("account.resetPassword")))]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.sent && _vm.success
                ? _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("notifications.passwordSuccess")) +
                        " "
                    )
                  ])
                : _vm._e(),
              _vm.sent && !_vm.success
                ? _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("notifications.passwordFailure")) +
                        " "
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }