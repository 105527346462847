var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShowing(this.text)
    ? _c("div", [
        _c(
          "span",
          {
            staticClass: "text-item",
            style: {
              fontSize: this.text.fontSize,
              fontFamily: this.text.fontFamily,
              fontStyle: this.text.fontStyle,
              fontWeight: this.text.fontWeight
            }
          },
          [_vm._v(" " + _vm._s(this.text.string) + " ")]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }