var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pt-3" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticClass: "loader"
        },
        [_c("div", { staticClass: "spinner" })]
      ),
      _c(
        "b-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading,
              expression: "!loading"
            }
          ],
          staticClass: "view h-100"
        },
        [
          _c(
            "b-col",
            { staticClass: "pb-2", attrs: { cols: "12", lg: "6" } },
            [_c("user", { attrs: { user: _vm.user } })],
            1
          ),
          _c(
            "b-col",
            { staticClass: "pb-2", attrs: { cols: "12", lg: "6" } },
            [_c("teams", { attrs: { userId: _vm.user.userId } })],
            1
          ),
          _c(
            "b-col",
            { staticClass: "pb-2", attrs: { cols: "12", lg: "6" } },
            [_c("invoices", { attrs: { userId: _vm.user.userId } })],
            1
          ),
          _c(
            "b-col",
            { staticClass: "pb-2", attrs: { cols: "12", lg: "6" } },
            [_c("event-log", { attrs: { userId: _vm.user.userId } })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }