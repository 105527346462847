var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { attrs: { header: _vm.$t("invoices.invoices") } },
    [
      _c("b-card-text", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "loader"
          },
          [_c("div", { staticClass: "spinner" })]
        ),
        _vm.invoices.length > 0
          ? _c("div", [
              _c(
                "div",
                [
                  _c(
                    "b-table-simple",
                    { staticClass: "table table-striped" },
                    [
                      _c(
                        "b-thead",
                        [
                          _c(
                            "b-tr",
                            [
                              _c("b-th", [
                                _vm._v(_vm._s(_vm.$t("dateTime.date")))
                              ]),
                              _c("b-th", [
                                _vm._v(
                                  _vm._s(_vm.$t("permissions.invoice")) + " #"
                                )
                              ]),
                              _c("b-th", [
                                _vm._v(_vm._s(_vm.$t("permissions.campaign")))
                              ]),
                              _c("b-th", [
                                _vm._v(_vm._s(_vm.$t("permissions.display")))
                              ]),
                              _c("b-th", [
                                _vm._v(_vm._s(_vm.$t("invoices.subtotal")))
                              ]),
                              _c("b-th", [
                                _vm._v(_vm._s(_vm.$t("invoices.discount")))
                              ]),
                              _c("b-th", [
                                _vm._v(_vm._s(_vm.$t("common.total")))
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-tbody",
                        _vm._l(_vm.invoices, function(invoice) {
                          return _c(
                            "b-tr",
                            { key: invoice.id },
                            [
                              _c("b-td", [_vm._v(_vm._s(invoice.date))]),
                              _c("b-td", [
                                _vm._v(_vm._s(invoice.invoiceNumber))
                              ]),
                              _c("b-td", [
                                _vm._v(_vm._s(invoice.campaignName))
                              ]),
                              _c("b-td", [_vm._v(_vm._s(invoice.displayName))]),
                              _c("b-td", [
                                _vm._v(
                                  _vm._s(
                                    (invoice.total - invoice.discount) / 100
                                  )
                                )
                              ]),
                              _c("b-td", [
                                _vm._v(_vm._s(invoice.discount / 100))
                              ]),
                              _c("b-td", [_vm._v(_vm._s(invoice.total / 100))])
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          : _c("div", [
              _c("span", [_vm._v(_vm._s(_vm.$t("message.noInvoice")))])
            ])
      ]),
      _vm.invoicesMeta.totalPages > 1
        ? _c(
            "div",
            { staticClass: "card-footer" },
            [
              _c(
                "b-button-group",
                [
                  _vm.invoicesMeta.totalPages > 1 && _vm.currentPage != 1
                    ? _c(
                        "b-button",
                        {
                          attrs: { variant: "outline-secondary" },
                          on: {
                            click: function($event) {
                              return _vm.fetchInvoices(
                                _vm.invoicesMeta.previousPage
                              )
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("buttons.previous")))]
                      )
                    : _vm._e(),
                  _vm.invoicesMeta.totalPages > 1 &&
                  _vm.currentPage != _vm.invoicesMeta.totalPages
                    ? _c(
                        "b-button",
                        {
                          attrs: { variant: "outline-secondary" },
                          on: {
                            click: function($event) {
                              return _vm.fetchInvoices(
                                _vm.invoicesMeta.nextPage
                              )
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("buttons.next")))]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }