var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c("h2", [
            _vm._v(
              _vm._s(_vm.booking.campaignName) +
                " on " +
                _vm._s(_vm.booking.displayName)
            )
          ]),
          _c(
            "b-table-simple",
            { attrs: { stacked: "", small: "" } },
            [
              _c(
                "b-tr",
                [
                  _c("b-th", [_vm._v(" Campaign ")]),
                  _c("b-td", [
                    _vm._v(" " + _vm._s(_vm.booking.campaignName) + " ")
                  ])
                ],
                1
              ),
              _c(
                "b-tr",
                [
                  _c("b-th", [_vm._v(" Booking Display ")]),
                  _c("b-td", [
                    _vm._v(" " + _vm._s(_vm.booking.displayName) + " ")
                  ])
                ],
                1
              ),
              _c(
                "b-tr",
                [
                  _c("b-th", [_vm._v(" Booking Team ")]),
                  _c("b-td", [
                    _vm._v(" " + _vm._s(_vm.booking.advertiser) + " ")
                  ])
                ],
                1
              ),
              _c(
                "b-tr",
                [
                  _c("b-th", [_vm._v(" Booking ID ")]),
                  _c("b-td", [_vm._v(_vm._s(_vm.booking.id))])
                ],
                1
              ),
              _c(
                "b-tr",
                [
                  _c("b-th", [_vm._v(" Booking Dates ")]),
                  _vm.booking.startDate === _vm.booking.endDate
                    ? _c("b-td", [
                        _vm._v(" " + _vm._s(_vm.booking.startDate) + " ")
                      ])
                    : _c("b-td", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.booking.startDate) +
                            " to " +
                            _vm._s(_vm.booking.endDate) +
                            " "
                        )
                      ])
                ],
                1
              ),
              _c(
                "b-tr",
                [
                  _c("b-th", [_vm._v(" Days Enabled ")]),
                  _c(
                    "b-td",
                    _vm._l(_vm.daysOfWeek, function(box, idx) {
                      return _c(
                        "b-badge",
                        { key: idx, attrs: { variant: box.variant } },
                        [_vm._v(" " + _vm._s(box.value) + " ")]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "b-tr",
                [
                  _c("b-th", [_vm._v(" Skipped Weeks ")]),
                  _vm.booking.skippedWeeks == 0
                    ? _c("b-td", [_vm._v(" None skipped ")])
                    : _vm.booking.skippedWeeks == 1
                    ? _c("b-td", [_vm._v(" Every Second Week ")])
                    : _c("b-td", [_vm._v(" Every Third Week ")])
                ],
                1
              ),
              _c(
                "b-tr",
                [
                  _c("b-th", [_vm._v(" Daily Play Times ")]),
                  _vm.booking.startTime === "00:00:00" &&
                  _vm.booking.endTime === "23:59:59"
                    ? _c("b-td", [_vm._v(" All Day ")])
                    : _c("b-td", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.booking.startTime) +
                            " to " +
                            _vm._s(_vm.booking.endTime) +
                            " "
                        )
                      ])
                ],
                1
              ),
              _c(
                "b-tr",
                [
                  _c("b-th", [_vm._v(" Campaign Duration ")]),
                  _c("b-td", [_vm._v(" " + _vm._s(_vm.booking.duration) + " ")])
                ],
                1
              ),
              _c(
                "b-tr",
                [
                  _c("b-th", [_vm._v(" Booking Date ")]),
                  _c("b-td", [
                    _vm._v(" " + _vm._s(_vm.booking.dateScheduled) + " ")
                  ])
                ],
                1
              ),
              _c(
                "b-tr",
                [
                  _c("b-th", [_vm._v(" Approval ")]),
                  _vm.booking.approval === "pending"
                    ? _c(
                        "b-td",
                        [_c("b-badge", [_vm._v("Pending Approval")])],
                        1
                      )
                    : _vm.booking.approval === "approved"
                    ? _c(
                        "b-td",
                        [
                          _c("b-badge", { attrs: { variant: "success" } }, [
                            _vm._v("Approved")
                          ])
                        ],
                        1
                      )
                    : _vm.booking.approval === "denied"
                    ? _c(
                        "b-td",
                        [
                          _c("b-badge", { attrs: { variant: "danger" } }, [
                            _vm._v("Pending Denied")
                          ])
                        ],
                        1
                      )
                    : _vm.booking.approval === "cancelled"
                    ? _c(
                        "b-td",
                        [
                          _c("b-badge", { attrs: { variant: "danger" } }, [
                            _vm._v("Cancelled")
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm.booking.approval === "approved"
                ? _c(
                    "b-tr",
                    [
                      _c("b-th", [_vm._v(" Status ")]),
                      (_vm.booking.endDate > _vm.date &&
                        _vm.booking.startDate < _vm.date) ||
                      (_vm.booking.endDate === _vm.date &&
                        _vm.booking.endTime <= _vm.time) ||
                      (_vm.booking.startDate === _vm.date &&
                        _vm.booking.startTime >= _vm.time)
                        ? _c(
                            "b-td",
                            [
                              _c("b-badge", { attrs: { variant: "success" } }, [
                                _vm._v("Running")
                              ])
                            ],
                            1
                          )
                        : _vm.booking.endDate < _vm.date ||
                          (_vm.booking.endDate === _vm.date &&
                            _vm.booking.endTime > _vm.time)
                        ? _c("b-td", [_c("b-badge", [_vm._v("Finished")])], 1)
                        : _vm.booking.startDate > _vm.date ||
                          (_vm.booking.startDate === _vm.date &&
                            _vm.booking.startTime < _vm.time)
                        ? _c(
                            "b-td",
                            [
                              _c("b-badge", { attrs: { variant: "warning" } }, [
                                _vm._v("Pending")
                              ])
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.booking.startDate <= _vm.date &&
              _vm.booking.startTime <= _vm.time &&
              (_vm.plays > 0 || _vm.booking.approval === "approved")
                ? _c(
                    "b-tr",
                    [
                      _c("b-th", [_vm._v(" Total Plays To Date ")]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(_vm.plays) + " / " + _vm._s(_vm.expectedPlays)
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.booking.startDate <= _vm.date &&
              _vm.booking.startTime <= _vm.time &&
              (_vm.plays > 0 || _vm.booking.approval === "approved")
                ? _c(
                    "b-tr",
                    [
                      _c("b-th", [_vm._v(" Play Time To Date ")]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            ((_vm.plays * _vm.booking.duration) / 60).toFixed(2)
                          ) +
                            " / " +
                            _vm._s(
                              (
                                (_vm.expectedPlays * _vm.booking.duration) /
                                60
                              ).toFixed(2)
                            ) +
                            " mins"
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.booking.startDate <= _vm.date &&
              _vm.booking.startTime <= _vm.time &&
              (_vm.plays > 0 || _vm.booking.approval === "approved")
                ? _c(
                    "b-tr",
                    [
                      _c("b-th", [_vm._v(" Current Playback Efficiency ")]),
                      _c("b-td", [
                        _vm._v(
                          " " +
                            _vm._s(
                              ((_vm.plays / _vm.expectedPlays) * 100).toFixed(0)
                            ) +
                            "% "
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }