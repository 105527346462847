var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-center" }, [
    _vm.item.audioId
      ? _c("div", [
          _c("audio", { staticClass: "embed-fluid", attrs: { controls: "" } }, [
            _c("source", {
              attrs: {
                src: _vm.item.uri.replace("http:", ""),
                type: _vm.item.mimeType
              }
            }),
            _vm._v(" " + _vm._s(_vm.$t("message.errorAudio")) + " ")
          ])
        ])
      : _vm._e(),
    _vm.item.imageId
      ? _c("div", [
          _c("img", {
            staticClass: "img-fluid",
            attrs: { src: _vm.item.uri.replace("http:", "") }
          })
        ])
      : _vm._e(),
    _vm.item.textId
      ? _c("div", { staticClass: "text-item" }, [
          _c(
            "span",
            {
              style: {
                fontSize: _vm.item.fontSize,
                fontFamily: _vm.item.fontFamily,
                fontStyle: _vm.item.fontStyle,
                fontWeight: _vm.item.fontWeight
              }
            },
            [_vm._v(" " + _vm._s(_vm.item.string) + " ")]
          )
        ])
      : _vm._e(),
    _vm.item.videoId
      ? _c("div", { staticClass: "embed-responsive embed-responsive-16by9" }, [
          _c(
            "video",
            { staticClass: "embed-responsive-item", attrs: { controls: "" } },
            [
              _c("source", {
                attrs: { src: _vm.item.uri.replace("http:", "") }
              }),
              _vm._v(" Your browser does not support the video tag. ")
            ]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }