var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "notifications" },
    [
      _c(
        "transition-group",
        { attrs: { name: "notifications", tag: "p" } },
        _vm._l(_vm.notifications, function(notification) {
          return _c("notification", {
            key: notification.body,
            attrs: { notification: notification }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }