import { render, staticRenderFns } from "./DisplayGallery.vue?vue&type=template&id=1da6ee60&scoped=true&lang=html&"
import script from "./DisplayGallery.vue?vue&type=script&lang=js&"
export * from "./DisplayGallery.vue?vue&type=script&lang=js&"
import style0 from "./DisplayGallery.vue?vue&type=style&index=0&id=1da6ee60&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1da6ee60",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/srv/staging_vue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1da6ee60')) {
      api.createRecord('1da6ee60', component.options)
    } else {
      api.reload('1da6ee60', component.options)
    }
    module.hot.accept("./DisplayGallery.vue?vue&type=template&id=1da6ee60&scoped=true&lang=html&", function () {
      api.rerender('1da6ee60', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/DisplayGallery.vue"
export default component.exports