var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.filteredBookings.length,
          expression: "filteredBookings.length"
        }
      ]
    },
    [
      _c(
        "b-modal",
        { attrs: { id: "mediamodal", "hide-footer": "", size: "md" } },
        [
          _c("campaign", {
            staticClass: "preview",
            attrs: { media: _vm.media }
          })
        ],
        1
      ),
      _c(
        "b-card",
        { attrs: { title: _vm.$t("dashboard.common.approvalQueue") } },
        [
          _c("b-card-text", [
            !_vm.loading
              ? _c(
                  "div",
                  [
                    _c("b-table", {
                      attrs: {
                        id: "approvalTable",
                        items: _vm.filteredBookings,
                        "per-page": _vm.perPage,
                        "current-page": _vm.currentPage,
                        fields: _vm.fields,
                        small: ""
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "cell(buttons)",
                            fn: function(row) {
                              return [
                                _c(
                                  "b-button-group",
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          variant: "secondary",
                                          size: "sm"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.preview(
                                              row.item.campaign.data.media.data
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("common.preview")) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          variant: "primary",
                                          size: "sm"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.approveCampaign(
                                              row.item.displayId,
                                              row.item.id
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "dashboard.approval.approve"
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          variant: "danger",
                                          size: "sm"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.denyCampaign(
                                              row.item.displayId,
                                              row.item.id
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("dashboard.approval.deny")
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        549696954
                      )
                    }),
                    _c("b-pagination", {
                      attrs: {
                        size: "sm",
                        "total-rows": _vm.rows,
                        "per-page": _vm.perPage,
                        "aria-controls": "approvalTable"
                      },
                      model: {
                        value: _vm.currentPage,
                        callback: function($$v) {
                          _vm.currentPage = $$v
                        },
                        expression: "currentPage"
                      }
                    })
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "loader" },
                  [_c("b-spinner", { attrs: { label: "Loading" } })],
                  1
                )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }