<template lang="html">
  <div>
    <b-breadcrumb>
      <b-breadcrumb-item :active="section===0" @click="section=0">Hardware Information</b-breadcrumb-item>
      <b-breadcrumb-item :active="section===1" @click="section=1">Display Preferences</b-breadcrumb-item>
      <b-breadcrumb-item :active="section===2" @click="section=2">Scheduling Preferences</b-breadcrumb-item>
      <b-breadcrumb-item :active="section===4" @click="section=4" v-if="display.brightnessControl === 'scheduled'">
        Brightness</b-breadcrumb-item>
      <b-breadcrumb-item :active="section===3" @click="section=3">Location</b-breadcrumb-item>
    </b-breadcrumb>
    <div v-if="section===0">
      <h3>Hardware Information</h3>
      <b-form-group id="hwidset" description="Enter the key displayed on your screen" label="Hardware ID"
        label-for="hwid"
        :invalid-feedback="this.keyErrors.hwid.state && this.keyErrors.hwid.tried === this.display.hardwareId ? this.keyErrors.hwid.message : 'Invalid Hardware ID'"
        :state="hwidValid">
        <b-form-input id="hwid" v-model="display.hardwareId" :state="hwidValid" trim></b-form-input>
      </b-form-group>
      <b-row>
        <b-col cols="6">
          <b-form-group id="typeset" description="Select the type of display" label="Display Type" label-for="type">
            <b-form-select id="type" v-model="display.type" :options="typeOptions"></b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group id="rotationset" description="Select the display rotation" label="Rotation"
            label-for="rotation">
            <b-form-select :disabled="display.type!=='LCD'" id="rotation" v-model="display.rotate"
              :options="rotateOptions">
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group id="resolutionset" description="Enter the display resolution (WxH, pixels)" label="Resolution"
            label-for="resolution">
            <b-input-group id="resolution">
              <b-input-group-prepend is-text>Width</b-input-group-prepend>
              <b-form-input id="resolution-width" type="number" min=0 v-model="display.pixelWidth"></b-form-input>
              <b-input-group-prepend is-text>Height</b-input-group-prepend>
              <b-form-input id="resolution-height" type="number" min=0 v-model="display.pixelHeight"></b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group id="sizeset" description="Enter the physical resolution (WxH, millimetres)"
            label="Physical Size" label-for="size">
            <b-input-group id="size">
              <b-input-group-prepend is-text>Width</b-input-group-prepend>
              <b-form-input id="size-width" type="number" min=1 v-model="display.physicalWidth"></b-form-input>
              <b-input-group-prepend is-text>Height</b-input-group-prepend>
              <b-form-input id="size-height" type="number" min=1 v-model="display.physicalHeight"></b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-button variant="primary" @click="section = 1" class="float-right mt-3 mt-3">Next</b-button>
    </div>
    <div v-if="section===1">
      <h3>Display Preferences</h3>
      <b-form-group id="nameset" description="Enter the desired name for this display" label="Display Name"
        label-for="name" invalid-feedback="Invalid Name" :state="nameValid">
        <b-form-input id="name" v-model="display.displayName" :state="nameValid" trim></b-form-input>
      </b-form-group>
      <b-form-group id="descriptionset" description="Enter a short description of the display" label="Description"
        label-for="description" invalid-feedback="A valid description is required" :state="descriptionValid">
        <b-form-textarea id="description" v-model="display.description" placeholder="Enter something..."
          :state="descriptionValid" trim max-rows="3">
        </b-form-textarea>
      </b-form-group>
      <b-form-group id="publicset" label="Display Publicity" label-for="public">
        <b-form-radio-group id="public" v-model="display.private" :options="publicOptions"
          button-variant="outline-primary" buttons></b-form-radio-group>
      </b-form-group>
      <b-button variant="primary" @click="section = 0" class="mt-3">Previous</b-button>
      <b-button variant="primary" @click="section = 2" class="float-right mt-3">Next</b-button>
    </div>
    <div v-if="section===2">
      <h3>Scheduling Preferences</h3>
      <b-row>
        <b-col cols="6">
          <b-form-group id="blocktimeset" description="Enter the total amount of time bookable on the display"
            label="Block Time" label-for="blocktime">
            <b-form-input id="blocktime" v-model="display.blockTime" type="number" min=1 max=3600></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group id="controlset" description="Please select how brightness should be managed"
            label="Brightness Control" label-for="control">
            <b-form-select id="control" v-model="display.brightnessControl" :options="brightnessControls">
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group id="currencyset" description="Please select the currency for transactions" label="Currency"
            label-for="currency">
            <b-form-select id="currency" :disabled="display.private" v-model="display.currency"
              :options="currencyOptions"></b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group id="maxtimeset" description="Enter the maximum length of a campaign bookable on the display"
            label="Max Time" label-for="maxtime">
            <b-form-input id="maxtime" :disabled="display.private" v-model="display.maxTimePurchasable" type="number"
              min=1 :max="display.blockTime"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group id="baselineset" description="Enter the baseline price for bookings" label="Baseline Price"
        label-for="baseline">
        <b-input-group id="baseline">
          <b-form-input :disabled="display.private" v-model="display.baselinePrice" type="number" min=0>
          </b-form-input>
          <b-input-group-append>
            <b-button :disabled="display.private" v-b-toggle.pricecalc>Show Price Calculator</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-collapse id="pricecalc">
        <b-card>
          <pricing-calculator v-model="display.baselinePrice" :blockTime="display.blockTime"
            :maxTimePurchasable="display.maxTimePurchasable"></pricing-calculator>
        </b-card>
      </b-collapse>
      <b-button variant="primary" @click="section = 1" class="mt-3">Previous</b-button>
      <b-button variant="primary" @click="display.brightnessControl === 'scheduled' ? section=4 : section = 3"
        class="float-right mt-3">Next</b-button>
    </div>
    <div v-if="section===4">
      <h3>Brightness</h3>
      <curve id="curve" v-model="display.brightnessCurve"></curve>
      <b-button variant="primary" @click="section = 2" class="mt-3">Previous</b-button>
      <b-button variant="primary" @click="section = 3" class="float-right mt-3">Next</b-button>
    </div>
    <div v-if="section===3">
      <h3>Location</h3>
      <b-form-group id="searchset" label-for="search">
        <b-input-group size="sm" id="search">
          <b-form-input v-model="search" placeholder="Search for a location"></b-form-input>
          <b-input-group-append>
            <b-button variant="primary" @click="fetchLocationFromAddress">Search</b-button>
            <b-button variant="secondary" @click="coordShow = !coordShow">{{coordShow ? 'Hide' : 'Show'}} Coordinates
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <div style="height: 300px" class="mb-2 mt-2">
        <l-map :zoom="mapSettings.zoom" :center="mapCenter" :options="mapSettings.options">
          <l-tile-layer :options="{ maxZoom: 13 }" :url="mapSettings.url" :attribution="mapSettings.attribution" />
          <l-marker :icon="mapSettings.iconLed" :lat-lng="{lat: display.latitude, lng: display.longitude}" draggable
            @dragend="moved">
          </l-marker>
        </l-map>
      </div>
      <b-collapse id="coord-collapse" v-model="coordShow" class="mt-2">
        <b-form-group id="coordsset" label="Location Coordinates" label-for="coords">
          <b-input-group id="coords">
            <b-input-group-prepend is-text>latitude</b-input-group-prepend>
            <b-form-input id="coords-latitude" type="number" v-model="display.latitude"></b-form-input>
            <b-input-group-prepend is-text>longitude</b-input-group-prepend>
            <b-form-input id="coords-longitude" type="number" v-model="display.longitude"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-collapse>
      <b-row>
        <b-col cols="6">
          <b-form-group id="timezoneset" description="Select the timezone the display is in" label="Time Zone"
            label-for="timezone">
            <b-form-select id="timezone" v-model="display.timezone" :options="timezones"></b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group id="mobilityset" description="Select whether the display is mobile or not" label="Mobility"
            label-for="mobility">
            <b-form-radio-group id="mobility" v-model="display.mobile" :options="mobileOptions"
              button-variant="outline-primary" buttons></b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group v-if="$auth.user.admin" id="acidset"
        description="A value entered here will be used instead of an automatically generated ACID" label="ACID Override"
        label-for="acid">
        <b-input-group id="acid">
          <b-form-input v-model="acidOverride" :placeholder="display.hardwareId.replace(/\./gi, '-')" trim>
          </b-form-input>
          <b-input-group-append is-text>.{{geoData}}</b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-button variant="primary" @click="display.brightnessControl === 'scheduled' ? section=4 : section = 2"
        class="mt-3">Previous</b-button>
      <b-button v-if="!keyErrors.internal" variant="primary" @click="validate()" class="float-right mt-3">Save Display
      </b-button>
      <span class="float-right mt-3" v-else>Please contact Adverpost support</span>
    </div>
  </div>
</template>

<script>
import teamContext from "@/mixins/teamContext";
import moment from "moment-timezone";
import Curve from "./BrightnessCurve.vue";
import PricingCalculator from "./PricingCalculator.vue";

import { latLng, icon } from "leaflet";
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
require("leaflet/dist/leaflet.css");

export default {
  mixins: [teamContext],
  components: {
    LMap,
    LTileLayer,
    LMarker,
    Curve,
    PricingCalculator,
  },
  props: {},
  data() {
    return {
      section: 0,
      taken: false,
      search: "",
      coordShow: false,
      publicOptions: [
        {
          value: false,
          text: "Public",
        },
        {
          value: true,
          text: "Private",
        },
      ],
      typeOptions: [
        {
          value: "LED Outdoors",
          text: "LED Outdoors",
        },
        {
          value: "LED Indoors",
          text: "LED Indoors",
        },
        {
          value: "LCD",
          text: "LCD",
        },
      ],
      mobileOptions: [
        {
          value: false,
          text: "Static",
        },
        {
          value: true,
          text: "Mobile",
        },
      ],
      currencyOptions: [
        {
          text: this.$t("currency.fullAud"),
          value: "AUD",
        },
        {
          text: this.$t("currency.fullCny"),
          value: "CNY",
        },
      ],
      brightnessControls: [
        {
          text: this.$t("brightnessControl.maximum"),
          value: "maximum",
        },
        {
          text: this.$t("brightnessControl.sensor"),
          value: "sensor",
        },
        {
          text: this.$t("brightnessControl.scheduled"),
          value: "scheduled",
        },
      ],
      rotateOptions: [
        {
          value: "0",
          text: "No Rotation",
        },
        {
          value: "90",
          text: "90",
        },
        {
          value: "180",
          text: "180",
        },
        {
          value: "270",
          text: "270",
        },
      ],
      timezones: moment.tz.names(),
      display: {
        teamName: this.activeTeam.name,
        displayName: "",
        description: "",
        hardwareId: "",
        type: "LED Outdoors",
        currency: "AUD",
        pixelWidth: 0,
        pixelHeight: 0,
        physicalWidth: 1,
        physicalHeight: 1,
        baselinePrice: 0,
        rotate: 0,
        acid: null,
        timezone: "Australia/Adelaide",
        private: true,
        demoMode: false,
        blockTime: 120,
        maxTimePurchasable: 0,
        brightnessControl: "maximum",
        brightnessCurve: {
          0: "100",
          6: "100",
          12: "100",
          18: "100",
          24: "100",
        },
        mobile: false,
        longitude: 138.574,
        latitude: -34.9839,
      },
      keyErrors: {
        internal: false,
        hwid: {
          message: "",
          tried: "",
          state: false,
        },
        name: false,
        description: false,
      },
      mapSettings: {
        url: "https://cors.adverpost.com/https/tiles.stadiamaps.com/tiles/stamen_terrain/{z}/{x}/{y}{r}.png",
        attribution:
          'Map tiles by &copy; <a href="//stamen.com">Stamen Design</a>' +
          '<a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> \n' +
          '<a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> \n' +
          '<a href="http://https://creativecommons.org/licenses/by/4.0/">CC BY 4.0</a>' +
          '&mdash; Map data &copy; <a href="http://www.openstreetmap.org/about/">OpenStreetMap</a>',
        zoom: 12,
        options: {
          zoomSnap: 0.5,
        },
        iconLed: icon({
          iconUrl: require("@/assets/pin-led.png"),
          shadowUrl: require("@/assets/shadow.png"),
          iconSize: [30, 45],
          iconAnchor: [0, 42],
          popupAnchor: [15, -20],
        }),
      },
      mapCenter: latLng(-34.98385, 138.57395),
      geoData: "5039.sa.au.adverpost.com",
      acidOverride: "",
    };
  },
  computed: {
    hwidValid() {
      if (
        this.keyErrors.hwid.state === true &&
        this.keyErrors.hwid.tried === this.display.hardwareId
      ) {
        return false;
      } else if (this.display.hardwareId === "") {
        return null;
      } else if (
        this.display.hardwareId.length === 11 &&
        /^[a-zA-Z0-9.]*$/.test(this.display.hardwareId)
      ) {
        return true;
      } else {
        return false;
      }
    },
    nameValid() {
      if (this.keyErrors.name === true) {
        return false;
      } else if (this.display.displayName === "") {
        return null;
      } else if (
        this.display.displayName.length > 0 &&
        this.display.displayName.length < 255
      ) {
        return true;
      } else {
        return false;
      }
    },
    descriptionValid() {
      if (this.keyErrors.description === true) {
        return false;
      } else if (this.display.description === "") {
        return null;
      } else if (
        this.display.description.length > 0 &&
        this.display.description.length < 255
      ) {
        return true;
      } else {
        return false;
      }
    },
    autoAcid() {
      if (this.acidOverride !== "") {
        return this.acidOverride + "." + this.geoData;
      } else if (this.display.hardwareId !== "") {
        return (
          this.display.hardwareId.replace(/\./gi, "-") + "." + this.geoData
        );
      } else {
        return "." + this.geoData;
      }
    },
  },
  mounted() {},
  methods: {
    moved(e) {
      let coords = e.target.getLatLng();
      this.display.latitude = coords.lat;
      this.display.longitude = coords.lng;
      this.fetchGeoDataFromCoords();
    },
    fetchLocationFromAddress() {
      let params = {
        address: this.search,
      };
      this.$axios
        .get("v1/location", {
          params: params,
        })
        .then(
          (response) => {
            this.display.latitude = response.data.latitude;
            this.display.longitude = response.data.longitude;
            this.mapCenter = latLng(
              response.data.latitude,
              response.data.longitude
            );
            this.fetchGeoDataFromCoords();
          },
          () => {}
        );
    },
    fetchGeoDataFromCoords() {
      let params = {
        longitude: this.display.longitude,
        latitude: this.display.latitude,
      };
      this.$axios
        .get("v1/location/reverse", {
          params: params,
        })
        .then(
          (response) => {
            let postCode = response.data.postalCode;
            let state = response.data.adminLevels["1"].code.toLowerCase();
            let country = response.data.countryCode.toLowerCase();
            this.geoData = [postCode, state, country, "adverpost.com"].join(
              "."
            );
          },
          () => {
            this.keyErrors.internal = true;
            return;
          }
        );
    },
    resetErrorState() {
      this.keyErrors = {
        internal: false,
        hwid: {
          message: "",
          tried: "",
          state: false,
        },
        name: false,
        description: false,
      };
    },
    validate() {
      this.resetErrorState();
      let error = null;
      if (this.display.teamName === null) {
        this.keyErrors.internal = true;
        return;
      }
      if (this.display.blockTime < 1 && this.display.blockTime > 3600) {
        error = 2;
      }
      if (!this.display.private) {
        if (this.display.maxTimePurchasable > this.display.blockTime) {
          error = 2;
        }
      }
      if (this.nameValid !== true) {
        this.keyErrors.name = true;
        error = 1;
      }
      if (this.descriptionValid !== true) {
        this.keyErrors.description = true;
        error = 1;
      }
      if (this.hwidValid !== true) {
        this.keyErrors.hwid.tried = this.display.hardwareId;
        this.keyErrors.hwid.state = true;
        this.keyErrors.hwid.message = "Invalid Hardware ID";
        error = 0;
      }
      if (this.display.physicalWidth < 1 || this.display.physicalHeight < 1) {
        error = 0;
      }
      if (this.display.pixelWidth < 0 || this.display.pixelHeight < 0) {
        error = 0;
      }

      if (error !== null) {
        this.section = error;
      } else {
        this.submitDisplay();
      }
    },
    submitDisplay() {
      var scope = this;
      this.display.acid = this.autoAcid;
      this.$axios
        .post("v1/displays", this.display)
        .then((response) => {
          let displayId = response.data.data.displayId;
          if (!this.display.private) {
            this.saveDefault(displayId);
          } else {
            this.$emit("refreshDisplays");
          }
        })
        .catch(function (error) {
          if (!error.response) {
            scope.$notifications.add({
              title: "Error",
              type: "warning",
              body: error.message,
              timeout: true,
            });
          } else if (
            error.response.data.message == "The team you entered does not exist"
          ) {
            this.keyErrors.internal = true;
          } else if (
            error.response.data.message ==
            "Another display with this Hardware ID already exists"
          ) {
            this.keyErrors.hwid.tried = this.display.hardwareId;
            this.keyErrors.hwid.state = true;
            this.keyErrors.hwid.message =
              "This Hardware ID is already registered";
            this.section = 0;
          } else if (error.response.status !== 404) {
            scope.$notifications.add({
              title: "Error",
              type: "warning",
              body: error.response.data.message,
              timeout: true,
            });
          }
        });
    },
    saveDefault(displayId) {
      let pricing = {};
      pricing.currency = this.display.currency;
      pricing.scheduleName = "Default";
      pricing.priority = 0;
      pricing.startTime = "00:00:00";
      pricing.endTime = "23:59:59";
      pricing.startDate = moment().format("YYYY-MM-DD");
      pricing.endDate = moment().year("2037").format("YYYY-MM-DD");
      pricing.displayDefault = true;
      pricing.displayId = displayId;
      pricing.pricePerSecond = this.display.baselinePrice;
      let url = "v1/displays/" + displayId + "/pricing/";
      this.$axios.post(url, pricing).then(
        () => {
          this.$emit("refreshDisplays");
        },
        () => {
          //error?
        }
      );
    },
  },
};
</script>

<style lang="css">
</style>
