var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.isLoaded
    ? _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "text-center" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "m-2",
                      attrs: { variant: "primary" },
                      on: { click: _vm.printReport }
                    },
                    [_vm._v("Print Report")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { attrs: { id: "report" } },
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { cols: "4" } }, [
                        _c("img", {
                          staticClass: "img-responsive",
                          attrs: { src: "/static/logo_black.png" }
                        })
                      ]),
                      _c("b-col", { attrs: { cols: "6" } }, [
                        _c("h3", [_vm._v("Campaign Playback Report")])
                      ]),
                      _c(
                        "b-col",
                        { staticClass: "text-right", attrs: { cols: "2" } },
                        [
                          _c(
                            "b-row",
                            [_c("b-col", [_vm._v(" generated on ")])],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _vm._v(" " + _vm._s(_vm.currentDate) + " ")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _vm._v(" " + _vm._s(_vm.currentTime) + " ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "pt-4 text-center" },
                    [
                      _c("b-col", [_c("h5", [_vm._v("Display Details")])]),
                      _c("b-col", [_c("h5", [_vm._v("Campaign Details")])]),
                      _c("b-col", [_c("h5", [_vm._v("Media Details")])])
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        _vm._l(
                          [
                            "display_name",
                            "display_owner",
                            "display_contact",
                            "display_resolution",
                            "display_block_time",
                            "display_size",
                            "display_pixel_ratio"
                          ],
                          function(key) {
                            return _c(
                              "b-row",
                              { key: key },
                              [
                                _c(
                                  "b-col",
                                  { staticClass: "pt-1 font-weight-bold" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("report." + key)) +
                                        " "
                                    )
                                  ]
                                ),
                                _c("b-col", [
                                  _vm._v(" " + _vm._s(_vm.report[key]) + " ")
                                ])
                              ],
                              1
                            )
                          }
                        ),
                        1
                      ),
                      _c(
                        "b-col",
                        _vm._l(
                          [
                            "campaign_name",
                            "start_date",
                            "end_date",
                            "start_time_daily",
                            "end_time_daily",
                            "total_duration"
                          ],
                          function(key) {
                            return _c(
                              "b-row",
                              { key: key },
                              [
                                _c(
                                  "b-col",
                                  { staticClass: "pt-1 font-weight-bold" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("report." + key)) +
                                        " "
                                    )
                                  ]
                                ),
                                _c("b-col", [
                                  _vm._v(" " + _vm._s(_vm.report[key]) + " ")
                                ])
                              ],
                              1
                            )
                          }
                        ),
                        1
                      ),
                      _c(
                        "b-col",
                        _vm._l(
                          [
                            "media_items",
                            "media_size",
                            "media_types",
                            "min_max_width",
                            "min_max_height",
                            "avg_aspect_ratio"
                          ],
                          function(key) {
                            return _c(
                              "b-row",
                              { key: key },
                              [
                                _c(
                                  "b-col",
                                  { staticClass: "pt-1 font-weight-bold" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("report." + key)) +
                                        " "
                                    )
                                  ]
                                ),
                                _c("b-col", [
                                  _vm._v(" " + _vm._s(_vm.report[key]) + " ")
                                ])
                              ],
                              1
                            )
                          }
                        ),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "text-center pt-4" },
                    [
                      _c("b-col", [_c("h5", [_vm._v("Scheduled Data")])]),
                      _c("b-col", [_c("h5", [_vm._v("Live Data")])]),
                      _c("b-col", [_c("h5", [_vm._v("Other Details")])])
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        _vm._l(
                          [
                            "expected_total_plays",
                            "expected_current_plays",
                            "current_progress",
                            "expected_duration"
                          ],
                          function(key) {
                            return _c(
                              "b-row",
                              { key: key },
                              [
                                _c(
                                  "b-col",
                                  { staticClass: "pt-1 font-weight-bold" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("report." + key)) +
                                        " "
                                    )
                                  ]
                                ),
                                _c("b-col", [
                                  _vm._v(" " + _vm._s(_vm.report[key]) + " ")
                                ])
                              ],
                              1
                            )
                          }
                        ),
                        1
                      ),
                      _c(
                        "b-col",
                        _vm._l(
                          [
                            "total_plays_to_date",
                            "extra_plays",
                            "average_duration",
                            "min_duration",
                            "max_duration",
                            "deviation_margin"
                          ],
                          function(key) {
                            return _c(
                              "b-row",
                              { key: key },
                              [
                                _c(
                                  "b-col",
                                  { staticClass: "pt-1 font-weight-bold" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("report." + key)) +
                                        " "
                                    )
                                  ]
                                ),
                                _c("b-col", [
                                  _vm._v(" " + _vm._s(_vm.report[key]) + " ")
                                ])
                              ],
                              1
                            )
                          }
                        ),
                        1
                      ),
                      _c(
                        "b-col",
                        _vm._l(
                          [
                            "efficiency_percent",
                            "minimum_brightness",
                            "maximum_brightness"
                          ],
                          function(key) {
                            return _c(
                              "b-row",
                              { key: key },
                              [
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "pt-1 font-weight-bold",
                                    attrs: { cols: "8" }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("report." + key)) +
                                        " "
                                    )
                                  ]
                                ),
                                _c("b-col", { attrs: { cols: "4" } }, [
                                  _vm._v(" " + _vm._s(_vm.report[key]) + " ")
                                ])
                              ],
                              1
                            )
                          }
                        ),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "text-center pt-4" },
                    [
                      _c("b-col", [_c("h5", [_vm._v("Key Information")])]),
                      _c("b-col", [
                        _c("h5", [_vm._v("Financial Information")])
                      ]),
                      _c("b-col", [_c("h5", [_vm._v("System Health")])])
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        _vm._l(
                          [
                            "playback_quality",
                            "playback_rating",
                            "content_fit",
                            "content_scale",
                            "extra_value"
                          ],
                          function(key) {
                            return _c(
                              "b-row",
                              { key: key },
                              [
                                _c(
                                  "b-col",
                                  { staticClass: "pt-1 font-weight-bold" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("report." + key)) +
                                        " "
                                    )
                                  ]
                                ),
                                _c("b-col", [
                                  _vm._v(" " + _vm._s(_vm.report[key]) + " ")
                                ])
                              ],
                              1
                            )
                          }
                        ),
                        1
                      ),
                      _c(
                        "b-col",
                        _vm._l(
                          [
                            "buyer_name",
                            "buyer_contact",
                            "total_cost",
                            "actual_cost",
                            "discount",
                            "gst",
                            "payout"
                          ],
                          function(key) {
                            return _c(
                              "b-row",
                              { key: key },
                              [
                                _c(
                                  "b-col",
                                  { staticClass: "pt-1 font-weight-bold" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("report." + key)) +
                                        " "
                                    )
                                  ]
                                ),
                                _c("b-col", [
                                  _vm._v(" " + _vm._s(_vm.report[key]) + " ")
                                ])
                              ],
                              1
                            )
                          }
                        ),
                        1
                      ),
                      _c(
                        "b-col",
                        _vm._l([], function(key) {
                          return _c(
                            "b-row",
                            { key: key },
                            [
                              _c(
                                "b-col",
                                { staticClass: "pt-1 font-weight-bold" },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("report." + key)) + " "
                                  )
                                ]
                              ),
                              _c("b-col", [
                                _vm._v(" " + _vm._s(_vm.report[key]) + " ")
                              ])
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c("h5", [_vm._v("Media Timings")]),
                          _c(
                            "b-table-simple",
                            { attrs: { small: "" } },
                            [
                              _c("b-th", [_vm._v("Type")]),
                              _c("b-th", [_vm._v("Filename")]),
                              _c("b-th", [_vm._v("Size")]),
                              _c("b-th", [_vm._v("Width")]),
                              _c("b-th", [_vm._v("Height")]),
                              _c("b-th", [_vm._v("Start")]),
                              _c("b-th", [_vm._v("End")]),
                              _vm._l(_vm.report.media, function(media, index) {
                                return _c(
                                  "b-tr",
                                  { key: index },
                                  [
                                    _c("b-td", [
                                      _vm._v(" " + _vm._s(media.type))
                                    ]),
                                    _c("b-td", [
                                      _vm._v(" " + _vm._s(media.name))
                                    ]),
                                    _c("b-td", [
                                      _vm._v(" " + _vm._s(media.size))
                                    ]),
                                    _c("b-td", [
                                      _vm._v(" " + _vm._s(media.width))
                                    ]),
                                    _c("b-td", [
                                      _vm._v(" " + _vm._s(media.height))
                                    ]),
                                    _c("b-td", [
                                      _vm._v(" " + _vm._s(media.start))
                                    ]),
                                    _c("b-td", [
                                      _vm._v(" " + _vm._s(media.end))
                                    ])
                                  ],
                                  1
                                )
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _c(
        "b-container",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _vm._v(" Generating report, this might take a minute.. "),
                  _c("b-spinner", { attrs: { small: "", variant: "danger" } })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }