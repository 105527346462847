import { render, staticRenderFns } from "./Image.vue?vue&type=template&id=1d153634&scoped=true&lang=html&"
import script from "./Image.vue?vue&type=script&lang=js&"
export * from "./Image.vue?vue&type=script&lang=js&"
import style0 from "./Image.vue?vue&type=style&index=0&id=1d153634&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d153634",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/srv/staging_vue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1d153634')) {
      api.createRecord('1d153634', component.options)
    } else {
      api.reload('1d153634', component.options)
    }
    module.hot.accept("./Image.vue?vue&type=template&id=1d153634&scoped=true&lang=html&", function () {
      api.rerender('1d153634', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Preview/Image.vue"
export default component.exports