var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        [
          _vm.errors.length > 0
            ? _c(
                "b-col",
                { staticClass: "alert alert-danger", attrs: { cols: "12" } },
                [
                  _c(
                    "ul",
                    _vm._l(_vm.errors, function(error, index) {
                      return _c("li", { key: index }, [
                        _vm._v(" " + _vm._s(error) + " ")
                      ])
                    }),
                    0
                  )
                ]
              )
            : _vm._e(),
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _c("h4", [
                  _vm._v(
                    "[E2V] " +
                      _vm._s(_vm.$t("common.schedule")) +
                      " " +
                      _vm._s(this.source.name) +
                      " " +
                      _vm._s(_vm.$t("article.on")) +
                      " " +
                      _vm._s(this.display.name) +
                      " "
                  )
                ])
              ])
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "6" } },
                [
                  _c(
                    "b-card",
                    {
                      staticClass: "mb-3",
                      attrs: { header: "Booking Duration" }
                    },
                    [
                      _c(
                        "b-card-text",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t("dateTime.sDate"),
                                "label-for": "startDate"
                              }
                            },
                            [
                              _c("flatpickr", {
                                attrs: {
                                  config: _vm.flatpickrConfig,
                                  options: _vm.startDateOptions,
                                  placeholder: _vm.$t("dateTime.date"),
                                  id: "startDate"
                                },
                                model: {
                                  value: _vm.bookingData.startDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.bookingData, "startDate", $$v)
                                  },
                                  expression: "bookingData.startDate"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t("dateTime.eDate"),
                                "label-for": "endDate"
                              }
                            },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("flatpickr", {
                                    attrs: {
                                      disabled: _vm.forever,
                                      config: _vm.flatpickrConfig,
                                      options: _vm.endDateOptions,
                                      placeholder: _vm.$t("dateTime.date"),
                                      id: "endDate"
                                    },
                                    model: {
                                      value: _vm.bookingData.endDate,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.bookingData,
                                          "endDate",
                                          $$v
                                        )
                                      },
                                      expression: "bookingData.endDate"
                                    }
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    {
                                      attrs: { id: "foreverBox", "is-text": "" }
                                    },
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          attrs: {
                                            id: "forever",
                                            name: "forever",
                                            value: true,
                                            "unchecked-value": false
                                          },
                                          model: {
                                            value: _vm.forever,
                                            callback: function($$v) {
                                              _vm.forever = $$v
                                            },
                                            expression: "forever"
                                          }
                                        },
                                        [_c("b-icon-arrow-repeat")],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-popover",
                            {
                              attrs: {
                                target: "foreverBox",
                                triggers: "hover focus"
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("popover.forever")) + " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "6" } },
                [
                  _c(
                    "b-card",
                    {
                      staticClass: "mb-3",
                      attrs: { header: "Daily Run Times" }
                    },
                    [
                      _c(
                        "b-card-text",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t("dateTime.sTime"),
                                "label-for": "startTime"
                              }
                            },
                            [
                              _c(
                                "b-dropdown",
                                {
                                  ref: "startDropdown",
                                  staticClass: "w-100 text-left",
                                  attrs: {
                                    "no-caret": "",
                                    id: "startTime",
                                    variant: "outline-secondary"
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "button-content",
                                      fn: function() {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "text-left" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.bookingData.startTime
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ])
                                },
                                _vm._l(4, function(n) {
                                  return _c(
                                    "b-button-group",
                                    {
                                      key: n.id,
                                      staticClass: "ml-2 mr-2",
                                      attrs: { size: "sm" }
                                    },
                                    _vm._l(6, function(i) {
                                      return _c(
                                        "b-button",
                                        {
                                          key: i.id,
                                          staticClass: "timebutton",
                                          on: {
                                            click: function($event) {
                                              _vm.bookingData.startTime =
                                                ((n - 1) * 6 + (i - 1))
                                                  .toString()
                                                  .padStart(2, "0") + ":00:00"
                                              _vm.$refs.startDropdown.hide(true)
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s((n - 1) * 6 + (i - 1)) +
                                              ":00"
                                          )
                                        ]
                                      )
                                    }),
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t("dateTime.eTime"),
                                "label-for": "endTime"
                              }
                            },
                            [
                              _c(
                                "b-dropdown",
                                {
                                  ref: "endDropdown",
                                  staticClass: "w-100",
                                  attrs: {
                                    "no-caret": "",
                                    id: "endTime",
                                    variant: "outline-secondary"
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "button-content",
                                      fn: function() {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "text-left" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.bookingData.endTime
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ])
                                },
                                _vm._l(4, function(m) {
                                  return _c(
                                    "b-button-group",
                                    {
                                      key: m.id,
                                      staticClass: "ml-2 mr-2",
                                      attrs: { size: "sm" }
                                    },
                                    _vm._l(6, function(o) {
                                      return _c(
                                        "b-button",
                                        {
                                          key: o.id,
                                          staticClass: "timebutton",
                                          on: {
                                            click: function($event) {
                                              _vm.bookingData.endTime =
                                                ((m - 1) * 6 + o)
                                                  .toString()
                                                  .padStart(2, "0") + ":00:00"
                                              _vm.$refs.endDropdown.hide(true)
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s((m - 1) * 6 + o) +
                                              ":00"
                                          )
                                        ]
                                      )
                                    }),
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("hr"),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "inspector",
                    {
                      attrs: { uri: _vm.source.uri },
                      on: { changed: _vm.updateE2V }
                    },
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", [_vm._v("Time Available")]),
                          _c("b-col", [
                            _c("label", [
                              _vm._v(_vm._s(_vm.timeRemaining) + " seconds")
                            ])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c("b-col", [_c("b", [_vm._v("E2V Duration")])]),
                          _c(
                            "b-col",
                            [
                              _c("b-form-input", {
                                attrs: { id: "sourceDuration" },
                                model: {
                                  value: _vm.sourceDuration,
                                  callback: function($$v) {
                                    _vm.sourceDuration = $$v
                                  },
                                  expression: "sourceDuration"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "pt-4" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  !_vm.selectDisabled
                    ? _c(
                        "b-button",
                        {
                          staticClass: "float-left btn btn-primary",
                          attrs: { variant: "primary" },
                          on: { click: _vm.back }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("buttons.back")))]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      staticClass: "float-right btn btn-primary",
                      attrs: { variant: "primary", disabled: !_vm.schedulable },
                      on: {
                        click: function($event) {
                          return _vm.schedule()
                        }
                      }
                    },
                    [_vm._v(" Connect ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }