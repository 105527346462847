var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row mt-2" },
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "delete-modal",
            title: _vm.$t("actions.delete") + " " + _vm.source.name
          }
        },
        [
          _vm._v(" " + _vm._s(_vm.$t("message.sourceDelete")) + " "),
          _c("div", { attrs: { slot: "modal-footer" }, slot: "modal-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn delete-button btn-primary left",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    _vm.deleteSource()
                    _vm.$bvModal.hide("delete-modal")
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("answers.yes")))]
            ),
            _c(
              "button",
              {
                staticClass: "btn delete-button btn-primary right",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.$bvModal.hide("delete-modal")
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("answers.no")))]
            )
          ])
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: { id: "edit-modal", size: "xl", "hide-footer": "" },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function() {
                return [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.editSource.name,
                        expression: "editSource.name"
                      }
                    ],
                    staticClass: "modal-title h4",
                    attrs: { placeholder: _vm.$t("sources.sourceNamePH") },
                    domProps: { value: _vm.editSource.name },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.editSource, "name", $event.target.value)
                      }
                    }
                  })
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm.errors.length > 0
            ? _c("div", { staticClass: "alert alert-danger" }, [
                _c(
                  "ul",
                  _vm._l(_vm.errors, function(error, index) {
                    return _c("li", { key: index }, [
                      _vm._v(" " + _vm._s(error) + " ")
                    ])
                  }),
                  0
                )
              ])
            : _vm._e()
        ]
      ),
      _c(
        "b-col",
        { staticClass: "source-nav", attrs: { cols: "12" } },
        [
          _c(
            "b-row",
            [
              _c("b-col", [
                _c("h1", { staticClass: "source-name" }, [
                  _vm._v(_vm._s(_vm.source.name))
                ])
              ]),
              _c(
                "b-col",
                { staticClass: "text-right" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "danger" },
                      on: {
                        click: function($event) {
                          return _vm.$bvModal.show("delete-modal")
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("buttons.delete")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.updateSource()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("buttons.save")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-col",
        { staticClass: "h-100 source-main", attrs: { cols: "12" } },
        [
          _vm.errors.length > 0
            ? _c("div", { staticClass: "mt-2 mb-2 alert alert-danger" }, [
                _c(
                  "ul",
                  _vm._l(_vm.errors, function(error, index) {
                    return _c("li", { key: index }, [
                      _vm._v(" " + _vm._s(error) + " ")
                    ])
                  }),
                  0
                )
              ])
            : _vm._e(),
          _c(
            "b-row",
            { staticClass: "mt-3" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [_c("inspector", { attrs: { uri: _vm.editSource.uri } })],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "b-card",
                    {
                      staticClass: "mb-2",
                      attrs: { header: _vm.$t("sources.editDetail") }
                    },
                    [
                      _c(
                        "b-card-text",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t("sources.name"),
                                description: _vm.$t("sources.nameDesc")
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: { required: "" },
                                model: {
                                  value: _vm.editSource.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editSource, "name", $$v)
                                  },
                                  expression: "editSource.name"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t("sources.team"),
                                description: _vm.$t("sources.teamDesc")
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: { required: "", disabled: "" },
                                model: {
                                  value: _vm.activeTeam.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.activeTeam, "name", $$v)
                                  },
                                  expression: "activeTeam.name"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t("sources.uri"),
                                description: _vm.$t("sources.uriDesc")
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: { required: "", disabled: "" },
                                model: {
                                  value: _vm.editSource.uri,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editSource, "uri", $$v)
                                  },
                                  expression: "editSource.uri"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t("sources.apiKey"),
                                description: _vm.$t("sources.apiDesc")
                              }
                            },
                            [
                              _c("b-form-input", {
                                model: {
                                  value: _vm.editSource.api_key,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editSource, "api_key", $$v)
                                  },
                                  expression: "editSource.api_key"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [_c("source-status", { attrs: { source: _vm.source } })],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }