var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { staticClass: "mb-2", attrs: { header: _vm.$t("sources.current") } },
    [
      _c(
        "b-card-text",
        [
          _c(
            "b-list-group",
            [
              _c(
                "b-list-group-item",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-center"
                },
                [
                  _vm._v(" " + _vm._s(_vm.$t("sources.status")) + " "),
                  _c(
                    "b-badge",
                    { attrs: { variant: _vm.statusType, pill: "" } },
                    [_vm._v(_vm._s(_vm.stats.status))]
                  )
                ],
                1
              ),
              _c(
                "b-list-group-item",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-center"
                },
                [
                  _vm._v(" " + _vm._s(_vm.$t("sources.latency")) + " "),
                  _c("b-badge", { attrs: { variant: "success" } }, [
                    _vm._v(_vm._s(_vm.stats.latency))
                  ])
                ],
                1
              ),
              _c(
                "b-list-group-item",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-center"
                },
                [
                  _vm._v(" " + _vm._s(_vm.$t("sources.APIVer")) + " "),
                  _c("b-badge", { attrs: { variant: "light", pill: "" } }, [
                    _vm._v(_vm._s(_vm.stats.version))
                  ])
                ],
                1
              ),
              _c(
                "b-list-group-item",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-center"
                },
                [
                  _vm._v(" " + _vm._s(_vm.$t("sources.campaign")) + " "),
                  _c("b-badge", { attrs: { variant: "light", pill: "" } }, [
                    _vm._v(_vm._s(_vm.stats.campaigns))
                  ])
                ],
                1
              ),
              _c(
                "b-list-group-item",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-center"
                },
                [
                  _vm._v(" " + _vm._s(_vm.$t("sources.author")) + " "),
                  _c("b-badge", { attrs: { variant: "light", pill: "" } }, [
                    _vm._v(_vm._s(_vm.stats.author))
                  ])
                ],
                1
              ),
              _c(
                "b-list-group-item",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-center"
                },
                [
                  _vm._v(" " + _vm._s(_vm.$t("sources.campany")) + " "),
                  _c("b-badge", { attrs: { variant: "light", pill: "" } }, [
                    _vm._v(_vm._s(_vm.stats.company))
                  ])
                ],
                1
              ),
              _c(
                "b-list-group-item",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-center"
                },
                [
                  _vm._v(" " + _vm._s(_vm.$t("sources.email")) + " "),
                  _c("b-badge", { attrs: { variant: "light", pill: "" } }, [
                    _vm._v(_vm._s(_vm.stats.email))
                  ])
                ],
                1
              ),
              _c(
                "b-list-group-item",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-center"
                },
                [
                  _vm._v(" " + _vm._s(_vm.$t("sources.phone")) + " "),
                  _c("b-badge", { attrs: { variant: "light", pill: "" } }, [
                    _vm._v(_vm._s(_vm.stats.phone))
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("p", { staticClass: "text-right" }, [
            _c("small", [_vm._v(_vm._s(_vm.$t("sources.update")))])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }