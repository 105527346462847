var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-container", [
    _c(
      "div",
      { staticClass: "newspage" },
      [
        _c(
          "b-modal",
          {
            attrs: {
              id: "modal-xl",
              size: "lg",
              title: _vm.$t("actions.deleteNews") + _vm.selectedNews.title,
              "hide-footer": ""
            }
          },
          [
            _c("div", { attrs: { slot: "header" }, slot: "header" }, [
              _c("h2", { staticClass: "text-center" }, [
                _vm._v(
                  _vm._s(_vm.$t()) + " " + _vm._s(_vm.selectedNews.title) + " "
                )
              ])
            ]),
            _vm._v(" " + _vm._s(_vm.$t("message.newsDelete")) + " "),
            _c(
              "div",
              [
                _c(
                  "b-button",
                  {
                    staticClass: "remove-button",
                    attrs: { type: "button", variant: "danger" },
                    on: {
                      click: function($event) {
                        _vm.deleteNews(_vm.selectedNews)
                        _vm.$bvModal.hide("modal-xl")
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("answers.yes")))]
                ),
                _c(
                  "b-button",
                  {
                    attrs: { type: "button", variant: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.$bvModal.hide("modal-xl")
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("answers.no")))]
                )
              ],
              1
            )
          ]
        ),
        _c(
          "b-card",
          { staticClass: "mb-3", attrs: { cols: "12" } },
          [
            _c("h4", { staticClass: "card-title" }, [
              _vm._v(_vm._s(_vm.$t("admin.createNews")))
            ]),
            _vm._v(" " + _vm._s(_vm.$t("admin.title")) + " "),
            _c("b-form-input", {
              staticClass: "form-control",
              attrs: { placeholder: "Title" },
              model: {
                value: _vm.newNews.title,
                callback: function($$v) {
                  _vm.$set(_vm.newNews, "title", $$v)
                },
                expression: "newNews.title"
              }
            }),
            _vm._v(" "),
            _vm._v(" " + _vm._s(_vm.$t("admin.body")) + " "),
            _c("b-form-textarea", {
              staticClass: "form-control",
              attrs: { placeholder: "Body", rows: "3" },
              model: {
                value: _vm.newNews.content,
                callback: function($$v) {
                  _vm.$set(_vm.newNews, "content", $$v)
                },
                expression: "newNews.content"
              }
            }),
            _vm._v(" "),
            _c(
              "b-button",
              {
                staticClass: "btn btn-primary mt-3",
                attrs: { variant: "primary", type: "submit" },
                on: {
                  click: function($event) {
                    return _vm.addNews()
                  }
                }
              },
              [_vm._v("Submit")]
            )
          ],
          1
        ),
        _c("b-card", { attrs: { cols: "12" } }, [
          _c(
            "div",
            { staticClass: "card-block" },
            [
              _c("h4", { staticClass: "card-title" }, [
                _vm._v(_vm._s(_vm.$t("Current news list")))
              ]),
              _c("b-card-text", [
                _vm.loaded
                  ? _c(
                      "div",
                      _vm._l(_vm.news, function(newsItem) {
                        return _c(
                          "div",
                          {
                            key: newsItem.id,
                            staticClass: "border-top mt-3 news"
                          },
                          [
                            _vm.editing === null ? _c("p") : _vm._e(),
                            _c("h5", [_vm._v(_vm._s(newsItem.title))]),
                            _c("p"),
                            _c(
                              "p",
                              [
                                _vm.editing === newsItem.id
                                  ? _c("b-form-input", {
                                      staticClass: "form-control border-bottom",
                                      staticStyle: { "margin-bottom": "0.5em" },
                                      model: {
                                        value: newsItem.title,
                                        callback: function($$v) {
                                          _vm.$set(newsItem, "title", $$v)
                                        },
                                        expression: "newsItem.title"
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("news.postedBy")) +
                                    " " +
                                    _vm._s(newsItem.author) +
                                    " " +
                                    _vm._s(_vm.$t("news.on")) +
                                    " " +
                                    _vm._s(_vm.$t(newsItem.postedDate)) +
                                    " "
                                )
                              ],
                              1
                            ),
                            _vm.editing !== newsItem.id
                              ? _c("p", [
                                  _vm._v(" " + _vm._s(newsItem.content) + " ")
                                ])
                              : _vm._e(),
                            _c(
                              "p",
                              [
                                _vm.editing === newsItem.id
                                  ? _c("b-form-textarea", {
                                      staticClass: "form-control",
                                      staticStyle: { "margin-bottom": "0.5em" },
                                      attrs: { rows: "3" },
                                      model: {
                                        value: newsItem.content,
                                        callback: function($$v) {
                                          _vm.$set(newsItem, "content", $$v)
                                        },
                                        expression: "newsItem.content"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm.editing !== newsItem.id
                              ? _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      type: "button",
                                      variant: "primary"
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.editing = newsItem.id
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("buttons.edit")))]
                                )
                              : _vm.editing === newsItem.id
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "remove-button",
                                        attrs: {
                                          type: "button",
                                          variant: "primary"
                                        },
                                        on: {
                                          click: function($event) {
                                            ;(_vm.editing = null),
                                              _vm.updateNews(newsItem)
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.$t("Submit")))]
                                    ),
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "remove-button",
                                        attrs: {
                                          type: "button",
                                          variant: "warning"
                                        },
                                        on: {
                                          click: function($event) {
                                            ;(_vm.editing = null),
                                              _vm.fetchNews()
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.$t("Cancel")))]
                                    ),
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "b-modal",
                                            rawName: "v-b-modal.modal-xl",
                                            modifiers: { "modal-xl": true }
                                          }
                                        ],
                                        staticClass: "remove-button",
                                        attrs: {
                                          type: "button",
                                          variant: "danger"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.selectedNews = newsItem
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.$t("actions.delete")))]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      0
                    )
                  : _c("div", [
                      _vm._v(
                        " " + _vm._s(_vm.$t("dashboard.common.loading")) + " "
                      )
                    ])
              ])
            ],
            1
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }