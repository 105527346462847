var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { attrs: { title: "Payment Status" } },
        [
          _c("b-card-text", [
            _c(
              "div",
              [
                _c(
                  "h5",
                  [
                    _vm._v("Overall Status: "),
                    _vm.status
                      ? _c("b-badge", { attrs: { variant: "success" } }, [
                          _vm._v("Good")
                        ])
                      : _c(
                          "span",
                          [
                            _c("b-badge", { attrs: { variant: "danger" } }, [
                              _vm._v("Bad")
                            ]),
                            _c(
                              "label",
                              {
                                staticClass: "ml-1",
                                attrs: {
                                  id: "badHelp",
                                  "data-placement": "top"
                                }
                              },
                              [
                                _c("v-icon", {
                                  attrs: { name: "question-circle" }
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-popover",
                              {
                                attrs: {
                                  target: "badHelp",
                                  triggers: "hover focus"
                                }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("popover.badHelp")) + " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "6" } },
                      [
                        _c("h5", [_vm._v("Team")]),
                        _c(
                          "b-table-simple",
                          { attrs: { small: "", borderless: "" } },
                          [
                            _c(
                              "b-tr",
                              [
                                _c("b-td", [_vm._v(" Team Contact ")]),
                                _vm.team.email
                                  ? _c(
                                      "b-td",
                                      [
                                        _c(
                                          "b-badge",
                                          { attrs: { variant: "success" } },
                                          [_vm._v(_vm._s(_vm.team.email))]
                                        )
                                      ],
                                      1
                                    )
                                  : _c(
                                      "b-td",
                                      [
                                        _c(
                                          "b-badge",
                                          {
                                            attrs: {
                                              to: {
                                                name: "team",
                                                params: {
                                                  teamId: _vm.team.teamId
                                                }
                                              },
                                              variant: "danger"
                                            }
                                          },
                                          [_vm._v("Not Set")]
                                        )
                                      ],
                                      1
                                    )
                              ],
                              1
                            ),
                            _c(
                              "b-tr",
                              [
                                _c("b-td", [_vm._v(" Stripe Account ")]),
                                _vm.team.stripeConnected
                                  ? _c(
                                      "b-td",
                                      [
                                        _c(
                                          "b-badge",
                                          { attrs: { variant: "success" } },
                                          [_vm._v("Connected")]
                                        )
                                      ],
                                      1
                                    )
                                  : _c(
                                      "b-td",
                                      [
                                        _c(
                                          "b-badge",
                                          {
                                            attrs: {
                                              to: {
                                                name: "team",
                                                params: {
                                                  teamId: _vm.team.teamId
                                                }
                                              },
                                              variant: "danger"
                                            }
                                          },
                                          [_vm._v("Not Connected ")]
                                        )
                                      ],
                                      1
                                    )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { cols: "6" } },
                      [
                        _c("h5", [_vm._v("Public Displays")]),
                        _vm.filteredDisplays.length > 0
                          ? _c(
                              "b-table-simple",
                              { attrs: { small: "", borderless: "" } },
                              _vm._l(_vm.filteredDisplays, function(display) {
                                return _c(
                                  "b-tr",
                                  { key: display.id },
                                  [
                                    _c("b-td", [
                                      _vm._v(" " + _vm._s(display.name) + " ")
                                    ]),
                                    _c(
                                      "b-td",
                                      [
                                        display.approval &&
                                        display.blockTime > 0 &&
                                        display.maxTimePurchasable > 0 &&
                                        display.baselinePrice !== null
                                          ? _c(
                                              "b-badge",
                                              { attrs: { variant: "success" } },
                                              [_vm._v("Ready")]
                                            )
                                          : _vm._e(),
                                        !display.approval
                                          ? _c(
                                              "b-badge",
                                              {
                                                staticClass: "mr-1",
                                                attrs: { variant: "warning" }
                                              },
                                              [_vm._v("Pending Approval")]
                                            )
                                          : _vm._e(),
                                        display.blockTime <= 0
                                          ? _c(
                                              "b-badge",
                                              {
                                                staticClass: "mr-1",
                                                attrs: {
                                                  to: {
                                                    name: "display-pricing",
                                                    params: {
                                                      displayId:
                                                        display.displayId
                                                    }
                                                  },
                                                  variant: "danger"
                                                }
                                              },
                                              [_vm._v("Block Time")]
                                            )
                                          : _vm._e(),
                                        display.maxTimePurchasable <= 0
                                          ? _c(
                                              "b-badge",
                                              {
                                                staticClass: "mr-1",
                                                attrs: {
                                                  to: {
                                                    name: "display-pricing",
                                                    params: {
                                                      displayId:
                                                        display.displayId
                                                    }
                                                  },
                                                  variant: "danger"
                                                }
                                              },
                                              [_vm._v("Max Time")]
                                            )
                                          : _vm._e(),
                                        display.baselinePrice === null
                                          ? _c(
                                              "b-badge",
                                              {
                                                staticClass: "mr-1",
                                                attrs: {
                                                  to: {
                                                    name: "display-pricing",
                                                    params: {
                                                      displayId:
                                                        display.displayId
                                                    }
                                                  },
                                                  variant: "danger"
                                                }
                                              },
                                              [_vm._v("Baseline Price")]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              }),
                              1
                            )
                          : _c("p", [_vm._v("No public displays")])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }