var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        ref: "campaign",
        staticClass: "campaign-preview",
        attrs: { id: "campaign-preview" }
      },
      [
        _c(
          "div",
          { staticClass: "content w-100 p-0 m-0" },
          [
            _vm._l(_vm.videos, function(video) {
              return _c("video-item", {
                key: video.id,
                class: video.position.replace(",", " "),
                attrs: {
                  video: video,
                  time: _vm.time,
                  width: _vm.screenWidth,
                  height: _vm.screenHeight,
                  playing: _vm.playing
                }
              })
            }),
            _vm._l(_vm.images, function(image) {
              return _c("image-item", {
                key: image.id,
                class: image.position.replace(",", " "),
                attrs: {
                  image: image,
                  time: _vm.time,
                  width: _vm.screenWidth,
                  height: _vm.screenHeight,
                  playing: _vm.playing
                }
              })
            }),
            _vm._l(_vm.audio, function(audioItem) {
              return _c("audio-item", {
                key: audioItem.id,
                class: audioItem.position.replace(",", " "),
                staticStyle: { "z-index": "1" },
                attrs: { audio: audioItem, time: _vm.time }
              })
            }),
            _vm._l(_vm.text, function(textItem) {
              return _c("text-item", {
                key: textItem.id,
                staticClass: "text",
                class: textItem.position.replace(",", " "),
                staticStyle: { "z-index": "2147483647" },
                attrs: { text: textItem, time: _vm.time }
              })
            }),
            _vm.selectedFrame
              ? _c(
                  "div",
                  {
                    staticClass:
                      "durationBar bg-white border border-muted rounded p-0 m-0"
                  },
                  [
                    _vm._v(_vm._s(_vm.$t("campaigns.frameDuration")) + ": "),
                    _c("b-form-input", {
                      staticClass: "d-inline",
                      attrs: {
                        min: "0",
                        max: "9999",
                        number: "",
                        required: "",
                        trim: "",
                        id: "duration",
                        type: "number"
                      },
                      model: {
                        value: _vm.selectedFrame.duration,
                        callback: function($$v) {
                          _vm.$set(_vm.selectedFrame, "duration", _vm._n($$v))
                        },
                        expression: "selectedFrame.duration"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          2
        )
      ]
    ),
    !_vm.noControls
      ? _c(
          "div",
          { staticClass: "campaign-controls" },
          [
            _c(
              "div",
              {
                staticClass: "play-pause",
                on: {
                  click: function($event) {
                    return _vm.playPause()
                  }
                }
              },
              [
                _c("v-icon", {
                  staticClass: "play-icon",
                  attrs: { name: _vm.playingIcon }
                })
              ],
              1
            ),
            _c("b-input", {
              staticClass: "seek-bar",
              attrs: { type: "range", min: "0", max: _vm.end },
              model: {
                value: _vm.time,
                callback: function($$v) {
                  _vm.time = _vm._n($$v)
                },
                expression: "time"
              }
            }),
            _vm._v(
              " " +
                _vm._s(_vm._f("time")(_vm.time)) +
                "/" +
                _vm._s(_vm._f("time")(_vm.end)) +
                " "
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }