var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { width: "100%", height: "60px" } }, [
    _c("div", { ref: "slider" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }