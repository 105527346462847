var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { id: "verge" } },
    [
      _c(
        "b-row",
        { staticClass: "d-none d-md-block" },
        [
          _c("b-col", { staticClass: "m-2 d-flex justify-content-center" }, [
            _c(
              "div",
              { staticClass: "m-1" },
              [
                _c(
                  "b-button-group",
                  { staticClass: "m-1" },
                  _vm._l(Object.keys(_vm.aspects), function(aspect) {
                    return _c(
                      "b-button",
                      {
                        key: aspect,
                        staticClass: "font-weight-bold",
                        attrs: {
                          size: "sm",
                          variant:
                            _vm.selectedAspect === aspect
                              ? "dark"
                              : "outline-dark"
                        },
                        on: {
                          click: function($event) {
                            _vm.selectedAspect = aspect
                          }
                        }
                      },
                      [_vm._v(_vm._s(aspect))]
                    )
                  }),
                  1
                ),
                _c(
                  "b-button-group",
                  { staticClass: "m-1" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "font-weight-bold",
                        attrs: {
                          size: "sm",
                          variant: _vm.isRotated
                            ? "secondary"
                            : "outline-secondary"
                        },
                        on: {
                          click: function($event) {
                            _vm.isRotated = !_vm.isRotated
                          }
                        }
                      },
                      [
                        _vm._v("Rotate "),
                        _c("b-icon", {
                          attrs: {
                            icon: _vm.isRotated ? "check-square" : "square",
                            variant: _vm.isRotated ? "light" : "dark"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              true
                ? _c("prop-player", {
                    staticClass: "m-0 p-0 mx-auto mb-4",
                    style: _vm.computedAspectStyle,
                    attrs: { noControls: false, media: _vm.computedMedia }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c(
                "b-card",
                { attrs: { header: _vm.$t("common.tags") } },
                [
                  _c(
                    "b-card-text",
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            description: _vm.$t("campaigns.campaignTagDesc")
                          }
                        },
                        [
                          _c("multiselect", {
                            attrs: {
                              options: _vm.alltags,
                              value: _vm.selected,
                              multiple: true,
                              taggable: true,
                              placeholder: _vm.$t("message.addTag")
                            },
                            on: {
                              tag: _vm.addTag,
                              select: _vm.chooseTag,
                              remove: _vm.removeTag
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.saveTags()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("buttons.save")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "6" } },
            [
              _c(
                "b-card",
                { attrs: { header: _vm.$t("campaigns.scheduledOn") } },
                [
                  _c("b-card-text", [
                    _c(
                      "dl",
                      _vm._l(_vm.campaign.displays, function(display) {
                        return _c(
                          "dd",
                          { key: display.id },
                          [
                            _c(
                              "b-link",
                              {
                                attrs: {
                                  to: {
                                    name: "display-view",
                                    params: { displayId: display.id }
                                  }
                                }
                              },
                              [_vm._v(_vm._s(display.name))]
                            )
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }