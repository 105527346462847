var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user.authenticated
    ? _c(
        "b-col",
        {
          staticClass: "sidebar d-none d-md-block h-100",
          class: { visible: _vm.active },
          attrs: { cols: "10", md: "4", lg: "2" }
        },
        [
          _c(
            "b-row",
            { staticClass: "p-0 m-0" },
            [
              _c("b-col", { staticClass: "pt-2 m-0" }, [
                _c("h6", { staticClass: "m-0 text-muted text-center" }, [
                  _vm._v(_vm._s(_vm.$t("dashboard.headers.activeTeam")))
                ])
              ])
            ],
            1
          ),
          _c("team-selector", {
            attrs: { resetTeamsTrigger: _vm.resetTeamsTrigger },
            on: {
              updateTeam: function($event) {
                return _vm.$emit("updateTeam", $event)
              }
            }
          }),
          _c(
            "ul",
            { staticClass: "accordion-menu pl-2" },
            _vm._l(_vm.filterLinks, function(link, index) {
              return _c(
                "li",
                { key: index, class: { "has-children": link.children } },
                [
                  link.children
                    ? _c("input", {
                        attrs: {
                          type: "checkbox",
                          name: "group-" + index,
                          id: "group-" + index
                        }
                      })
                    : _vm._e(),
                  link.children
                    ? _c(
                        "label",
                        {
                          staticClass: "sidebar-link ",
                          attrs: { for: "group-" + index }
                        },
                        [
                          _c("v-icon", {
                            staticClass: "icon mr-2",
                            attrs: { name: link.icon, scale: "1" }
                          }),
                          _c("span", [_vm._v(_vm._s(_vm.$t(link.key)))])
                        ],
                        1
                      )
                    : _vm._e(),
                  link.children
                    ? _c(
                        "ul",
                        {
                          on: {
                            click: function($event) {
                              return _vm.$emit("hide")
                            }
                          }
                        },
                        _vm._l(link.children, function(child, childIndex) {
                          return _c(
                            "router-link",
                            {
                              key: childIndex,
                              staticClass: "sidebar-link child-link",
                              attrs: { tag: "li", to: child.uri }
                            },
                            [
                              _c("v-icon", {
                                staticClass: "icon mr-2",
                                attrs: { name: child.icon, scale: "1" }
                              }),
                              _c("span", [_vm._v(_vm._s(_vm.$t(child.key)))])
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _c(
                        "ul",
                        {
                          on: {
                            click: function($event) {
                              return _vm.$emit("hide")
                            }
                          }
                        },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "sidebar-link",
                              attrs: { to: link.uri, tag: "li" }
                            },
                            [
                              _c("v-icon", {
                                staticClass: "icon mr-2",
                                attrs: {
                                  name: link.icon,
                                  scale: "1",
                                  "shape-rendering": "geometricPrecision"
                                }
                              }),
                              _c("span", [_vm._v(_vm._s(_vm.$t(link.key)))])
                            ],
                            1
                          )
                        ],
                        1
                      )
                ]
              )
            }),
            0
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }