var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [_vm._v(_vm._s(_vm.$t("schedule.selectCampaign")))]),
      _vm.errors.length > 0
        ? _c("div", { staticClass: "alert alert-danger" }, [
            _c(
              "ul",
              _vm._l(_vm.errors, function(error, index) {
                return _c("li", { key: index }, [
                  _vm._v(" " + _vm._s(error) + " ")
                ])
              }),
              0
            )
          ])
        : _vm._e(),
      _c("multiselect", {
        attrs: {
          options: _vm.filteredCampaigns,
          searchable: true,
          placeholder: _vm.$t("schedule.selectCampaign"),
          label: "name"
        },
        on: { input: _vm.selectCampaign },
        model: {
          value: _vm.campaign,
          callback: function($$v) {
            _vm.campaign = $$v
          },
          expression: "campaign"
        }
      }),
      _c(
        "b-button",
        {
          staticClass: "float-right mt-2",
          attrs: { variant: "primary" },
          on: { click: _vm.next }
        },
        [_vm._v(_vm._s(_vm.$t("buttons.next")))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }