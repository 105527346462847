var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    [
      _c("h2", [_vm._v("Coming Soon")]),
      _c(
        "b-button",
        {
          on: {
            click: function($event) {
              return _vm.$router.push({ path: "/" })
            }
          }
        },
        [_vm._v("Back")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }