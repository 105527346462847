var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.displays.length > 0
    ? _c(
        "div",
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("h4", [
                      _vm._v(_vm._s(_vm.$t("dashboard.metrics.displayMetrics")))
                    ])
                  ]),
                  _c("b-col", [
                    _c(
                      "p",
                      {
                        staticClass: "text-right",
                        staticStyle: { "font-size": "0.75em" }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("schedule.lastUpdateText")) +
                            " " +
                            _vm._s(_vm.count) +
                            " " +
                            _vm._s(_vm.$t("schedule.updateUnit"))
                        )
                      ]
                    )
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                { attrs: { "align-h": "end" } },
                [
                  _c(
                    "b-button-group",
                    { staticClass: "pb-3", attrs: { size: "sm" } },
                    [
                      _c(
                        "b-button",
                        {
                          class: _vm.getActiveClass("hour"),
                          attrs: { variant: "outline-primary" },
                          on: {
                            click: function($event) {
                              _vm.range("hour")
                              _vm.activeButton = "hour"
                            }
                          }
                        },
                        [_vm._v("1H")]
                      ),
                      _c(
                        "b-button",
                        {
                          class: _vm.getActiveClass("day"),
                          attrs: { variant: "outline-primary" },
                          on: {
                            click: function($event) {
                              _vm.range("day")
                              _vm.activeButton = "day"
                            }
                          }
                        },
                        [_vm._v("1D")]
                      ),
                      _c(
                        "b-button",
                        {
                          class: _vm.getActiveClass("week"),
                          attrs: { variant: "outline-primary" },
                          on: {
                            click: function($event) {
                              _vm.range("week")
                              _vm.activeButton = "week"
                            }
                          }
                        },
                        [_vm._v("1W")]
                      ),
                      _c(
                        "b-button",
                        {
                          class: _vm.getActiveClass("reset"),
                          attrs: { variant: "outline-primary" },
                          on: {
                            click: function($event) {
                              _vm.range()
                              _vm.activeButton = "reset"
                            }
                          }
                        },
                        [_vm._v("Reset")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c("graph", {
                        ref: "memory",
                        attrs: {
                          width: "105%",
                          height: "200",
                          type: "line",
                          options: _vm.chartOptions,
                          series: _vm.series.orz_memory
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c("graph", {
                        ref: "disk",
                        attrs: {
                          width: "105%",
                          height: "200",
                          type: "line",
                          options: _vm.chartOptions,
                          series: _vm.series.orz_disk
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c("graph", {
                        ref: "load",
                        attrs: {
                          width: "105%",
                          height: "200",
                          type: "line",
                          options: _vm.chartOptions,
                          series: _vm.series.orz_load
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c("graph", {
                        ref: "signal",
                        attrs: {
                          width: "105%",
                          height: "200",
                          type: "line",
                          options: _vm.chartOptions,
                          series: _vm.series.orz_signal
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("h4", [
                          _vm._v(
                            _vm._s(_vm.$t("dashboard.campaigns.lastPlayed"))
                          )
                        ])
                      ]),
                      _c("b-col", [
                        _c(
                          "p",
                          {
                            staticClass: "text-right",
                            staticStyle: { "font-size": "0.75em" }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("dashboard.campaigns.updateTime"))
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { attrs: { "align-h": "end" } },
                    [
                      _c("b-col", { staticClass: "text-right" }, [
                        _vm._v(" " + _vm._s(_vm.campaignTable.paused) + " ")
                      ]),
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "primary", size: "sm" },
                          on: {
                            click: function($event) {
                              return _vm.pause()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("dashboard.campaigns.pause")))]
                      )
                    ],
                    1
                  ),
                  _c("br"),
                  _c(
                    "b-row",
                    [
                      _c("b-table", {
                        staticClass: "my-table",
                        attrs: {
                          small: "",
                          bordered: "",
                          responsive: "",
                          headVariant: "dark",
                          fields: _vm.campaignTable.fields,
                          items: _vm.campaignTable.items
                        }
                      })
                    ],
                    1
                  ),
                  _c("br"),
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("h4", [
                          _vm._v(
                            _vm._s(_vm.$t("dashboard.campaigns.clientUptime"))
                          )
                        ])
                      ]),
                      _c("b-table", {
                        staticClass: "my-table",
                        attrs: {
                          responsive: "",
                          bordered: "",
                          headVariant: "dark",
                          items: _vm.upTable.items,
                          fields: _vm.upTable.fields,
                          "sort-by": _vm.upTable.sort
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }