var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        { staticClass: "header mt-3 pb-3 w-100", attrs: { id: "top-area" } },
        [
          _c("b-col", [
            _c("h2", [_vm._v(_vm._s(_vm.$t("sources.sources")))]),
            _c("p", { staticClass: "d-none d-md-block" }, [
              _vm._v(_vm._s(_vm.$t("tutorial.sources")))
            ])
          ]),
          _c(
            "b-col",
            { staticClass: "d-md-none" },
            [
              _c("multiselect", {
                attrs: {
                  options: _vm.filtered,
                  "track-by": "sourceId",
                  label: "name",
                  value: _vm.compSelected,
                  searchable: false,
                  "allow-empty": false
                },
                on: { select: _vm.selectSource }
              })
            ],
            1
          ),
          _c("b-col", { staticClass: "d-md-none", attrs: { cols: "1" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "b-modal",
                    rawName: "v-b-modal.new-source",
                    modifiers: { "new-source": true }
                  }
                ]
              },
              [
                _c("v-icon", {
                  attrs: { name: "plus", scale: "1.5", label: "New Source" }
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm.loading
        ? _c("b-row", { attrs: { id: "content-area" } }, [
            _c("div", { staticClass: "spinner" })
          ])
        : _c(
            "b-row",
            { attrs: { id: "content-area" } },
            [
              _c(
                "b-col",
                {
                  ref: "sidebar",
                  staticClass: "d-none d-md-block h-100 overflow list",
                  attrs: { md: "4", lg: "2" }
                },
                [
                  _c("b-form-input", {
                    staticClass: "search",
                    attrs: {
                      id: "search",
                      placeholder: _vm.$t("actions.search")
                    },
                    model: {
                      value: _vm.search,
                      callback: function($$v) {
                        _vm.search = $$v
                      },
                      expression: "search"
                    }
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.new-source",
                          modifiers: { "new-source": true }
                        }
                      ],
                      staticClass: "new-item item"
                    },
                    [
                      _c("v-icon", {
                        attrs: {
                          name: "plus",
                          scale: "1.5",
                          label: "New Source"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._l(_vm.filtered, function(source) {
                    return _c(
                      "div",
                      {
                        key: source.sourceId,
                        staticClass: "pt-3 pb-3 item",
                        class: {
                          selected:
                            _vm.compSelected !== {} &&
                            _vm.compSelected.sourceId === source.sourceId
                        },
                        attrs: { id: "s_" + source.sourceId },
                        on: {
                          click: function($event) {
                            return _vm.selectSource(source)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(source.name) + " ")]
                    )
                  })
                ],
                2
              ),
              _c(
                "b-col",
                {
                  staticClass: "h-100 overflow",
                  attrs: { cols: "12", md: "8", lg: "10" }
                },
                [
                  _c(
                    "transition",
                    { attrs: { name: "fade" } },
                    [
                      !_vm.loading &&
                      _vm.$route.params.sourceId &&
                      _vm.compSelected !== {}
                        ? _c("router-view", {
                            attrs: {
                              activeTeam: _vm.activeTeam,
                              source: _vm.compSelected
                            },
                            on: { update: _vm.fetchSources }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            id: "new-source",
            title: "",
            "no-fade": "",
            "no-close-on-backdrop": "",
            "hide-footer": ""
          }
        },
        [
          _c("new-source", {
            attrs: { activeTeam: _vm.activeTeam },
            on: { refresh: _vm.fetchSources }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }