var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        { attrs: { id: "toparea", "no-gutters": "" } },
        [
          _c("div", {}, [
            _c(
              "h2",
              { staticClass: "pt-1 d-none d-sm-block" },
              [
                _vm._v(_vm._s(_vm.$t("schedule.ipamTitle")) + " "),
                _vm.loaded
                  ? _c("b-button", { attrs: { variant: "outline-success" } }, [
                      _vm._v(
                        _vm._s(_vm.$t("schedule.lastUpdateText")) +
                          " " +
                          _vm._s(_vm.lastUpdate)
                      )
                    ])
                  : !_vm.loaded && !_vm.tried
                  ? _c("b-button", { attrs: { variant: "outline-warning" } }, [
                      _vm._v(_vm._s(_vm.$t("schedule.loading")))
                    ])
                  : _vm._e()
              ],
              1
            ),
            _c("p", { staticClass: "d-none d-md-block" }, [
              _vm._v(_vm._s(_vm.$t("tutorial.schedulingExample")))
            ])
          ]),
          _c(
            "b-row",
            { staticClass: "w-100 m-0 p-0" },
            [
              _c(
                "b-col",
                {
                  staticClass: "p-1",
                  attrs: { xl: "4", lg: "4", md: "6", sm: "12", xs: "12" }
                },
                [
                  _c(
                    "b-button-group",
                    { staticStyle: { width: "100% !important" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          placeholder: _vm.$t("schedule.searchDisplay")
                        },
                        model: {
                          value: _vm.displayFilter,
                          callback: function($$v) {
                            _vm.displayFilter = $$v
                          },
                          expression: "displayFilter"
                        }
                      }),
                      _c(
                        "b-dropdown",
                        {
                          attrs: {
                            variant: "outline-success",
                            id: "displaySort",
                            text: _vm.displaySortMethod
                              ? _vm.$t("schedule.newest")
                              : _vm.$t("schedule.oldest")
                          }
                        },
                        [
                          _c(
                            "b-dropdown-item",
                            {
                              on: {
                                click: function($event) {
                                  _vm.displaySortMethod = true
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("schedule.newest")))]
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              on: {
                                click: function($event) {
                                  _vm.displaySortMethod = false
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("schedule.oldest")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "p-1",
                  attrs: { xl: "4", lg: "4", md: "6", sm: "12", xs: "12" }
                },
                [
                  _c(
                    "b-button-group",
                    { staticStyle: { width: "100% !important" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          placeholder: _vm.$t("schedule.searchCampaigns")
                        },
                        model: {
                          value: _vm.campaignFilter,
                          callback: function($$v) {
                            _vm.campaignFilter = $$v
                          },
                          expression: "campaignFilter"
                        }
                      }),
                      _c(
                        "b-dropdown",
                        {
                          attrs: {
                            variant: "outline-info",
                            id: "campaignSort",
                            text: _vm.campaignSortMethod
                              ? _vm.$t("schedule.newest")
                              : _vm.$t("schedule.oldest")
                          }
                        },
                        [
                          _c(
                            "b-dropdown-item",
                            {
                              on: {
                                click: function($event) {
                                  _vm.campaignSortMethod = true
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("schedule.newest")))]
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              on: {
                                click: function($event) {
                                  _vm.campaignSortMethod = false
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("schedule.oldest")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "p-1",
                  attrs: { xl: "2", lg: "2", md: "6", sm: "12", xs: "12" }
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-success",
                      staticStyle: { width: "100%" },
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.toggleActive()
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("schedule.active")) +
                          " " +
                          _vm._s(_vm.activeSwitch === true ? "☑" : "☐")
                      )
                    ]
                  )
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass: "p-1",
                  attrs: { xl: "2", lg: "2", md: "6", sm: "12", xs: "12" }
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-warning",
                      staticStyle: { width: "100%" },
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.toggleSources()
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("schedule.source")) +
                          " " +
                          _vm._s(_vm.sourceSwitch === true ? "☑" : "☐")
                      )
                    ]
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.displays.length < 1 ||
      (_vm.sources.length < 1 && _vm.campaigns.length < 1)
        ? _c(
            "b-row",
            [
              _c("b-col", [
                _vm.displays.length < 1
                  ? _c(
                      "div",
                      { staticClass: "text-center" },
                      [
                        _c("p", [
                          _vm._v(_vm._s(_vm.$t("schedule.noDisplayFound")))
                        ]),
                        _c(
                          "b-button",
                          {
                            attrs: {
                              variant: "primary",
                              to: { name: "display" }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("index.displays")))]
                        )
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "text-center" },
                      [
                        _c("p", [
                          _vm._v(_vm._s(_vm.$t("schedule.noCampaignFound")))
                        ]),
                        _c(
                          "b-button",
                          {
                            staticClass: "mr-2",
                            attrs: {
                              variant: "primary",
                              to: { name: "campaign" }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("index.campaigns")))]
                        ),
                        _c(
                          "b-button",
                          {
                            attrs: {
                              variant: "primary",
                              to: { name: "source" }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("index.sources")))]
                        )
                      ],
                      1
                    )
              ])
            ],
            1
          )
        : _c(
            "b-row",
            { attrs: { "no-gutters": "" } },
            [
              _vm.loaded
                ? _c(
                    "b-table-simple",
                    {
                      staticClass: "m-0",
                      attrs: {
                        "sticky-header": _vm.tableHeight,
                        fixed: "",
                        hover: "",
                        responsive: ""
                      }
                    },
                    [
                      _c(
                        "b-thead",
                        [
                          _c(
                            "b-tr",
                            [
                              _c(
                                "b-th",
                                {
                                  staticStyle: { "vertical-align": "top" },
                                  attrs: { colspan: "2" }
                                },
                                [
                                  _c(
                                    "b-button-group",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            variant: "outline-primary",
                                            disabled: _vm.canGoPrevious
                                          },
                                          on: { click: _vm.previousPage }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("schedule.prePage"))
                                          )
                                        ]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            variant: "outline-primary",
                                            disabled: _vm.canGoNext
                                          },
                                          on: { click: _vm.nextPage }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("schedule.nexPage"))
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._l(_vm.headerRange, function(dc) {
                                return [
                                  _c("ipam-header", {
                                    key: dc,
                                    staticClass: "align-top",
                                    attrs: { display: _vm.filteredDisplays[dc] }
                                  })
                                ]
                              })
                            ],
                            2
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-tbody",
                        [
                          _vm._l(_vm.campaignRange, function(row) {
                            return _c(
                              "b-tr",
                              { key: row, staticClass: "ipam-row" },
                              [
                                _vm.filteredRows[row].campaignId
                                  ? _c("b-th", { attrs: { colspan: "2" } }, [
                                      _c(
                                        "div",
                                        { staticClass: "pt-1 pb-1" },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "b-link",
                                                {
                                                  staticClass: "text-dark",
                                                  attrs: {
                                                    id: "popover-target-" + row,
                                                    to: {
                                                      name: "campaign",
                                                      params: {
                                                        campaignId:
                                                          _vm.filteredRows[row]
                                                            .campaignId
                                                      }
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.filteredRows[row].name
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c("small", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getCampaignLength(
                                                      _vm.filteredRows[row]
                                                        .media.data
                                                    )
                                                  ) + " seconds"
                                                )
                                              ])
                                            ],
                                            1
                                          ),
                                          true
                                            ? _c(
                                                "b-popover",
                                                {
                                                  attrs: {
                                                    "custom-class":
                                                      "campaign-preview",
                                                    container: null,
                                                    target:
                                                      "popover-target-" + row,
                                                    triggers: "hover"
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "title",
                                                        fn: function() {
                                                          return [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm
                                                                  .filteredRows[
                                                                  row
                                                                ].name
                                                              )
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                },
                                                [
                                                  _c("prop-player", {
                                                    style: {
                                                      width: "20em",
                                                      height: "15em"
                                                    },
                                                    attrs: {
                                                      media:
                                                        _vm.filteredRows[row]
                                                          .media.data
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ])
                                  : _c("b-th", { attrs: { colspan: "2" } }, [
                                      _c("div", { staticClass: "pt-1 pb-1 " }, [
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "b-badge",
                                              { attrs: { variant: "dark" } },
                                              [_vm._v("E2V")]
                                            ),
                                            _c(
                                              "b-link",
                                              {
                                                staticClass: "text-dark",
                                                attrs: {
                                                  to: {
                                                    name: "sources",
                                                    params: {
                                                      sourceId:
                                                        _vm.filteredRows[row]
                                                          .sourceId
                                                    }
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.filteredRows[row].name
                                                    )
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    ]),
                                _vm._l(Array(_vm.displayLimit).keys(), function(
                                  col
                                ) {
                                  return [
                                    _c("ipam-cell", {
                                      key: col,
                                      attrs: { content: _vm.cells[row][col] },
                                      on: { triggerModal: _vm.triggerModal }
                                    })
                                  ]
                                })
                              ],
                              2
                            )
                          }),
                          _vm.campaignsVisible < _vm.filteredRows.length
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "mt-1 mb-4 ml-2",
                                  attrs: { variant: "primary" },
                                  on: { click: _vm.loadCampaigns }
                                },
                                [_vm._v("Load More")]
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "addCampaign",
            size: "lg",
            "no-fade": "",
            "hide-footer": "",
            "no-stacking": ""
          }
        },
        [
          _c("add-campaign", {
            attrs: {
              selectedCampaign: _vm.selectedCampaign,
              selectedDisplay: _vm.selectedDisplay,
              startingIndex: 1,
              selectDisabled: true
            },
            on: { done: _vm.closeAndRefresh }
          })
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "manageCampaign",
            size: "lg",
            "no-fade": "",
            "hide-footer": "",
            "no-stacking": ""
          }
        },
        [
          _c("manage-campaigns", {
            attrs: {
              display: _vm.selectedDisplay,
              campaign: _vm.selectedCampaign
            },
            on: {
              add: function($event) {
                return _vm.$bvModal.show("addCampaign")
              },
              deleted: _vm.fetchDisplays
            }
          })
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "addSource",
            size: "lg",
            "no-fade": "",
            "hide-footer": "",
            "no-stacking": ""
          }
        },
        [
          _c("add-source", {
            attrs: {
              selectedSource: _vm.selectedCampaign,
              selectedDisplay: _vm.selectedDisplay,
              startingIndex: 1,
              selectDisabled: true
            },
            on: { done: _vm.closeAndRefresh }
          })
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "manageSource",
            size: "lg",
            "no-fade": "",
            "hide-footer": "",
            "no-stacking": ""
          }
        },
        [
          _c("manage-sources", {
            attrs: {
              display: _vm.selectedDisplay,
              source: _vm.selectedCampaign,
              sources: _vm.sources
            },
            on: {
              add: function($event) {
                return _vm.$bvModal.show("addSource")
              },
              deleted: _vm.fetchDisplays
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }