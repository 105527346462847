var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pt-4 pb-4" }, [
    !_vm.forgot
      ? _c(
          "div",
          [
            _c("h2", { staticClass: "p-2" }, [
              _vm._v(_vm._s(_vm.$t("account.loginTitle")))
            ]),
            _vm.errors.length > 0
              ? _c(
                  "div",
                  _vm._l(_vm.errors, function(error, index) {
                    return _c(
                      "b-alert",
                      { key: index, attrs: { show: "", variant: "danger" } },
                      [_vm._v(" " + _vm._s(error) + " ")]
                    )
                  }),
                  1
                )
              : _vm._e(),
            _c(
              "b-form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.submit()
                  }
                }
              },
              [
                _c(
                  "b-form-group",
                  {
                    staticClass: "m-2 mt-4",
                    attrs: { description: _vm.$t("register.lgName") }
                  },
                  [
                    _c("b-form-input", {
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        autocomplete: "username",
                        placeholder: _vm.$t("user.usernameEmail")
                      },
                      model: {
                        value: _vm.credentials.username,
                        callback: function($$v) {
                          _vm.$set(_vm.credentials, "username", $$v)
                        },
                        expression: "credentials.username"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "b-form-group",
                  {
                    staticClass: "m-2 mt-4 mb-4",
                    attrs: { description: _vm.$t("register.lgPass") }
                  },
                  [
                    _c("b-form-input", {
                      staticClass: "form-control",
                      attrs: {
                        type: "password",
                        autocomplete: "current-password",
                        placeholder: _vm.$t("user.enterPassword")
                      },
                      model: {
                        value: _vm.credentials.password,
                        callback: function($$v) {
                          _vm.$set(_vm.credentials, "password", $$v)
                        },
                        expression: "credentials.password"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "m-2 mt-4" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "warning", type: "button" },
                        on: {
                          click: function($event) {
                            _vm.forgot = true
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("account.forgotPassword")))]
                    ),
                    _c(
                      "b-button",
                      {
                        staticClass: "float-right",
                        attrs: { variant: "primary", type: "submit" }
                      },
                      [_vm._v(_vm._s(_vm.$t("account.login")))]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      : _c("div", [
          _c("h2", [_vm._v(_vm._s(_vm.$t("account.passwordReset")))]),
          !_vm.sent
            ? _c(
                "div",
                [
                  _c("p", [
                    _vm._v(" " + _vm._s(_vm.$t("message.resetPassword")) + " ")
                  ]),
                  _c(
                    "b-form-group",
                    { staticClass: "m-2" },
                    [
                      _c("b-form-input", {
                        staticClass: "form-control",
                        attrs: {
                          type: "email",
                          placeholder: _vm.$t("user.enterEmail")
                        },
                        model: {
                          value: _vm.forgotCredentials.email,
                          callback: function($$v) {
                            _vm.$set(_vm.forgotCredentials, "email", $$v)
                          },
                          expression: "forgotCredentials.email"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "m-2" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "primary" },
                          on: {
                            click: function($event) {
                              _vm.forgot = false
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("account.backToLogin")))]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "float-right",
                          attrs: { variant: "success" },
                          on: {
                            click: function($event) {
                              return _vm.forgotPassword()
                            }
                          }
                        },
                        [_vm._v("Send")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c("p", [
                _vm._v(" " + _vm._s(_vm.$t("message.forgotSent")) + " ")
              ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }