var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "h-100" },
    [
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.selectedDisplay.name,
            id: "pinconfirm",
            size: "md",
            "hide-footer": ""
          }
        },
        [
          _c("display-gallery", {
            attrs: { displayId: _vm.selectedDisplay.displayId }
          }),
          _c(
            "b-card",
            { staticClass: "mt-1" },
            [
              _vm.selectedDisplay
                ? _c(
                    "b-table-simple",
                    { attrs: { borderless: "", fixed: "", small: "" } },
                    [
                      _c(
                        "b-tr",
                        [
                          _c("b-th", [_vm._v(" Daily ")]),
                          _c("b-th", [_vm._v(" Weekly ")]),
                          _c("b-th", [_vm._v(" Monthly ")])
                        ],
                        1
                      ),
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [
                            _vm._v(
                              " $" +
                                _vm._s(
                                  _vm._f("money")(
                                    _vm.selectedDisplay.baselinePrice
                                      .price_per_second *
                                      15 *
                                      (86400 / _vm.selectedDisplay.blockTime)
                                  )
                                ) +
                                " "
                            )
                          ]),
                          _c("b-td", [
                            _vm._v(
                              " $" +
                                _vm._s(
                                  _vm._f("money")(
                                    _vm.selectedDisplay.baselinePrice
                                      .price_per_second *
                                      15 *
                                      ((86400 * 7) /
                                        _vm.selectedDisplay.blockTime)
                                  )
                                ) +
                                " "
                            )
                          ]),
                          _c("b-td", [
                            _vm._v(
                              " $" +
                                _vm._s(
                                  _vm._f("money")(
                                    _vm.selectedDisplay.baselinePrice
                                      .price_per_second *
                                      15 *
                                      ((86400 * 30) /
                                        _vm.selectedDisplay.blockTime)
                                  )
                                ) +
                                " "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("p", [_vm._v(_vm._s(_vm.selectedDisplay.description))]),
              _c("hr"),
              _c("small", [
                _c("p", [
                  _c("em", [
                    _vm._v(
                      "Display Contact: " +
                        _vm._s(_vm.selectedDisplay.contact_email)
                    )
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _c("em", [
                    _vm._v(
                      "Maximum Campaign Length: " +
                        _vm._s(_vm.selectedDisplay.maxTimePurchasable) +
                        "s"
                    )
                  ])
                ])
              ])
            ],
            1
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-1",
              on: {
                click: function($event) {
                  _vm.$bvModal.hide("pinconfirm")
                  _vm.$root.$children[0].$bvModal.show("login")
                }
              }
            },
            [_vm._v(" Log in to Adverpost ")]
          ),
          _c(
            "b-button",
            {
              staticClass: "float-right mt-1",
              attrs: { variant: "primary" },
              on: {
                click: function($event) {
                  _vm.$bvModal.hide("pinconfirm")
                  _vm.$root.$children[0].$bvModal.show("register")
                }
              }
            },
            [_vm._v(" Register for Adverpost ")]
          )
        ],
        1
      ),
      _c(
        "b-col",
        { staticClass: "h-100 p-0" },
        [
          _c(
            "l-map",
            {
              attrs: {
                zoom: _vm.mapSettings.zoom,
                center: _vm.mapCenter,
                options: _vm.mapSettings.options
              },
              on: {
                "update:bounds": _vm.getDisplays,
                ready: function($event) {
                  _vm.mapCenter = { lat: -34.98385, lng: 138.57395 }
                }
              }
            },
            [
              _c("l-tile-layer", {
                attrs: {
                  options: { maxZoom: 13 },
                  url: _vm.mapSettings.url,
                  attribution: _vm.mapSettings.attribution
                }
              }),
              _vm._l(_vm.displaysPrivate, function(display) {
                return _c("l-marker", {
                  key: display.displayId,
                  attrs: {
                    icon: _vm.mapSettings.iconPrivate,
                    "lat-lng": {
                      lat: display.latitude,
                      lng: display.longitude
                    },
                    zIndexOffset: -10,
                    opacity: "0"
                  }
                })
              }),
              _vm._l(_vm.displays, function(display) {
                return _c("l-marker", {
                  key: display.displayId,
                  attrs: {
                    icon:
                      display.type === "LCD" &&
                      display.pixelHeight === 0 &&
                      display.pixelWidth === 0
                        ? _vm.mapSettings.iconLcd
                        : _vm.mapSettings.iconLed,
                    "lat-lng": { lat: display.latitude, lng: display.longitude }
                  },
                  on: {
                    click: function($event) {
                      _vm.selectedDisplay = display
                      _vm.$bvModal.show("pinconfirm")
                    }
                  }
                })
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }