var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    {},
    [
      _c(
        "b-col",
        { attrs: { cols: "12", lg: "6" } },
        [
          _c(
            "b-row",
            { staticClass: "pl-3" },
            [
              _c(
                "b-col",
                { staticClass: "p-0", attrs: { cols: "12" } },
                [_c("approval", { attrs: { activeTeam: _vm.activeTeam } })],
                1
              ),
              _c(
                "b-col",
                { staticClass: "p-0", attrs: { cols: "12" } },
                [
                  _c("network", {
                    attrs: {
                      activeTeam: _vm.activeTeam,
                      displays: _vm.displays
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "p-0 mt-2", attrs: { cols: "12" } },
                [
                  _c(
                    "b-card",
                    [
                      _c(
                        "b-card-text",
                        [
                          _c(
                            "b-col",
                            {
                              staticClass: "mx-auto",
                              attrs: { cols: "12", xl: "11" }
                            },
                            [
                              _c("campaigns", {
                                attrs: {
                                  activeTeam: _vm.activeTeam,
                                  refreshTime: _vm.refreshTime
                                }
                              }),
                              _c("media", {
                                staticClass: "pt-2",
                                attrs: {
                                  activeTeam: _vm.activeTeam,
                                  refreshTime: _vm.refreshTime
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "p-0 mt-2", attrs: { cols: "12" } },
                [
                  _c("links", {
                    attrs: {
                      activeTeam: _vm.activeTeam,
                      displays: _vm.displays,
                      refreshTime: _vm.refreshTime
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "p-0 mt-2", attrs: { cols: "12" } },
                [
                  _c("payment", {
                    attrs: {
                      activeTeam: _vm.activeTeam,
                      displays: _vm.displays
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-col",
        { staticClass: "pl-2", attrs: { cols: "12", lg: "6" } },
        [
          _vm.supportUrl === "https://support.adverpost.com/"
            ? _c(
                "b-col",
                { staticClass: "p-0 mb-2", attrs: { cols: "12" } },
                [
                  _c(
                    "b-card",
                    {},
                    [
                      _c(
                        "b-row",
                        { staticClass: "pl-4 pr-4" },
                        [
                          _c("b-col", { attrs: { cols: "12", xl: "6" } }, [
                            _c("h3", { staticClass: "mt-0 pt-0" }, [
                              _vm._v("Support")
                            ]),
                            _c("p", [
                              _vm._v(
                                "Support for the Adverpost system is typically provided by your reseller. If you have purchased access to Adverpost directly from One World LED, or have an active support contract with us, tickets can be created using the facilities on this page."
                              )
                            ]),
                            _c("p", [
                              _vm._v("Other resources are available "),
                              _c(
                                "a",
                                {
                                  staticStyle: { display: "inline" },
                                  attrs: { href: _vm.supportUrl }
                                },
                                [_vm._v("here")]
                              ),
                              _vm._v(".")
                            ])
                          ]),
                          _c(
                            "b-col",
                            {
                              staticClass: "border rounded pt-3",
                              attrs: { cols: "12", xl: "6" }
                            },
                            [
                              _c("p", { staticClass: "text-center" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "btn btn-primary btn-lg m-1",
                                    attrs: {
                                      href: "//support.oneworldled.com/",
                                      role: "button"
                                    }
                                  },
                                  [_vm._v("Create Support Ticket")]
                                )
                              ]),
                              _c("p", { staticClass: "text-center" }, [
                                _vm._v("Telephone number (within Australia): "),
                                _c("a", { attrs: { href: "tel:0883744557" } }, [
                                  _vm._v("(08) 8374 4557 "),
                                  _c("i", { staticClass: "fas fa-phone" })
                                ]),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v("(10AM to 5PM weekdays ACST)")
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-col",
            { staticClass: "p-0", attrs: { cols: "12" } },
            [_c("active", { attrs: { activeTeam: _vm.activeTeam } })],
            1
          ),
          false && _vm.displays.length > 0
            ? _c(
                "b-col",
                { staticClass: "pt-2", attrs: { cols: "12" } },
                [
                  _c(
                    "b-card",
                    [[_c("Graph", { attrs: { displays: _vm.displays } })]],
                    2
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }