var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c("b-col", { staticClass: "pb-4", attrs: { cols: "6" } }, [
            _vm.validSchedule
              ? _c(
                  "div",
                  [
                    _c("schedule-player", {
                      attrs: {
                        width: "100%",
                        height: "20em",
                        uri: _vm.uri,
                        params: _vm.e2v
                      }
                    })
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c("b-skeleton-img", {
                      attrs: { width: "100%", height: "25vw" }
                    })
                  ],
                  1
                )
          ]),
          _c(
            "b-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "h4",
                            [
                              _vm._v("E2V Stream Options "),
                              _vm.parametersLoaded !== true
                                ? _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        variant: "primary",
                                        disabled: ""
                                      }
                                    },
                                    [
                                      _c("b-spinner", {
                                        attrs: { small: "", type: "grow" }
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s("Retrieving parameter list") +
                                          " "
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm.parameters.length == 0
                            ? _c("div", [_vm._v(" No parameters required. ")])
                            : _vm._e(),
                          _vm._l(_vm.parameters, function(parameter) {
                            return _c("div", { key: parameter.slug }, [
                              parameter.opts
                                ? _c(
                                    "div",
                                    [
                                      _c("multiselect", {
                                        attrs: {
                                          placeholder: "Select",
                                          options: parameter.opts,
                                          "track-by": "id",
                                          label: "name",
                                          "preselect-first": true
                                        },
                                        on: {
                                          select: function(opt, id) {
                                            _vm.e2v[parameter.slug] = opt.id
                                          }
                                        },
                                        model: {
                                          value: _vm.selector[parameter.slug],
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.selector,
                                              parameter.slug,
                                              $$v
                                            )
                                          },
                                          expression: "selector[parameter.slug]"
                                        }
                                      }),
                                      (_vm.selectCheck = true)
                                        ? _c("b-form-text", [
                                            _vm._v(
                                              " " + _vm._s(parameter.desc) + " "
                                            )
                                          ])
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "e2v_" + parameter.slug,
                                          state: _vm.validSchedule,
                                          placeholder: parameter.name,
                                          "aria-describedby":
                                            "input-live-feedback",
                                          trim: ""
                                        },
                                        model: {
                                          value: _vm.e2v[parameter.slug],
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.e2v,
                                              parameter.slug,
                                              $$v
                                            )
                                          },
                                          expression: "e2v[parameter.slug]"
                                        }
                                      }),
                                      _c(
                                        "b-form-invalid-feedback",
                                        {
                                          attrs: { id: "input-live-feedback" }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.errors[parameter.slug]
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "b-form-text",
                                        {
                                          attrs: { id: "e2v_" + parameter.slug }
                                        },
                                        [_vm._v(_vm._s(parameter.desc))]
                                      )
                                    ],
                                    1
                                  )
                            ])
                          })
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-col",
                        [
                          _c("hr", { staticClass: "border" }),
                          _c("h4", [_vm._v("Campaign Stats")]),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [_vm._v("Matched Campaigns")]),
                              _c("b-col", [
                                _c("label", [
                                  _vm._v(_vm._s(_vm.totalCampaigns))
                                ])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [_vm._v("Shortest Campaign")]),
                              _c("b-col", [
                                _c("label", [
                                  _vm._v(
                                    _vm._s(_vm.minimumDuration) + " seconds"
                                  )
                                ])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", [_vm._v("Longest Campaign")]),
                              _c("b-col", [
                                _c("label", [
                                  _vm._v(
                                    _vm._s(_vm.maximumDuration) + " seconds"
                                  )
                                ])
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        [
                          _c("hr", { staticClass: "border" }),
                          _vm._t("default")
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }