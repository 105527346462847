var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "instance", staticClass: "player", attrs: { id: this.instance_id } },
    [
      this.$slots.default
        ? [_vm._t("default")]
        : [
            this.queue[0] && this.queue[0].media.length === 0
              ? _c("div", { staticClass: "h-100 w-100 h5 text-center mt-4" }, [
                  _vm._v("No Media")
                ])
              : _vm._e(),
            _c("div", { ref: "custom", staticClass: "custom" }),
            _c("div", {
              ref: "target",
              staticClass: "target",
              style: { background: _vm.backgroundStyle }
            }),
            !_vm.hideControls
              ? [
                  _c(
                    "div",
                    { ref: "controls", staticClass: "player-controls" },
                    [
                      _c("b-form-input", {
                        staticClass: "player-slider",
                        attrs: {
                          type: "range",
                          step: "1",
                          max: _vm.context_duration
                        },
                        on: { mousedown: _vm.pause, mouseup: _vm.resume },
                        model: {
                          value: _vm.context_progress,
                          callback: function($$v) {
                            _vm.context_progress = $$v
                          },
                          expression: "context_progress"
                        }
                      }),
                      _c(
                        "div",
                        { ref: "counter", staticClass: "player-counter" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(this.context_progress) +
                              "/" +
                              _vm._s(this.context_duration) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              : _vm._e()
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }