var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShowing(this.image)
    ? _c("img", {
        staticClass: "image",
        style: _vm.styles,
        attrs: { src: _vm.image.uri }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }