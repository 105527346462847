var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Expected Campaign Duration",
                    "label-for": "duration"
                  }
                },
                [
                  _c(
                    "b-input-group",
                    { attrs: { id: "duration", append: "sec" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          type: "number",
                          step: "1",
                          max: _vm.maxTimePurchasable,
                          min: "0"
                        },
                        model: {
                          value: _vm.durationEstimate,
                          callback: function($$v) {
                            _vm.durationEstimate = $$v
                          },
                          expression: "durationEstimate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                { attrs: { label: "Per Day", "label-for": "day" } },
                [
                  _c(
                    "b-input-group",
                    { attrs: { id: "day", prepend: "$" } },
                    [
                      _c("b-form-input", {
                        attrs: { type: "number", step: "0.1", min: "0" },
                        model: {
                          value: _vm.perDay,
                          callback: function($$v) {
                            _vm.perDay = $$v
                          },
                          expression: "perDay"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-3" },
        [
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Price Per Second",
                    "label-for": "pricePerSecond"
                  }
                },
                [
                  _c(
                    "b-input-group",
                    { attrs: { id: "pricePerSecond", append: "¢" } },
                    [
                      _c("b-form-input", {
                        attrs: { disabled: "", value: _vm.centsPerSecond }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                { attrs: { label: "Per Week", "label-for": "week" } },
                [
                  _c(
                    "b-input-group",
                    { attrs: { id: "week", prepend: "$" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          disabled: "",
                          value:
                            _vm.centsPerSecond == 0
                              ? 0
                              : Math.round(_vm.perDay * 7 * 10) / 10
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                { attrs: { label: "Per Month", "label-for": "month" } },
                [
                  _c(
                    "b-input-group",
                    { attrs: { id: "month", prepend: "$" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          disabled: "",
                          value:
                            _vm.centsPerSecond == 0
                              ? 0
                              : Math.round(_vm.perDay * 30 * 10) / 10
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.showButton
        ? _c(
            "b-button",
            {
              staticClass: "float-right mt-3",
              attrs: { name: "button", variant: "primary" },
              on: {
                click: function($event) {
                  return _vm.close()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("buttons.close")))]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }