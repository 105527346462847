var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.filteredBookings.length,
          expression: "filteredBookings.length"
        }
      ]
    },
    [
      _c(
        "b-card",
        { attrs: { title: _vm.$t("dashboard.common.current") } },
        [
          _c("b-card-text", [
            !_vm.loading
              ? _c(
                  "div",
                  [
                    _c("b-table", {
                      attrs: {
                        id: "activeTable",
                        items: _vm.filteredBookings,
                        "per-page": 10,
                        "sort-by": _vm.sortBy,
                        "sort-desc": _vm.sortDesc,
                        "current-page": _vm.currentPage,
                        fields: _vm.fields,
                        responsive: "",
                        stacked: "md",
                        borderless: "",
                        small: "",
                        "sort-icon-left": ""
                      },
                      on: {
                        "update:sortBy": function($event) {
                          _vm.sortBy = $event
                        },
                        "update:sort-by": function($event) {
                          _vm.sortBy = $event
                        },
                        "update:sortDesc": function($event) {
                          _vm.sortDesc = $event
                        },
                        "update:sort-desc": function($event) {
                          _vm.sortDesc = $event
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "cell(buttons)",
                            fn: function(row) {
                              return [
                                row.detailsShowing
                                  ? [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass:
                                            "border border-muted text-dark",
                                          attrs: {
                                            size: "sm",
                                            variant: "light"
                                          },
                                          on: { click: row.toggleDetails }
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: { icon: "arrow-up" }
                                          }),
                                          _vm._v(" Close")
                                        ],
                                        1
                                      )
                                    ]
                                  : [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass:
                                            "border border-muted text-dark",
                                          attrs: {
                                            size: "sm",
                                            variant: "light"
                                          },
                                          on: { click: row.toggleDetails }
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: { icon: "arrow-down" }
                                          }),
                                          _vm._v(" Expand")
                                        ],
                                        1
                                      )
                                    ]
                              ]
                            }
                          },
                          {
                            key: "cell(paidStatus)",
                            fn: function(paid) {
                              return [
                                paid.item.displayTeamId !==
                                paid.item.advertiserId
                                  ? _c(
                                      "b-badge",
                                      { attrs: { variant: "success" } },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("currency.symbol"))
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            }
                          },
                          {
                            key: "cell(displayName)",
                            fn: function(display) {
                              return [
                                _c(
                                  "b-link",
                                  {
                                    staticClass: "text-dark",
                                    attrs: {
                                      to: {
                                        name: "display-view",
                                        params: {
                                          displayId: display.item.displayId
                                        }
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(display.item.displayName))]
                                )
                              ]
                            }
                          },
                          {
                            key: "cell(campaignName)",
                            fn: function(campaign) {
                              return [
                                _c(
                                  "b-link",
                                  {
                                    staticClass: "text-dark",
                                    attrs: {
                                      to: {
                                        name: "campaign",
                                        params: {
                                          campaignId: campaign.item.campaignId
                                        }
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(campaign.item.campaignName))]
                                )
                              ]
                            }
                          },
                          {
                            key: "cell(endDate)",
                            fn: function(booking) {
                              return [
                                booking.item.endDate >= "2037-01-01"
                                  ? _c(
                                      "p",
                                      [
                                        _c("b-icon-arrow-repeat"),
                                        _vm._v(" No End Date")
                                      ],
                                      1
                                    )
                                  : _c("p", [
                                      _vm._v(_vm._s(booking.item.endDate))
                                    ])
                              ]
                            }
                          },
                          {
                            key: "row-details",
                            fn: function(row) {
                              return [
                                _c(
                                  "b-row",
                                  { staticClass: "m-0 p-0" },
                                  [
                                    _c(
                                      "b-col",
                                      [
                                        _c("campaign", {
                                          staticStyle: { height: "18vh" },
                                          attrs: {
                                            media: _vm.campaigns.find(function(
                                              c
                                            ) {
                                              return (
                                                c.value.campaignId ==
                                                row.item.campaignId
                                              )
                                            }).value.media.data,
                                            noControls: ""
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      [
                                        _c(
                                          "b-table-simple",
                                          {
                                            attrs: { small: "", borderless: "" }
                                          },
                                          [
                                            _c(
                                              "b-tr",
                                              [
                                                _c("b-th", [
                                                  _vm._v("Start Time")
                                                ]),
                                                _c("b-td", [
                                                  _vm._v(
                                                    _vm._s(row.item.startTime)
                                                  )
                                                ])
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-tr",
                                              [
                                                _c("b-th", [
                                                  _vm._v("End Time")
                                                ]),
                                                _c("b-td", [
                                                  _vm._v(
                                                    _vm._s(row.item.endTime)
                                                  )
                                                ])
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-tr",
                                              [
                                                _c("b-th", [
                                                  _vm._v("Duration")
                                                ]),
                                                _c("b-td", [
                                                  _vm._v(
                                                    _vm._s(row.item.duration) +
                                                      " " +
                                                      _vm._s(_vm.$t("seconds"))
                                                  )
                                                ])
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-tr",
                                              [
                                                _c("b-th", [
                                                  _vm._v("Days Enabled")
                                                ]),
                                                _c("b-td", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "btn-group"
                                                    },
                                                    _vm._l(
                                                      [
                                                        "M",
                                                        "T",
                                                        "W",
                                                        "T",
                                                        "F",
                                                        "S",
                                                        "S"
                                                      ],
                                                      function(day, index) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: index,
                                                            staticClass:
                                                              "d-inline-block border text-light p-1 pl-2 pr-2",
                                                            class:
                                                              row.item
                                                                .daysEnabled &
                                                              Math.pow(2, index)
                                                                ? "bg-dark"
                                                                : "bg-light",
                                                            style: {
                                                              fontWeight:
                                                                "bold",
                                                              width: "2em",
                                                              fontSize: "90%"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(day) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                ])
                                              ],
                                              1
                                            ),
                                            row.item.displayTeamId ===
                                            row.item.advertiserId
                                              ? _c(
                                                  "b-tr",
                                                  [
                                                    _c("b-th", [
                                                      _vm._v("Actions")
                                                    ]),
                                                    _c(
                                                      "b-td",
                                                      [
                                                        row.item.approval ===
                                                        "approved"
                                                          ? _c(
                                                              "div",
                                                              [
                                                                _c(
                                                                  "b-button",
                                                                  {
                                                                    staticClass:
                                                                      "ml-0 mr-1",
                                                                    attrs: {
                                                                      variant:
                                                                        "warning",
                                                                      size: "sm"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        _vm.working =
                                                                          row.item
                                                                        _vm.$bvModal.show(
                                                                          "replace-modal"
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "dashboard.current.replace"
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-button",
                                                                  {
                                                                    staticClass:
                                                                      "ml-1",
                                                                    attrs: {
                                                                      variant:
                                                                        "danger",
                                                                      size: "sm"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        _vm.working =
                                                                          row.item
                                                                        _vm.$bvModal.show(
                                                                          "cancel-modal"
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "dashboard.current.cancel"
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _c(
                                                              "b-badge",
                                                              {
                                                                attrs: {
                                                                  variant:
                                                                    row.item
                                                                      .startDate >
                                                                    _vm.currentDate
                                                                      ? "warning"
                                                                      : "danger"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "Pending Approval"
                                                                    )
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "b-tr",
                                                  [
                                                    _c("b-th", [
                                                      _vm._v("Advertiser")
                                                    ]),
                                                    _c("b-td", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            row.item.advertiser
                                                          ) +
                                                          " "
                                                      ),
                                                      _c("em", [
                                                        _vm._v(
                                                          _vm._s(
                                                            row.item
                                                              .advertiserContact
                                                          )
                                                        )
                                                      ])
                                                    ])
                                                  ],
                                                  1
                                                )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2303995979
                      )
                    }),
                    _vm.rows > 10
                      ? _c("b-pagination", {
                          attrs: {
                            "hide-goto-end-buttons": "",
                            "hide-ellipsis": "",
                            align: "center",
                            "total-rows": _vm.rows,
                            "per-page": _vm.perPage,
                            "aria-controls": "activeTable"
                          },
                          model: {
                            value: _vm.currentPage,
                            callback: function($$v) {
                              _vm.currentPage = $$v
                            },
                            expression: "currentPage"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "loader" },
                  [_c("b-spinner", { attrs: { label: "Loading" } })],
                  1
                )
          ])
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "cancel-modal",
            title: _vm.$t("actions.cancel"),
            size: "md"
          }
        },
        [
          _c("p", [_vm._v(_vm._s(_vm.$t("message.paidCancel")))]),
          _vm.working.campaign
            ? _c(
                "b-table-simple",
                { attrs: { outlined: "", small: "" } },
                [
                  _c(
                    "b-tr",
                    [
                      _c("b-th", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("dashboard.campaigns.name")) + " "
                        )
                      ]),
                      _c("b-td", [
                        _vm._v(
                          " " + _vm._s(_vm.working.campaign.data.name) + " "
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-tr",
                    [
                      _c("b-th", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("dashboard.campaigns.display")) +
                            " "
                        )
                      ]),
                      _c("b-td", [
                        _vm._v(" " + _vm._s(_vm.working.displayName) + " ")
                      ])
                    ],
                    1
                  ),
                  _vm.working.startDate === _vm.working.endDate
                    ? _c(
                        "b-tr",
                        [
                          _c("b-th", [
                            _vm._v(" " + _vm._s(_vm.$t("dateTime.date")) + " ")
                          ]),
                          _c("b-td", [
                            _vm._v(" " + _vm._s(_vm.working.startDate) + " ")
                          ])
                        ],
                        1
                      )
                    : _c(
                        "b-tr",
                        [
                          _c("b-th", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("dateTime.dateRange")) + " "
                            )
                          ]),
                          _c("b-td", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.working.startDate) +
                                " to " +
                                _vm._s(_vm.working.endDate) +
                                " "
                            )
                          ])
                        ],
                        1
                      ),
                  _c(
                    "b-tr",
                    [
                      _c("b-th", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("dateTime.dailyTimes")) + " "
                        )
                      ]),
                      _vm.working.startTime === "00:00:00" &&
                      _vm.working.endTime === "23:59:59"
                        ? _c("b-td", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("dateTime.allDay")) + " "
                            )
                          ])
                        : _c("b-td", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.working.startTime) +
                                " to " +
                                _vm._s(_vm.working.endTime) +
                                " "
                            )
                          ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c("p", [
            _c("em", [_vm._v(_vm._s(_vm.$t("message.paidCancelNote")))])
          ]),
          _c(
            "div",
            { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
            [
              _c(
                "b-button",
                {
                  staticClass: "float-left",
                  attrs: { variant: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.$bvModal.hide("cancel-modal")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("answers.no")) + " ")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "float-right ml-2",
                  attrs: { variant: "danger" },
                  on: {
                    click: function($event) {
                      return _vm.removeCampaign(
                        _vm.working.id,
                        _vm.working.displayId
                      )
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("actions.cancel")) + " ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "replace-modal",
            title: _vm.$t("actions.replace"),
            size: "md"
          }
        },
        [
          _c("p", [_vm._v(_vm._s(_vm.$t("message.replaceBooking")))]),
          _c(
            "b-form",
            [
              _c(
                "b-form-group",
                { attrs: { id: "campaign-group", "label-for": "campaign" } },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "campaign",
                      options: _vm.campaigns,
                      required: ""
                    },
                    model: {
                      value: _vm.selectedCampaign,
                      callback: function($$v) {
                        _vm.selectedCampaign = $$v
                      },
                      expression: "selectedCampaign"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-1" },
            [
              _c("campaign", {
                staticStyle: { height: "18vh" },
                attrs: { media: _vm.previewMedia, noControls: "" }
              })
            ],
            1
          ),
          _c(
            "b-table-simple",
            { attrs: { small: "" } },
            [
              _c(
                "b-tr",
                [
                  _c("b-th"),
                  _c("b-th", [_vm._v(" Current Campaign ")]),
                  _c("b-th", [_vm._v(" Selected Campaign ")])
                ],
                1
              ),
              _c(
                "b-tr",
                [
                  _c("b-th", [_vm._v(" Duration ")]),
                  _c("b-td", [
                    _vm._v(" " + _vm._s(_vm.working.duration) + "s ")
                  ]),
                  _c("b-td", [
                    _vm._v(" " + _vm._s(_vm.selectedDuration) + "s ")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "h4",
            [
              _vm._v("Suitability for replacement: "),
              _c("b-badge", { attrs: { variant: _vm.suitable } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("dashboard.current.variantActions." + _vm.suitable)
                    )
                )
              ])
            ],
            1
          ),
          _vm.suitable == "danger"
            ? _c("p", [
                _c("em", [_vm._v(_vm._s(_vm.$t("message.replaceLong")))])
              ])
            : _vm.suitable != "success"
            ? _c("p", [
                _c("em", [_vm._v(_vm._s(_vm.$t("message.replaceShort")))])
              ])
            : _vm._e(),
          _c(
            "div",
            { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
            [
              _c(
                "b-button",
                {
                  staticClass: "float-left",
                  attrs: { variant: "secondary" },
                  on: {
                    click: function($event) {
                      return _vm.$bvModal.hide("cancel-modal")
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("dashboard.current.cancel")) + " ")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "float-right ml-2",
                  attrs: {
                    variant: "primary",
                    disabled: _vm.suitable == "danger"
                  },
                  on: {
                    click: function($event) {
                      return _vm.replaceCampaign(
                        _vm.working.id,
                        _vm.working.displayId,
                        _vm.selectedCampaign.campaignId
                      )
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("actions.replace")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }