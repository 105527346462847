var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "alert",
        "notification",
        _vm.type,
        { "alert-dismissible": _vm.notification.dismissable }
      ],
      attrs: { role: "alert" }
    },
    [
      _vm.notification.dismissable
        ? _c(
            "button",
            {
              staticClass: "close",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.$notifications.remove(_vm.notification)
                }
              }
            },
            [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
          )
        : _vm._e(),
      _c("strong", [_vm._v(_vm._s(_vm.notification.title))]),
      _vm._v(" - " + _vm._s(_vm.notification.body) + " ")
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }