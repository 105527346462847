var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "relative" },
    [
      this.queue.length > 0
        ? [
            _c(
              "div",
              { staticClass: "mx-auto text-center h4" },
              [
                _c(
                  "b-button-group",
                  _vm._l(_vm.displayedQueue, function(c) {
                    return _c(
                      "b-badge",
                      {
                        key: c.campaignId,
                        staticClass:
                          "btn border btn-light butty font-weight-bold",
                        attrs: {
                          variant:
                            c.campaignId === _vm.context
                              ? "primary"
                              : _vm.mediaDone(c)
                              ? "light"
                              : "danger"
                        },
                        on: {
                          click: function($event) {
                            return _vm.triggerJump(c.campaignId)
                          }
                        }
                      },
                      [_vm._v(_vm._s(String(c.campaignId).padStart(2, 0)))]
                    )
                  }),
                  1
                )
              ],
              1
            )
          ]
        : _vm._e(),
      _vm.isRealCampaign
        ? [
            _c("div", { staticClass: "mx-auto text-center h5" }, [
              _vm._v(" name: "),
              _c("em", [_vm._v(_vm._s(_vm.computedName))])
            ])
          ]
        : _vm._e(),
      [
        _c("player-core", {
          ref: "player",
          staticClass: "player-wrapper mx-auto m-4",
          style: { width: _vm.width, height: _vm.height },
          attrs: {
            background: "#000",
            hideControls: !_vm.isRealCampaign,
            cache: _vm.availableCache,
            queue: _vm.availableQueue
          },
          on: { setcontext: _vm.setContext }
        })
      ],
      _c("div", [
        _vm.errored.length > 0
          ? _c(
              "div",
              [
                _vm._v(" failed to load: "),
                _vm._l(_vm.errored, function(uri, idx) {
                  return _c("div", { key: idx }, [
                    _vm._v(" " + _vm._s(uri) + " ")
                  ])
                })
              ],
              2
            )
          : _vm._e()
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }