var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "delete-modal",
            title: _vm.$t("actions.delete") + " " + _vm.campaign.name
          }
        },
        [
          _vm._v(" " + _vm._s(_vm.$t("message.campaignDelete")) + " "),
          _c("div", { attrs: { slot: "modal-footer" }, slot: "modal-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn delete-button btn-primary left",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    _vm.deleteCampaign()
                    _vm.$bvModal.hide("delete-modal")
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("answers.yes")))]
            ),
            _c(
              "button",
              {
                staticClass: "btn delete-button btn-primary right",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.$bvModal.hide("delete-modal")
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("answers.no")))]
            )
          ])
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "edit-modal",
            "no-close-on-backdrop": "",
            size: "xl",
            "hide-footer": ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function() {
                return [
                  _c("b-input", {
                    staticClass: "wvh-50",
                    attrs: { placeholder: "Campaign Name" },
                    model: {
                      value: _vm.campaign.name,
                      callback: function($$v) {
                        _vm.$set(_vm.campaign, "name", $$v)
                      },
                      expression: "campaign.name"
                    }
                  })
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm.errors.length > 0
            ? _c("div", { staticClass: "alert alert-danger" }, [
                _c(
                  "ul",
                  _vm._l(_vm.errors, function(error, index) {
                    return _c("li", { key: index }, [
                      _vm._v(" " + _vm._s(error) + " ")
                    ])
                  }),
                  0
                )
              ])
            : _vm._e(),
          _vm.campaign !== {}
            ? _c("campaign-editor", {
                attrs: { activeTeam: _vm.activeTeam, campaign: _vm.campaign },
                on: {
                  saved: function($event) {
                    return _vm.$emit("close")
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "b-col",
        { staticClass: "campaign-nav w-100", attrs: { cols: "12" } },
        [
          _c(
            "div",
            { staticClass: "d-flex justify-content-center align-items-center" },
            [
              _c("h1", { staticClass: "campaign-name d-none d-md-block" }, [
                _vm._v(_vm._s(_vm.campaign.name))
              ]),
              _c(
                "b-button-toolbar",
                {},
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.edit-modal",
                          modifiers: { "edit-modal": true }
                        }
                      ],
                      staticClass: "m-1 border-primary text-primary",
                      attrs: { variant: "light" }
                    },
                    [
                      _c("b-icon", { attrs: { icon: "card-list" } }),
                      _vm._v(" Edit Campaign ")
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.delete-modal",
                          modifiers: { "delete-modal": true }
                        }
                      ],
                      staticClass: "m-1 border-danger text-danger",
                      attrs: { variant: "light" }
                    },
                    [
                      _c("b-icon", { attrs: { icon: "x-octagon" } }),
                      _vm._v(" " + _vm._s(_vm.$t("buttons.delete")) + " ")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "b-col",
        { staticClass: "h-100 campaign-main", attrs: { cols: "12" } },
        [
          _vm.errors.length > 0
            ? _c("div", { staticClass: "mt-2 mb-2 alert alert-danger" }, [
                _c(
                  "ul",
                  _vm._l(_vm.errors, function(error, index) {
                    return _c("li", { key: index }, [
                      _vm._v(" " + _vm._s(error) + " ")
                    ])
                  }),
                  0
                )
              ])
            : _vm._e(),
          _c(
            "transition",
            { attrs: { name: "fade" } },
            [
              _c("edit-campaign", {
                attrs: { activeTeam: _vm.activeTeam, campaign: _vm.campaign },
                on: {
                  update: function($event) {
                    return _vm.emit("update")
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }