var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { cols: "6" } },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.$t("sources.name"),
                description: _vm.$t("sources.nameDesc")
              }
            },
            [
              _c("b-form-input", {
                attrs: { required: "" },
                model: {
                  value: _vm.source.name,
                  callback: function($$v) {
                    _vm.$set(_vm.source, "name", $$v)
                  },
                  expression: "source.name"
                }
              })
            ],
            1
          ),
          _c(
            "b-form-group",
            { attrs: { label: _vm.$t("sources.team"), description: "" } },
            [
              _c("b-form-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.activeTeam.name,
                  callback: function($$v) {
                    _vm.$set(_vm.activeTeam, "name", $$v)
                  },
                  expression: "activeTeam.name"
                }
              })
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.$t("sources.uri"),
                description: _vm.$t("sources.uriDesc")
              }
            },
            [
              _c("b-form-input", {
                attrs: { required: "" },
                on: {
                  input: function($event) {
                    return _vm.checkURL()
                  }
                },
                model: {
                  value: _vm.source.uri,
                  callback: function($$v) {
                    _vm.$set(_vm.source, "uri", $$v)
                  },
                  expression: "source.uri"
                }
              }),
              _c(
                "b-form-invalid-feedback",
                { attrs: { state: _vm.isGoodURL } },
                [_vm._v(" " + _vm._s(_vm.$t("sources.badUrl")) + " ")]
              ),
              _c("b-form-valid-feedback", { attrs: { state: _vm.isGoodURL } }, [
                _vm._v(" " + _vm._s(_vm.$t("sources.goodUrl")) + " ")
              ])
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.$t("sources.apiKey"),
                description: _vm.$t("sources.apiDesc")
              }
            },
            [
              _c("b-form-input", {
                model: {
                  value: _vm.source.api_key,
                  callback: function($$v) {
                    _vm.$set(_vm.source, "api_key", $$v)
                  },
                  expression: "source.api_key"
                }
              })
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: "API Type",
                description: "File type of API data",
                "invalid-feedback": _vm.invalidType,
                state: _vm.state
              }
            },
            [
              _c("b-form-select", {
                attrs: { options: _vm.typeSelection, state: _vm.state },
                model: {
                  value: _vm.selected,
                  callback: function($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected"
                }
              })
            ],
            1
          ),
          _c(
            "b-button",
            {
              attrs: { variant: "primary" },
              on: {
                click: function($event) {
                  return _vm.createSource()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("buttons.save")))]
          )
        ],
        1
      ),
      _c(
        "b-col",
        { attrs: { cols: "6" } },
        [_c("source-status", { attrs: { source: _vm.source } })],
        1
      ),
      _c(
        "b-button",
        {
          attrs: {
            block: "",
            variant: "secondary",
            disabled: _vm.isGoodURL !== true
          },
          on: {
            click: function($event) {
              return _vm.addE2VElement()
            }
          }
        },
        [_vm._v("Add API Element")]
      ),
      _c("b-container", [_c("b-row")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }