var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.pages[_vm.index] === "select"
        ? _c("select-campaign", {
            on: { campaignSelected: _vm.selectCampaign, next: _vm.next }
          })
        : _vm._e(),
      _vm.pages[_vm.index] === "schedule"
        ? _c("schedule-campaign", {
            attrs: {
              selectDisabled: _vm.selectDisabled,
              campaign: _vm.campaign,
              display: _vm.display,
              savedBooking: _vm.bookingData
            },
            on: {
              next: _vm.next,
              back: _vm.previous,
              dataChange: _vm.dataChange,
              done: function($event) {
                return _vm.$emit("done")
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }