var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h-100 home-main" },
    [
      _c(
        "b-container",
        [
          _c(
            "b-row",
            { staticClass: "top-margin" },
            [
              _c("b-col", { attrs: { lg: "7", cols: "12" } }, [
                _c(
                  "div",
                  { staticClass: "float-middle" },
                  [
                    _c("h2", [_vm._v(_vm._s(_vm.$t("home.heroHeader")))]),
                    _c("p", [_vm._v(_vm._s(_vm.$t("home.heroText")))]),
                    _c(
                      "b-button",
                      {
                        staticClass: "buttons button-red",
                        on: {
                          click: function($event) {
                            return _vm.$root.$children[0].$bvModal.show(
                              "register"
                            )
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("home.signUp")))]
                    )
                  ],
                  1
                )
              ]),
              _c("b-col", { attrs: { lg: "5", cols: "12" } }, [
                _c("img", {
                  staticClass: "photo",
                  staticStyle: { width: "100%" },
                  attrs: { src: "/static/ad02.jpg" }
                })
              ])
            ],
            1
          ),
          _c(
            "div",
            [
              _c("h2", [_vm._v(_vm._s(_vm.$t("home.mapHeader")))]),
              _c("p", [_vm._v(_vm._s(_vm.$t("home.mapText")))]),
              _c(
                "b-col",
                {
                  staticClass: "static-map pb-3 pt-3",
                  attrs: { cols: "12", md: "12" }
                },
                [_c("static-map")],
                1
              ),
              _c("p", [
                _c("span", { staticClass: "red-text" }, [
                  _vm._v(_vm._s(_vm.$t("home.mapRed")))
                ]),
                _vm._v(_vm._s(_vm.$t("home.mapCaption")))
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "top-margin" },
            [
              _c("h2", [_vm._v(_vm._s(_vm.$t("home.stepHeader")))]),
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { lg: "6", cols: "12" } }, [
                    _c("div", { staticClass: "step-padding" }, [
                      _c("img", {
                        staticClass: "step-image",
                        attrs: { src: "/static/ad06.svg" }
                      }),
                      _c("h5", [_vm._v(_vm._s(_vm.$t("home.step1Header")))]),
                      _c("p", { staticClass: "small-text" }, [
                        _vm._v(_vm._s(_vm.$t("home.step1Text")))
                      ])
                    ])
                  ]),
                  _c("b-col", { attrs: { lg: "6", cols: "12" } }, [
                    _c("div", { staticClass: "step-padding right-padding" }, [
                      _c("img", {
                        staticClass: "step-image",
                        attrs: { src: "/static/ad05.svg" }
                      }),
                      _c("h5", [_vm._v(_vm._s(_vm.$t("home.step2Header")))]),
                      _c("p", { staticClass: "small-text" }, [
                        _vm._v(_vm._s(_vm.$t("home.step2Text")))
                      ])
                    ])
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { lg: "6", cols: "12" } }, [
                    _c("div", { staticClass: "step-padding" }, [
                      _c("img", {
                        staticClass: "step-image",
                        attrs: { src: "/static/ad04.svg" }
                      }),
                      _c("h5", [_vm._v(_vm._s(_vm.$t("home.step3Header")))]),
                      _c("p", { staticClass: "small-text" }, [
                        _vm._v(_vm._s(_vm.$t("home.step3Text")))
                      ])
                    ])
                  ]),
                  _c("b-col", { attrs: { lg: "6", cols: "12" } }, [
                    _c("div", { staticClass: "step-padding right-padding" }, [
                      _c("img", {
                        staticClass: "step-image",
                        attrs: { src: "/static/ad03.svg" }
                      }),
                      _c("h5", [_vm._v(_vm._s(_vm.$t("home.step4Header")))]),
                      _c("p", { staticClass: "small-text" }, [
                        _vm._v(_vm._s(_vm.$t("home.step4Text")))
                      ])
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "red-bar" },
            [
              _c(
                "b-row",
                { staticClass: "red-bar-contents" },
                [
                  _c("b-col", { attrs: { lg: "9", cols: "12" } }, [
                    _c("h1", [_vm._v(_vm._s(_vm.$t("home.barHeader")))]),
                    _c("p", [_vm._v(_vm._s(_vm.$t("home.barText")))])
                  ]),
                  _c(
                    "b-col",
                    { attrs: { lg: "3", cols: "12" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "buttons button-white float-middle",
                          on: {
                            click: function($event) {
                              return _vm.$root.$children[0].$bvModal.show(
                                "register"
                              )
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("home.signUp")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "top-margin" },
            [
              _c("b-col", { attrs: { lg: "7", cols: "12" } }, [
                _c(
                  "div",
                  { staticClass: "float-middle" },
                  [
                    _c("h2", [_vm._v(_vm._s(_vm.$t("home.ownerHeader")))]),
                    _c("p", [_vm._v(_vm._s(_vm.$t("home.ownerText")))]),
                    _c(
                      "b-button",
                      {
                        staticClass: "buttons button-red",
                        on: {
                          click: function($event) {
                            return _vm.$root.$children[0].$bvModal.show(
                              "register"
                            )
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("home.signUp")))]
                    )
                  ],
                  1
                )
              ]),
              _c("b-col", { attrs: { lg: "5", cols: "12" } }, [
                _c("img", {
                  staticClass: "float-right photo",
                  staticStyle: { width: "100%" },
                  attrs: { src: "/static/ad07.jpg" }
                })
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "footer" },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                { staticClass: "mb-3" },
                [
                  _c("b-col", { attrs: { lg: "4", cols: "12" } }, [
                    _c("img", {
                      staticClass: "mb-3",
                      attrs: {
                        src: "/static/" + "adverpost" + "-white.svg",
                        height: "34"
                      }
                    }),
                    _c("p", [
                      _vm._v("One World LED Pty Ltd ABN 33 161 705 068")
                    ])
                  ]),
                  _c("b-col", { attrs: { lg: "4", cols: "6" } }, [
                    _c("ul", [
                      _c(
                        "li",
                        [
                          _c("b-link", { attrs: { href: _vm.supportUrl } }, [
                            _vm._v(" " + _vm._s(_vm.$t("home.footer.contact")))
                          ])
                        ],
                        1
                      ),
                      _c(
                        "li",
                        [
                          _c("b-link", { attrs: { href: _vm.supportUrl } }, [
                            _vm._v(" " + _vm._s(_vm.$t("home.footer.support")))
                          ])
                        ],
                        1
                      ),
                      _c(
                        "li",
                        [
                          _c(
                            "b-link",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.$router.push({ path: "/owners" })
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("home.footer.owners")))]
                          )
                        ],
                        1
                      )
                    ])
                  ]),
                  _c("b-col", { attrs: { lg: "4", cols: "6" } }, [
                    _c("ul", [
                      _c(
                        "li",
                        [
                          _c(
                            "b-link",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.$router.push({ path: "/terms" })
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("home.footer.tos")))]
                          )
                        ],
                        1
                      ),
                      _c(
                        "li",
                        [
                          _c(
                            "b-link",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.$router.push({ path: "/privacy" })
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("home.footer.privacy"))
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "li",
                        [
                          _c(
                            "b-link",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.$router.push({ path: "/ip" })
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("home.footer.ip")))]
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }