var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        { staticClass: "pt-3" },
        [
          _c(
            "b-modal",
            {
              attrs: {
                id: "delete-modal",
                title: _vm.$t("actions.delete") + " " + _vm.team.name
              }
            },
            [
              _vm._v(" " + _vm._s(_vm.$t("message.teamDelete")) + " "),
              _c("p", [_vm._v(_vm._s(_vm.$t("message.teamDeleteConfirm")))]),
              _c("b-form-input", {
                attrs: { placeholder: "Confirm Here" },
                model: {
                  value: _vm.confirmText,
                  callback: function($$v) {
                    _vm.confirmText = $$v
                  },
                  expression: "confirmText"
                }
              }),
              _c(
                "div",
                { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "m-1",
                      attrs: { variant: "danger", disabled: !_vm.confirmation },
                      on: {
                        click: function($event) {
                          return _vm.deleteTeam()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("answers.yes")))]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "m-1",
                      attrs: { variant: "primary" },
                      on: {
                        click: function($event) {
                          _vm.$bvModal.hide("delete-modal")
                          _vm.confirmText = ""
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("answers.no")))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("b-col", { staticClass: "team-nav", attrs: { cols: "12" } }, [
            _c(
              "div",
              {
                staticClass:
                  "d-flex pl-3 justify-content-between align-items-center"
              },
              [
                _c("h1", [_vm._v(_vm._s(_vm.team.name))]),
                _c(
                  "div",
                  {
                    staticClass: "btn btn-danger",
                    on: {
                      click: function($event) {
                        return _vm.$bvModal.show("delete-modal")
                      }
                    }
                  },
                  [_c("a", [_vm._v(_vm._s(_vm.$t("buttons.delete")))])]
                )
              ]
            )
          ]),
          _c(
            "b-col",
            { staticClass: "h-100 team-main", attrs: { cols: "12" } },
            [
              _vm.errors.length > 0
                ? _c("div", { staticClass: "mt-2 mb-2 alert alert-danger" }, [
                    _c(
                      "ul",
                      _vm._l(_vm.errors, function(error, index) {
                        return _c("li", { key: index }, [
                          _vm._v(" " + _vm._s(error) + " ")
                        ])
                      }),
                      0
                    )
                  ])
                : _vm._e(),
              _c(
                "transition",
                { attrs: { name: "fade" } },
                [
                  _c("team-view", {
                    attrs: {
                      teamId: _vm.team.teamId,
                      showDisplays: true,
                      showCampaigns: true
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }