var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        [
          _c(
            "b-card-text",
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("h4", [
                      _vm._v(_vm._s(_vm.$t("dashboard.common.network")))
                    ])
                  ]),
                  _c("b-col", { staticClass: "text-right" }, [
                    _c("small", { staticClass: "text-muted" }, [
                      _vm._v(_vm._s(_vm.$t("dashboard.common.lastUpdated")))
                    ])
                  ])
                ],
                1
              ),
              _vm.displays.length > 0
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "mx-auto",
                          attrs: { cols: "12", xl: "8" }
                        },
                        [
                          _c(
                            "b-row",
                            {},
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "pb-0 mb-0",
                                  attrs: { cols: "4" }
                                },
                                [
                                  _c("chart", {
                                    attrs: {
                                      id: "online",
                                      height: "150",
                                      type: "radialBar",
                                      series: _vm.onlineSeries,
                                      options: _vm.onlineOptions
                                    }
                                  }),
                                  _vm.onlineDisplays
                                    ? _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            placement: "bottom",
                                            variant: "success",
                                            target: "online",
                                            triggers: "hover"
                                          }
                                        },
                                        _vm._l(_vm.onlineDisplays, function(d) {
                                          return _c(
                                            "div",
                                            {
                                              key: d.name,
                                              staticClass: "text-left"
                                            },
                                            [_c("b", [_vm._v(_vm._s(d.name))])]
                                          )
                                        }),
                                        0
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "pb-0 mb-0",
                                  attrs: { cols: "4" }
                                },
                                [
                                  _c("chart", {
                                    attrs: {
                                      id: "passive",
                                      height: "150",
                                      type: "radialBar",
                                      series: _vm.passiveSeries,
                                      options: _vm.passiveOptions
                                    }
                                  }),
                                  _vm.passiveDisplays
                                    ? _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            placement: "bottom",
                                            variant: "warning",
                                            target: "passive",
                                            triggers: "hover"
                                          }
                                        },
                                        _vm._l(_vm.passiveDisplays, function(
                                          d
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              key: d.name,
                                              staticClass: "text-left pb-1"
                                            },
                                            [
                                              _c("div", [
                                                _c("b", [
                                                  _vm._v(_vm._s(d.name))
                                                ])
                                              ]),
                                              _c("div", [
                                                _vm._v(_vm._s(d.lastConnected))
                                              ])
                                            ]
                                          )
                                        }),
                                        0
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "pb-0 mb-0",
                                  attrs: { cols: "4" }
                                },
                                [
                                  _c("chart", {
                                    attrs: {
                                      id: "offline",
                                      height: "150",
                                      type: "radialBar",
                                      series: _vm.offlineSeries,
                                      options: _vm.offlineOptions
                                    }
                                  }),
                                  _vm.offlineDisplays
                                    ? _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            placement: "bottom",
                                            variant: "danger",
                                            target: "offline",
                                            triggers: "hover"
                                          }
                                        },
                                        _vm._l(_vm.offlineDisplays, function(
                                          d
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              key: d.name,
                                              staticClass: "text-left pb-1"
                                            },
                                            [
                                              _c("div", [
                                                _c("b", [
                                                  _vm._v(_vm._s(d.name))
                                                ])
                                              ]),
                                              _c("div", [
                                                _vm._v(_vm._s(d.lastConnected))
                                              ])
                                            ]
                                          )
                                        }),
                                        0
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "m-0 p-0 text-center",
                          attrs: { cols: "12" }
                        },
                        [
                          _c("em", { staticClass: "text-muted" }, [
                            _vm._v("Hover for more information.")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _vm._v(_vm._s(_vm.$t("dashboard.common.noTeamDisplay")))
                      ])
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }