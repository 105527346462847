var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !this.media.every(function(m) {
        return this$1.cache[m.id]
      })
        ? [
            _vm._v(
              " Downloading (" +
                _vm._s(Object.keys(_vm.dl).length) +
                ") files: "
            ),
            _vm._l(_vm.dl, function(file, index) {
              return _c("div", { key: index }, [
                _vm._v(
                  " " +
                    _vm._s(file.name) +
                    " " +
                    _vm._s(_vm._f("percent")(file.progress)) +
                    " "
                )
              ])
            })
          ]
        : [
            _c("player-core", {
              attrs: {
                id: "core",
                background: "#000",
                hideControls: false,
                cache: _vm.computedCache,
                queue: _vm.computedMedia
              }
            })
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }