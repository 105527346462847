var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-modal",
        {
          attrs: { centered: "", id: "preview", size: "md", "hide-footer": "" }
        },
        [
          _c(
            "div",
            {
              staticClass: "p-2",
              style: { width: "100%", height: "30vh", resize: "both" }
            },
            [
              _c("player", {
                style: { width: "100%", height: "100%" },
                attrs: { media: _vm.computedMedia, showControls: true }
              })
            ],
            1
          )
        ]
      ),
      _vm.computedMobile
        ? _c(
            "b-col",
            { staticClass: "text-center m-3", attrs: { cols: "12" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "m-2",
                  attrs: { variant: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.isMediaLibrary(false)
                    }
                  }
                },
                [_vm._v("Playlist")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "m-2",
                  attrs: { variant: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.isMediaLibrary(true)
                    }
                  }
                },
                [_vm._v("Media Library")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.showLibrary
        ? _c(
            "b-col",
            {
              style: _vm.computedMediaStyle,
              attrs: { cols: "12", md: "6", id: "mediaCol" }
            },
            [
              _c(
                "b-modal",
                {
                  attrs: {
                    id: "upload",
                    title: _vm.$t("media.create"),
                    "no-close-on-backdrop": "",
                    "no-fade": "",
                    "hide-footer": ""
                  }
                },
                [
                  _c("new-media", {
                    attrs: { activeTeam: _vm.activeTeam },
                    on: {
                      updated: _vm.fetchMedia,
                      finished: function($event) {
                        return _vm.$bvModal.hide("upload")
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "mx-auto text-center p-0 m-0 mb-1 pb-1" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchComputed,
                            expression: "searchComputed"
                          }
                        ],
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t("actions.search")
                        },
                        domProps: { value: _vm.searchComputed },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.searchComputed = $event.target.value
                          }
                        }
                      }),
                      _c(
                        "b-button",
                        {
                          staticClass: "ml-4",
                          attrs: {
                            type: "button",
                            variant: "primary",
                            size: "sm"
                          },
                          on: {
                            click: function($event) {
                              return _vm.$bvModal.show("upload")
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("buttons.upload")))]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "media-index h-100 d-flex flex-wrap align-content-start border p-1"
                },
                [
                  _vm.loading
                    ? _c("div", { staticClass: "spinner" })
                    : _vm._l(_vm.filteredMedia, function(mediaItem) {
                        return _c(
                          "div",
                          {
                            key: mediaItem.mediaId,
                            staticClass: "w-25",
                            attrs: { overlay: "", id: mediaItem.mediaId },
                            on: {
                              click: function($event) {
                                return _vm.selectMedia(mediaItem)
                              }
                            }
                          },
                          [
                            _c(
                              "b-img",
                              {
                                staticClass: "media-item",
                                attrs: {
                                  "fluid-grow": "",
                                  center: "",
                                  src: _vm.getThumbnail(mediaItem.hash, "s"),
                                  title: _vm.makeTitle(mediaItem)
                                }
                              },
                              [
                                mediaItem.videoId
                                  ? _c("div", { staticClass: "duration" }, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("time")(mediaItem.duration)
                                          )
                                        )
                                      ])
                                    ])
                                  : _vm._e()
                              ]
                            )
                          ],
                          1
                        )
                      })
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _vm.showCampaignList
        ? _c(
            "b-col",
            { style: _vm.computedMediaStyle, attrs: { cols: "12", md: "6" } },
            [
              _c("h6", { staticClass: "text-center" }, [
                _vm._v("Drag the arrow icon or thumbnail to re-order.")
              ]),
              _vm.previewMedia.length == 0
                ? _c(
                    "b-list-group",
                    [
                      _c("b-list-group-item", { staticClass: "text-center" }, [
                        _vm._v(" No media added! try clicking on a thumbnail. ")
                      ])
                    ],
                    1
                  )
                : _c(
                    "b-list-group",
                    [
                      _c(
                        "draggable",
                        {
                          staticClass: "list-group",
                          attrs: {
                            tag: "ul",
                            list: _vm.previewMedia,
                            handle: ".handle",
                            "ghost-class": "ghost"
                          }
                        },
                        _vm._l(_vm.previewMedia, function(mediaItem, index) {
                          return _c(
                            "b-list-group-item",
                            { key: index, staticClass: "pr-2" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-items-center justify-content-between"
                                },
                                [
                                  _c("div", {}, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "text-muted text-center",
                                        attrs: { title: mediaItem.name }
                                      },
                                      [
                                        _c("small", [
                                          _vm._v(_vm._s(mediaItem.name))
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center justify-content-center mt-2"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {},
                                          [
                                            _c("b-img", {
                                              staticClass: "w-100 handle",
                                              attrs: {
                                                rounded: "",
                                                src: _vm.getThumbnail(
                                                  mediaItem.hash,
                                                  "s"
                                                ),
                                                alt: ""
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c("div", { staticClass: "ml-2" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex justify-content-around ml-2 mr-2"
                                            },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    "invalid-feedback":
                                                      "duration can't be nothing!",
                                                    state:
                                                      parseInt(
                                                        mediaItem.duration
                                                      ) > 0
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "b-input-group",
                                                    {
                                                      attrs: {
                                                        size: "sm",
                                                        prepend: "play for",
                                                        append: "secs"
                                                      }
                                                    },
                                                    [
                                                      _c("b-form-input", {
                                                        attrs: {
                                                          disabled: mediaItem.mimeType.includes(
                                                            "video"
                                                          ),
                                                          type: "text",
                                                          value:
                                                            mediaItem.duration,
                                                          placeholder:
                                                            "duration:"
                                                        },
                                                        on: {
                                                          input: function(
                                                            $event
                                                          ) {
                                                            return _vm.setDuration(
                                                              index,
                                                              $event
                                                            )
                                                          }
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-button",
                                                {
                                                  staticClass: "h-100 ml-2",
                                                  attrs: {
                                                    variant: "outline-danger",
                                                    size: "sm",
                                                    squared: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.deleteMedia(
                                                        index
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("Delete")]
                                              ),
                                              _c("b-icon", {
                                                staticClass:
                                                  "m-2 ml-4 p-1 handle",
                                                attrs: {
                                                  variant: "muted",
                                                  icon: "arrows-move",
                                                  scale: "4"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-center ml-2 mr-2"
                                            },
                                            [
                                              _c("hr", {
                                                staticClass: "mb-0 mt-2 p-0"
                                              }),
                                              _c(
                                                "small",
                                                { staticClass: "text-muted" },
                                                [_vm._v("file details:")]
                                              ),
                                              _c(
                                                "b-badge",
                                                {
                                                  staticClass: "m-1",
                                                  attrs: { variant: "light" }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(mediaItem.width) +
                                                      "x" +
                                                      _vm._s(mediaItem.height)
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "b-badge",
                                                {
                                                  staticClass: "m-1",
                                                  attrs: { variant: "light" }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("humanFileSize")(
                                                        mediaItem.size
                                                      )
                                                    )
                                                  )
                                                ]
                                              ),
                                              mediaItem.videoDuration
                                                ? _c(
                                                    "b-badge",
                                                    {
                                                      staticClass: "m-1",
                                                      attrs: {
                                                        variant: "light"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          mediaItem.videoDuration
                                                        ) + " seconds"
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ])
                                      ]
                                    )
                                  ])
                                ]
                              )
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
              _c(
                "div",
                { staticClass: "m-2" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "success",
                        disabled: _vm.computedInvalid
                      },
                      on: {
                        click: function($event) {
                          return _vm.save()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("buttons.saveCampaign")))]
                  ),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.preview",
                          modifiers: { preview: true }
                        }
                      ],
                      staticClass: "ml-2",
                      attrs: { variant: "danger" }
                    },
                    [_vm._v("Show Preview")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }