var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.stats.status != "offline"
    ? _c(
        "b-row",
        { staticClass: "h-100 pt-3" },
        [
          _c("b-col", { attrs: { sm: "12", md: "6" } }, [
            _c(
              "div",
              { staticClass: "mb-3" },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("preview", {
                          staticClass: "prev",
                          attrs: {
                            display: _vm.displayStats,
                            showLive: true,
                            refreshTime: _vm.refreshRate / 60
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "6" } },
            [
              _c(
                "b-row",
                { staticClass: "pt-2 pb-2 text-center" },
                [
                  _c("b-col", [
                    _c("h5", { staticClass: "text-center" }, [
                      _vm._v("Live Stats for " + _vm._s(_vm.display.name))
                    ])
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "border-right", attrs: { cols: "6" } },
                    [
                      _c(
                        "b-col",
                        [
                          _c("h5", [
                            _vm._v(_vm._s(_vm.$t("displays.diskUsage")))
                          ]),
                          _c(
                            "b-progress",
                            {
                              staticClass: "mb-3",
                              attrs: {
                                variant: "dark",
                                max: 100,
                                height: "1.5rem"
                              }
                            },
                            [
                              _c(
                                "b-progress-bar",
                                { attrs: { value: _vm.disk, animated: "" } },
                                [
                                  _c(
                                    "strong",
                                    { staticClass: "progress-label" },
                                    [_vm._v(_vm._s(_vm.disk) + "%")]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        [
                          _c("h5", [
                            _vm._v(_vm._s(_vm.$t("displays.memoryUsage")))
                          ]),
                          _c(
                            "b-progress",
                            {
                              staticClass: "mb-3",
                              attrs: {
                                variant: "dark",
                                max: 100,
                                height: "1.5rem"
                              }
                            },
                            [
                              _c(
                                "b-progress-bar",
                                { attrs: { value: _vm.memory, animated: "" } },
                                [
                                  _c(
                                    "strong",
                                    { staticClass: "progress-label" },
                                    [_vm._v(_vm._s(_vm.memory) + "%")]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "b-col",
                        [
                          _c("h5", [
                            _vm._v(_vm._s(_vm.$t("displays.wirelessSignal")))
                          ]),
                          _vm.stats.signal == 0
                            ? [
                                _c(
                                  "b-progress",
                                  {
                                    staticClass: "mb-3",
                                    attrs: {
                                      variant: "dark",
                                      max: 100,
                                      height: "1.5rem"
                                    }
                                  },
                                  [
                                    _c(
                                      "b-progress-bar",
                                      { attrs: { value: 100, animated: "" } },
                                      [
                                        _c(
                                          "strong",
                                          { staticClass: "progress-label" },
                                          [_vm._v("Plugged In")]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            : [
                                _c(
                                  "b-progress",
                                  {
                                    staticClass: "mb-3",
                                    attrs: {
                                      variant: "dark",
                                      max: 100,
                                      height: "1.5rem"
                                    }
                                  },
                                  [
                                    _c(
                                      "b-progress-bar",
                                      {
                                        attrs: {
                                          value: _vm.signal,
                                          animated: ""
                                        }
                                      },
                                      [
                                        _c(
                                          "strong",
                                          { staticClass: "progress-label" },
                                          [_vm._v(_vm._s(_vm.signal) + "%")]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                        ],
                        2
                      ),
                      _c(
                        "b-col",
                        [
                          _c("h5", [
                            _vm._v(_vm._s(_vm.$t("displays.CPULoad")))
                          ]),
                          _c(
                            "b-progress",
                            {
                              staticClass: "mb-1",
                              attrs: {
                                variant: "dark",
                                max: 100,
                                height: "1.5rem"
                              }
                            },
                            [
                              _c(
                                "b-progress-bar",
                                {
                                  attrs: {
                                    value: _vm.stats.load * 100,
                                    animated: ""
                                  }
                                },
                                [
                                  _c(
                                    "strong",
                                    { staticClass: "progress-label" },
                                    [
                                      _vm._v(
                                        _vm._s(parseInt(_vm.stats.load * 100)) +
                                          "%"
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "pt-1 ml-2 mr-2 border-top" },
                    [
                      _c("h5", [_vm._v(_vm._s(_vm.$t("Brightness")))]),
                      _c(
                        "b-progress",
                        {
                          staticClass: "mb-1",
                          attrs: { variant: "dark", max: 100, height: "1.5rem" }
                        },
                        [
                          _c(
                            "b-progress-bar",
                            {
                              attrs: {
                                value: _vm.stats.brightness * 100,
                                animated: ""
                              }
                            },
                            [
                              _c("strong", { staticClass: "progress-label" }, [
                                _vm._v(
                                  _vm._s(parseInt(_vm.stats.brightness * 100)) +
                                    "%"
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("b-col", { staticClass: "pt-1 ml-2 mr-2 border-top" }, [
                    _c("h5", [_vm._v(_vm._s(_vm.$t("Local Time")))]),
                    _c(
                      "div",
                      {},
                      [
                        _c("b-icon", {
                          attrs: { icon: "broadcast", animation: "fade" }
                        }),
                        _c("span", { staticClass: "dash-text pl-1" }, [
                          _vm._v(_vm._s(_vm.computedTime))
                        ])
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                {},
                [
                  _c(
                    "b-col",
                    { staticClass: "pt-2 mt-2 ml-2 mr-2 border-top" },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.advanced",
                              modifiers: { advanced: true }
                            }
                          ],
                          staticClass: "border-success text-success",
                          attrs: { variant: "light" }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "when-open" },
                            [
                              _c("b-icon", { attrs: { icon: "arrow-up" } }),
                              _vm._v(" Close Advanced")
                            ],
                            1
                          ),
                          _c(
                            "span",
                            { staticClass: "when-closed" },
                            [
                              _c("b-icon", { attrs: { icon: "arrow-down" } }),
                              _vm._v(" Open Advanced")
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-collapse",
            { attrs: { id: "advanced" } },
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-list-group",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "b-list-group-item",
                        { staticClass: "d-flex justify-content-between" },
                        [
                          _vm._v(" Display Name: "),
                          _c("span", { staticClass: "dash-text" }, [
                            _vm._v(_vm._s(_vm.display.name))
                          ])
                        ]
                      ),
                      _c(
                        "b-list-group-item",
                        { staticClass: "d-flex justify-content-between" },
                        [
                          _vm._v(" Uptime: "),
                          _c(
                            "h5",
                            [
                              _vm.stats.status != "offline"
                                ? _c(
                                    "b-badge",
                                    {
                                      attrs: {
                                        size: "lg",
                                        variant: "success",
                                        pill: ""
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.computedUptime) + " days"
                                      )
                                    ]
                                  )
                                : _c(
                                    "b-badge",
                                    { attrs: { variant: "danger", pill: "" } },
                                    [
                                      _vm._v(
                                        "Display Offline (" +
                                          _vm._s(_vm.display.lastConnected) +
                                          ")"
                                      )
                                    ]
                                  )
                            ],
                            1
                          )
                        ]
                      ),
                      _vm.stats.status != "offline"
                        ? _c(
                            "b-list-group-item",
                            { staticClass: "d-flex justify-content-between" },
                            [
                              _vm._v(" Platform: "),
                              _c("span", { staticClass: "dash-text" }, [
                                _vm.stats.platform.includes("Linux")
                                  ? _c("span", { attrs: { name: "linux" } }, [
                                      _vm._v("GNU/Linux")
                                    ])
                                  : _vm._e(),
                                _c("br")
                              ])
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "b-list-group-item",
                        { staticClass: "d-flex justify-content-between" },
                        [
                          _vm._v(" Hostname: "),
                          _c("span", { staticClass: "dash-text pl-1" }, [
                            _vm._v(
                              _vm._s(_vm.stats.hostname || _vm.display.acid)
                            )
                          ])
                        ]
                      ),
                      _c(
                        "b-list-group-item",
                        { staticClass: "d-flex justify-content-between" },
                        [
                          _vm._v(" " + _vm._s(_vm.$t("Client Version")) + ": "),
                          _c("span", { staticClass: "dash-text" }, [
                            _vm._v(_vm._s(_vm.stats.version))
                          ])
                        ]
                      ),
                      _c(
                        "b-list-group-item",
                        { staticClass: "d-flex justify-content-between" },
                        [
                          _vm._v(" " + _vm._s(_vm.$t("Hardware ID")) + ": "),
                          _c("span", { staticClass: "dash-text" }, [
                            _vm._v(
                              _vm._s(_vm.stats.hwid || _vm.display.hardwareId)
                            )
                          ])
                        ]
                      ),
                      _vm.stats.status != "offline"
                        ? _c(
                            "b-list-group-item",
                            { staticClass: "d-flex justify-content-between" },
                            [
                              _vm._v(" WAN IP: "),
                              _c("span", { staticClass: "dash-text" }, [
                                _vm._v(_vm._s(_vm.stats.wan_addr))
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm.stats.status != "offline"
                        ? _c(
                            "b-list-group-item",
                            { staticClass: "d-flex justify-content-between" },
                            [
                              _vm._v(" LAN IP: "),
                              _c("span", { staticClass: "dash-text" }, [
                                _vm._v(_vm._s(_vm.stats.lan_addr))
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm.stats.status != "offline"
                        ? _c(
                            "b-list-group-item",
                            { staticClass: "d-flex justify-content-between" },
                            [
                              _vm._v(" LED Port: "),
                              _vm.stats.ldetect
                                ? _c(
                                    "b-badge",
                                    { attrs: { variant: "success", pill: "" } },
                                    [_c("strong", [_vm._v("Detected")])]
                                  )
                                : _c(
                                    "b-badge",
                                    { attrs: { variant: "warning", pill: "" } },
                                    [_c("strong", [_vm._v("Not Detected")])]
                                  )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _c(
        "b-row",
        [
          _c(
            "b-alert",
            { staticClass: "m-4", attrs: { variant: "danger", show: "" } },
            [
              _c("h4", [
                _vm._v(
                  "Display Offline (last seen: " +
                    _vm._s(_vm.display.lastConnected) +
                    ")"
                )
              ])
            ]
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }