var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "newteam",
            title: _vm.$t("teams.newTeam"),
            size: "lg",
            "hide-footer": ""
          }
        },
        [
          _c("new-team", {
            on: {
              refresh: function($event) {
                return _vm.fetchTeams()
              }
            }
          })
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "header mt-3 pb-3 w-100", attrs: { id: "top-area" } },
        [
          _c("b-col", [
            _c("h2", [_vm._v(_vm._s(_vm.$t("teams.teams")))]),
            _c("p", { staticClass: "d-none d-md-block" }, [
              _vm._v(_vm._s(_vm.$t("tutorial.teamExample")))
            ])
          ]),
          _c(
            "b-col",
            { staticClass: "d-md-none" },
            [
              _c("multiselect", {
                attrs: {
                  options: _vm.filteredTeams,
                  "track-by": "teamId",
                  label: "name",
                  value: _vm.compSelected,
                  searchable: false,
                  "allow-empty": false
                },
                on: { select: _vm.selectTeam }
              })
            ],
            1
          ),
          _c("b-col", { staticClass: "d-md-none", attrs: { cols: "1" } }, [
            _c(
              "div",
              {
                on: {
                  click: function($event) {
                    return _vm.$bvModal.show("newteam")
                  }
                }
              },
              [
                _c("v-icon", {
                  attrs: { name: "plus", scale: "1.5", label: "New Team" }
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm.loading
        ? _c("b-row", { attrs: { id: "content-area" } }, [
            _c("div", { staticClass: "spinner" })
          ])
        : _c(
            "b-row",
            { attrs: { id: "content-area" } },
            [
              _c(
                "b-col",
                {
                  staticClass: "d-none d-md-block h-100 overflow list",
                  attrs: { md: "4", lg: "2" }
                },
                [
                  _c("b-form-input", {
                    staticClass: "search",
                    attrs: {
                      id: "search",
                      placeholder: _vm.$t("actions.search")
                    },
                    model: {
                      value: _vm.search,
                      callback: function($$v) {
                        _vm.search = $$v
                      },
                      expression: "search"
                    }
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "new-item item",
                      on: {
                        click: function($event) {
                          return _vm.$bvModal.show("newteam")
                        }
                      }
                    },
                    [
                      _c("v-icon", {
                        attrs: { name: "plus", scale: "1.5", label: "New Team" }
                      })
                    ],
                    1
                  ),
                  _vm._l(_vm.filteredTeams, function(team) {
                    return _c(
                      "div",
                      {
                        key: team.teamId,
                        staticClass: "pt-3 pb-3 item",
                        class: {
                          selected: _vm.compSelected.teamId === team.teamId
                        },
                        on: {
                          click: function($event) {
                            return _vm.selectTeam(team)
                          }
                        }
                      },
                      [
                        _vm._v(" " + _vm._s(team.name) + " "),
                        _c("b-badge", { attrs: { variant: "light" } }, [
                          _vm._v(_vm._s(team.users.data.length))
                        ]),
                        _c("div", { staticClass: "text-muted" }, [
                          _c("small", [_vm._v(_vm._s(team.description))])
                        ])
                      ],
                      1
                    )
                  })
                ],
                2
              ),
              _c(
                "b-col",
                {
                  staticClass: "h-100 overflow",
                  attrs: { cols: "12", md: "8", lg: "10" }
                },
                [
                  _c(
                    "transition",
                    { attrs: { name: "fade" } },
                    [
                      _vm.filteredTeams.length > 0 &&
                      _vm.$route.params.teamId &&
                      _vm.compSelected !== {}
                        ? _c("router-view", {
                            attrs: { team: _vm.compSelected },
                            on: { delete: _vm.deletePerformed }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }