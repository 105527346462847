var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h-100 dashboard" },
    [
      _c("br"),
      _c("h2", { staticClass: "header-title" }, [
        _vm._v(_vm._s(_vm.$t("admin.news")))
      ]),
      _c("dash", { attrs: { displays: _vm.displays } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }