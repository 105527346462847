var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-row",
            { staticClass: "p-2", attrs: { id: "top-area" } },
            [
              _c(
                "b-col",
                { staticClass: "head", attrs: { cols: "12" } },
                [
                  _c("h2", { staticClass: "d-none d-md-block" }, [
                    _vm._v(_vm._s(_vm.$t("media.media")))
                  ]),
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { md: "10" } }, [
                        _c("p", { staticClass: "d-none d-md-block" }, [
                          _vm._v(
                            _vm._s(_vm.$t("tutorial.mediaExample")) +
                              " " +
                              _vm._s(_vm.$t("tutorial.acceptedFileFormats"))
                          )
                        ])
                      ]),
                      _c(
                        "b-col",
                        { attrs: { md: "2" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                size: "lg",
                                block: "",
                                variant: "primary"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$bvModal.show("new-media-modal")
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("buttons.upload")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          attrs: {
                            xl: "4",
                            lg: "4",
                            md: "4",
                            sm: "12",
                            xs: "12"
                          }
                        },
                        [
                          _c(
                            "b-button-group",
                            { staticStyle: { width: "100% !important" } },
                            [
                              _c("b-form-input", {
                                staticClass: "searchbar",
                                attrs: {
                                  id: "searchbar",
                                  placeholder: _vm.$t("actions.search")
                                },
                                model: {
                                  value: _vm.debouncedSearch,
                                  callback: function($$v) {
                                    _vm.debouncedSearch = $$v
                                  },
                                  expression: "debouncedSearch"
                                }
                              }),
                              _c(
                                "b-dropdown",
                                {
                                  attrs: {
                                    variant: "outline-success",
                                    id: "mediatypeSort",
                                    text: _vm.type,
                                    "no-caret": ""
                                  }
                                },
                                [
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          _vm.resetView()
                                          _vm.type = "All"
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("buttons.all")))]
                                  ),
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          _vm.resetView()
                                          _vm.type = "Image"
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("buttons.image")))]
                                  ),
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          _vm.resetView()
                                          _vm.type = "Video"
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("buttons.video")))]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-dropdown",
                                {
                                  attrs: {
                                    variant: "outline-success",
                                    id: "mediaSort",
                                    text:
                                      _vm.button +
                                      (_vm.sortToggle === true ? "▲" : "▼"),
                                    "no-caret": ""
                                  }
                                },
                                [
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          _vm.resetView()
                                          _vm.button = "Date"
                                          _vm.sortToggle = !_vm.sortToggle
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("buttons.date")))]
                                  ),
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          _vm.resetView()
                                          _vm.button = "Name"
                                          _vm.sortToggle = !_vm.sortToggle
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("buttons.name")))]
                                  ),
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          _vm.resetView()
                                          _vm.button = "Size"
                                          _vm.sortToggle = !_vm.sortToggle
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("buttons.size")))]
                                  ),
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          _vm.resetView()
                                          _vm.button = "Dimensions"
                                          _vm.sortToggle = !_vm.sortToggle
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("buttons.dimensions"))
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          attrs: {
                            cols: "3",
                            xl: "2",
                            lg: "2",
                            md: "auto",
                            sm: "auto",
                            xs: "12"
                          }
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-outline-success",
                              staticStyle: { width: "100%" },
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  return _vm.toggleUsed()
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("buttons.used")) +
                                  " " +
                                  _vm._s(_vm.used === true ? "☑" : "☐") +
                                  " "
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            {
              staticClass: "p-0 items",
              attrs: { cols: "12", id: "content-area" },
              on: {
                scroll: function($event) {
                  return _vm.infinityScroll($event)
                }
              }
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.loading,
                      expression: "loading"
                    }
                  ],
                  staticClass: "loader"
                },
                [_c("div", { staticClass: "spinner" })]
              ),
              _vm._l(_vm.filteredMedia, function(item) {
                return _c(
                  "b-col",
                  {
                    key: item.mediaId,
                    staticClass: "pb-2 less-gutter",
                    attrs: { cols: _vm.viewportCols },
                    on: {
                      click: function($event) {
                        return _vm.select(item)
                      }
                    }
                  },
                  [
                    _c(
                      "b-card",
                      {
                        staticClass: "item-card",
                        attrs: {
                          "img-src": _vm.getThumbnail(item.hash),
                          "img-alt": "item.name",
                          overlay: true,
                          footer: item.name,
                          "footer-class": "cut-text",
                          "img-top": ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.showInfo(item)
                          }
                        }
                      },
                      [
                        _c("b-card-text", [
                          _c(
                            "div",
                            { staticClass: "float-right" },
                            [
                              item.campaign
                                ? _c(
                                    "b-badge",
                                    { attrs: { variant: "success" } },
                                    [_vm._v("In Use")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("b-badge", { attrs: { variant: "light" } }, [
                                _vm._v(
                                  _vm._s(item.width) +
                                    " x " +
                                    _vm._s(item.height)
                                )
                              ])
                            ],
                            1
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              })
            ],
            2
          ),
          _c(
            "b-modal",
            {
              attrs: {
                id: "delete-media-modal",
                title: _vm.$t("actions.delete"),
                size: "md"
              }
            },
            [
              _vm._v(" " + _vm._s(_vm.$t("message.mediaDelete")) + " "),
              _c(
                "div",
                { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "float-left",
                      attrs: { variant: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.$bvModal.hide("delete-media-modal")
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("answers.no")))]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "float-right ml-2",
                      attrs: { variant: "warning" },
                      on: {
                        click: function($event) {
                          return _vm.deleteMedia()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("answers.yes")))]
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "b-modal",
            {
              attrs: {
                id: "new-media-modal",
                title: _vm.$t("media.create"),
                size: "md",
                "hide-footer": ""
              }
            },
            [
              this.activeTeam.id !== 0
                ? _c("new-media", {
                    attrs: { activeTeam: _vm.activeTeam },
                    on: {
                      updated: function($event) {
                        return _vm.addMedia()
                      },
                      finished: function($event) {
                        return _vm.addMedia()
                      }
                    }
                  })
                : _c("div", [_vm._v("Please select a team first.")])
            ],
            1
          ),
          _c(
            "b-modal",
            {
              attrs: {
                id: "media-info",
                title: "Filename: " + _vm.selectedItem.name,
                size: "lg",
                centered: "",
                "hide-footer": ""
              }
            },
            [
              _c(
                "b-row",
                { staticClass: "item-details" },
                [
                  _c(
                    "b-col",
                    { staticClass: "row no-gutters align-content-start" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "mb-3 text-right",
                          attrs: { cols: "12" }
                        },
                        [
                          _vm.selectedItem.campaign
                            ? _c("em", { staticClass: "p-1" }, [
                                _vm._v(
                                  "(" +
                                    _vm._s(_vm.$t("message.inUseDelete")) +
                                    ")"
                                )
                              ])
                            : _vm._e(),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                variant: "danger",
                                disabled: _vm.selectedItem.campaign
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$bvModal.show("delete-media-modal")
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("buttons.delete")))]
                          ),
                          _c(
                            "b-card",
                            { staticClass: "mt-2" },
                            [
                              _c(
                                "b-card-text",
                                [
                                  _c("media-item", {
                                    attrs: { item: _vm.selectedItem }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { staticClass: "mb-3", attrs: { cols: "12" } },
                        [
                          _c(
                            "b-card",
                            { attrs: { header: _vm.$t("common.information") } },
                            [
                              _c(
                                "b-card-text",
                                [
                                  _c("b-row", [
                                    _c("span", { staticClass: "col-3" }, [
                                      _c("strong", [
                                        _vm._v(_vm._s(_vm.$t("common.name")))
                                      ])
                                    ]),
                                    _vm.selectedItem.string
                                      ? _c("span", { staticClass: "col-9" }, [
                                          _vm._v(
                                            _vm._s(_vm.selectedItem.string)
                                          )
                                        ])
                                      : _c("span", { staticClass: "col-9" }, [
                                          _vm._v(_vm._s(_vm.selectedItem.name))
                                        ])
                                  ]),
                                  _c("b-row", [
                                    _vm.selectedItem.mimeType
                                      ? _c("span", { staticClass: "col-3" }, [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(_vm.$t("media.mimeType"))
                                            )
                                          ])
                                        ])
                                      : _vm._e(),
                                    _vm.selectedItem.mimeType
                                      ? _c("span", { staticClass: "col-9" }, [
                                          _vm._v(
                                            _vm._s(_vm.selectedItem.mimeType)
                                          )
                                        ])
                                      : _vm._e()
                                  ]),
                                  _c("b-row", [
                                    _vm.selectedItem.mimeType
                                      ? _c("span", { staticClass: "col-3" }, [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(_vm.$t("common.team"))
                                            )
                                          ])
                                        ])
                                      : _vm._e(),
                                    _vm.selectedItem.mimeType
                                      ? _c("span", { staticClass: "col-9" }, [
                                          _vm._v(_vm._s(_vm.activeTeam.name))
                                        ])
                                      : _vm._e()
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { staticClass: "mb-3", attrs: { cols: "12", lg: "6" } },
                        [
                          _c(
                            "b-card",
                            { attrs: { header: _vm.$t("message.inUse") } },
                            [
                              _c("b-card-text", [
                                _vm.selectedItem.campaign
                                  ? _c(
                                      "div",
                                      _vm._l(
                                        _vm.selectedItem.campaign,
                                        function(campaign) {
                                          return _c(
                                            "ul",
                                            { key: campaign.id },
                                            [
                                              _c(
                                                "b-link",
                                                {
                                                  attrs: {
                                                    to: {
                                                      name: "campaign",
                                                      params: {
                                                        campaignId: campaign.id
                                                      }
                                                    }
                                                  }
                                                },
                                                [_vm._v(_vm._s(campaign.name))]
                                              )
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _c("div", [
                                      _vm._v(_vm._s(_vm.$t("message.notUsed")))
                                    ])
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }