var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.errors.length > 0
        ? _c(
            "div",
            _vm._l(_vm.errors, function(error, index) {
              return _c(
                "b-alert",
                { key: index, attrs: { show: "", variant: "danger" } },
                [_vm._v(" " + _vm._s(error) + " ")]
              )
            }),
            1
          )
        : _vm._e(),
      _c(
        "b-form-group",
        { attrs: { label: "Name", "label-for": "name" } },
        [
          _c("b-form-input", {
            attrs: { id: "name", placeholder: _vm.Name },
            model: {
              value: _vm.name,
              callback: function($$v) {
                _vm.name = $$v
              },
              expression: "name"
            }
          })
        ],
        1
      ),
      _c(
        "b-form-group",
        {
          staticClass: "pt-1",
          attrs: { label: "Credit Card", "label-for": "card" }
        },
        [_c("div", { staticClass: "form-control", attrs: { id: "card" } })]
      ),
      _c(
        "b-button",
        {
          staticClass: "mt-2",
          attrs: { variant: "warning" },
          on: { click: _vm.createToken }
        },
        [_vm._v(_vm._s(_vm.submitText))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }