var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "b-card",
            [
              _c(
                "b-card-text",
                [
                  _c("h4", [
                    _vm._v(_vm._s(_vm.$t("dashboard.links.quickLinks")))
                  ]),
                  _c(
                    "b-modal",
                    {
                      attrs: {
                        id: "newdisplay",
                        title: _vm.$t("displays.newDisplay"),
                        size: "lg",
                        "hide-footer": ""
                      }
                    },
                    [
                      _vm.activeTeam.id != 0
                        ? _c("new-display", {
                            attrs: { activeTeam: _vm.activeTeam },
                            on: { refreshDisplays: _vm.savedUpdate }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "b-modal",
                    {
                      attrs: {
                        id: "liveview",
                        size: "xl",
                        "no-fade": "",
                        "no-close-on-backdrop": "",
                        "hide-footer": "",
                        title: _vm.$t("Live View")
                      }
                    },
                    [
                      _c("live-view", {
                        attrs: {
                          displays: _vm.displays,
                          refreshTime: _vm.refreshTime
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-modal",
                    {
                      attrs: {
                        id: "upload",
                        "no-fade": "",
                        "no-close-on-backdrop": "",
                        "hide-footer": ""
                      }
                    },
                    [
                      _c("new-media", { attrs: { activeTeam: _vm.activeTeam } })
                    ],
                    1
                  ),
                  _c(
                    "b-modal",
                    {
                      attrs: {
                        id: "campaign",
                        "no-fade": "",
                        "no-close-on-backdrop": "",
                        "hide-footer": ""
                      }
                    },
                    [
                      _vm.newCampaign.errors.length > 0
                        ? _c(
                            "div",
                            _vm._l(_vm.newCampaign.errors, function(error) {
                              return _c(
                                "b-alert",
                                {
                                  key: error,
                                  attrs: { show: "", variant: "danger" }
                                },
                                [_vm._v(" " + _vm._s(error) + " ")]
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                      _vm.newCampaign.created
                        ? _c("campaign-editor", {
                            attrs: {
                              activeTeam: _vm.activeTeam,
                              show: _vm.showNewCampaign,
                              campaign: _vm.newCampaign.campaign
                            },
                            on: {
                              saved: function($event) {
                                return _vm.$bvModal.hide("campaign")
                              }
                            }
                          })
                        : _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              {
                                staticClass: "form-group col-12",
                                attrs: { id: "campaignInformation" }
                              },
                              [
                                _c("div", { staticClass: "form-group" }, [
                                  _c("label", { attrs: { for: "name" } }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("campaigns.campaignName"))
                                    )
                                  ]),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.newCampaign.campaign.name,
                                        expression: "newCampaign.campaign.name"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "name",
                                      placeholder: _vm.$t(
                                        "campaigns.campaignName"
                                      )
                                    },
                                    domProps: {
                                      value: _vm.newCampaign.campaign.name
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.newCampaign.campaign,
                                          "name",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary right",
                                    attrs: { type: "button" },
                                    on: { click: _vm.createCampaign }
                                  },
                                  [_vm._v("Create")]
                                )
                              ]
                            )
                          ])
                    ],
                    1
                  ),
                  _c(
                    "b-modal",
                    {
                      attrs: {
                        id: "display",
                        size: "lg",
                        "no-fade": "",
                        "no-close-on-backdrop": "",
                        "hide-footer": ""
                      }
                    },
                    [
                      _c("router-view", {
                        attrs: {
                          activeTeam: _vm.activeTeam,
                          name: _vm.page.name,
                          page: _vm.page,
                          display: _vm.display
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName: "v-b-modal.liveview",
                              modifiers: { liveview: true }
                            }
                          ],
                          staticClass: "m-1 border-danger text-danger",
                          attrs: { variant: "light" }
                        },
                        [
                          _c(
                            "span",
                            [
                              _c("b-icon", {
                                attrs: { icon: "grid-3x3-gap-fill" }
                              }),
                              _vm._v(
                                " " + _vm._s(_vm.$t("dashboard.links.liveView"))
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName: "v-b-modal.upload",
                              modifiers: { upload: true }
                            }
                          ],
                          staticClass: "m-1 border-primary text-primary",
                          attrs: { variant: "light" }
                        },
                        [
                          _c(
                            "span",
                            [
                              _c("b-icon", { attrs: { icon: "upload" } }),
                              _vm._v(
                                " " + _vm._s(_vm.$t("dashboard.links.upload"))
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName: "v-b-modal.campaign",
                              modifiers: { campaign: true }
                            }
                          ],
                          staticClass: "m-1 border-success text-success",
                          attrs: { variant: "light" }
                        },
                        [
                          _c(
                            "span",
                            [
                              _c("b-icon", { attrs: { icon: "film" } }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("dashboard.links.newCampaign"))
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "m-1 border-info text-info",
                          attrs: { variant: "light" },
                          on: { click: _vm.newDisplay }
                        },
                        [
                          _c(
                            "span",
                            [
                              _c("b-icon", { attrs: { icon: "display" } }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("dashboard.links.newDisplay"))
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }