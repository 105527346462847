import { render, staticRenderFns } from "./Flatpickr.vue?vue&type=template&id=3aab96d0&scoped=true&"
import script from "./Flatpickr.vue?vue&type=script&lang=js&"
export * from "./Flatpickr.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3aab96d0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/srv/staging_vue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3aab96d0')) {
      api.createRecord('3aab96d0', component.options)
    } else {
      api.reload('3aab96d0', component.options)
    }
    module.hot.accept("./Flatpickr.vue?vue&type=template&id=3aab96d0&scoped=true&", function () {
      api.rerender('3aab96d0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Flatpickr.vue"
export default component.exports