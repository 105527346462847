var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        { staticClass: "header mt-3 pb-3 w-100", attrs: { id: "top-area" } },
        [
          _c("b-col", [
            _c("h2", [_vm._v(_vm._s(_vm.$t("user.users")))]),
            _c("p", { staticClass: "d-none d-md-block" }, [
              _vm._v(_vm._s(_vm.$t("tutorial.usermanagementExample")))
            ])
          ]),
          _c(
            "b-col",
            { staticClass: "d-md-none" },
            [
              _c("multiselect", {
                attrs: {
                  options: _vm.filteredUsers,
                  "track-by": "userId",
                  label: "username",
                  value: _vm.compSelected,
                  searchable: false,
                  "allow-empty": false
                },
                on: { select: _vm.selectUser }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.loading
        ? _c("b-row", { attrs: { id: "content-area" } }, [
            _c("div", { staticClass: "spinner" })
          ])
        : _c(
            "b-row",
            { attrs: { id: "content-area" } },
            [
              _c(
                "b-col",
                {
                  staticClass: "d-none d-md-block h-100 overflow list",
                  attrs: { md: "4", lg: "2" }
                },
                [
                  _c("b-form-input", {
                    staticClass: "search",
                    attrs: {
                      id: "search",
                      placeholder: _vm.$t("actions.search")
                    },
                    model: {
                      value: _vm.search,
                      callback: function($$v) {
                        _vm.search = $$v
                      },
                      expression: "search"
                    }
                  }),
                  _vm._l(_vm.filteredUsers, function(user) {
                    return _c(
                      "div",
                      {
                        key: user.userId,
                        staticClass: "pb-3 pt-3 item",
                        class: {
                          selected: _vm.compSelected.userId === user.userId
                        },
                        on: {
                          click: function($event) {
                            return _vm.selectUser(user)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(user.username) + " ")]
                    )
                  })
                ],
                2
              ),
              _c(
                "b-col",
                {
                  staticClass: "h-100 overflow",
                  attrs: { cols: "12", md: "8", lg: "10" }
                },
                [
                  _c(
                    "transition",
                    { attrs: { name: "fade" } },
                    [
                      _vm.filteredUsers.length > 0 &&
                      _vm.$route.params.userId &&
                      _vm.compSelected !== {}
                        ? _c("router-view", {
                            attrs: { user: _vm.compSelected },
                            on: {
                              delete: _vm.fetchUsers,
                              refresh: _vm.fetchUsers
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }