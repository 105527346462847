var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.errors.length > 0
        ? _c("div", { staticClass: "alert alert-danger" }, [
            _c(
              "ul",
              _vm._l(_vm.errors, function(error, index) {
                return _c("li", { key: index }, [
                  _vm._v(" " + _vm._s(error) + " ")
                ])
              }),
              0
            )
          ])
        : _vm._e(),
      !_vm.created
        ? _c(
            "div",
            {
              on: {
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.createTeam()
                }
              }
            },
            [
              _c("h5", [_vm._v(_vm._s(_vm.$t("common.information")))]),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t("common.name"),
                    "label-for": "teamName"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: { id: "teamName" },
                    model: {
                      value: _vm.team.name,
                      callback: function($$v) {
                        _vm.$set(_vm.team, "name", $$v)
                      },
                      expression: "team.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t("teams.slug"),
                    "label-for": "teamSlug"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: { disabled: "", id: "teamSlug" },
                    model: {
                      value: _vm.slug,
                      callback: function($$v) {
                        _vm.slug = $$v
                      },
                      expression: "slug"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t("teams.description"),
                    "label-for": "teamDescription"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: { id: "teamDescription" },
                    model: {
                      value: _vm.team.description,
                      callback: function($$v) {
                        _vm.$set(_vm.team, "description", $$v)
                      },
                      expression: "team.description"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-button",
                {
                  staticClass: "right mt-2",
                  attrs: { variant: "primary" },
                  on: { click: _vm.createTeam }
                },
                [_vm._v(_vm._s(_vm.$t("buttons.create")))]
              )
            ],
            1
          )
        : _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "text-center", attrs: { cols: "12" } },
                [
                  _c("h3", [
                    _vm._v(
                      _vm._s(_vm.team.name) +
                        " " +
                        _vm._s(_vm.$t("message.wasCreated"))
                    )
                  ]),
                  _c("hr")
                ]
              ),
              _c(
                "b-col",
                { staticClass: "text-center", attrs: { cols: "6" } },
                [
                  _c("h4", [_vm._v(_vm._s(_vm.$t("stripe.toConnect")))]),
                  _c("div", {}, [
                    _c("span", [_vm._v(_vm._s(_vm.$t("message.stripeAccept")))])
                  ]),
                  _c(
                    "a",
                    {
                      staticClass: "stripe-connect light-blue",
                      attrs: { href: _vm.stripeConnectLink }
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.$t("stripe.toConnect")))])]
                  )
                ]
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("h4", [_vm._v(_vm._s(_vm.$t("teams.addUsers")))]),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "align-items-center",
                          attrs: { cols: "12" }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "user",
                              placeholder: _vm.$t("user.usernameEmail")
                            },
                            model: {
                              value: _vm.user.user,
                              callback: function($$v) {
                                _vm.$set(_vm.user, "user", $$v)
                              },
                              expression: "user.user"
                            }
                          })
                        ],
                        1
                      ),
                      _c("b-col", { attrs: { cols: "12" } }, [
                        _c("label", [
                          _vm._v(_vm._s(_vm.$t("permissions.permissions")))
                        ])
                      ]),
                      _c(
                        "b-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { stacked: "" } },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: { id: "newowner" },
                                  model: {
                                    value: _vm.user.owner,
                                    callback: function($$v) {
                                      _vm.$set(_vm.user, "owner", $$v)
                                    },
                                    expression: "user.owner"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("permissions.owner")))]
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: { id: "newdisplay" },
                                  model: {
                                    value: _vm.user.display,
                                    callback: function($$v) {
                                      _vm.$set(_vm.user, "display", $$v)
                                    },
                                    expression: "user.display"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("permissions.display")))]
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: { id: "newpricing" },
                                  model: {
                                    value: _vm.user.pricing,
                                    callback: function($$v) {
                                      _vm.$set(_vm.user, "pricing", $$v)
                                    },
                                    expression: "user.pricing"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("permissions.pricing")))]
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: { id: "newcampaign" },
                                  model: {
                                    value: _vm.user.campaign,
                                    callback: function($$v) {
                                      _vm.$set(_vm.user, "campaign", $$v)
                                    },
                                    expression: "user.campaign"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("permissions.campaign")))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { stacked: "" } },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: { id: "newmedia" },
                                  model: {
                                    value: _vm.user.media,
                                    callback: function($$v) {
                                      _vm.$set(_vm.user, "media", $$v)
                                    },
                                    expression: "user.media"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("permissions.media")))]
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: { id: "newinvoice" },
                                  model: {
                                    value: _vm.user.invoice,
                                    callback: function($$v) {
                                      _vm.$set(_vm.user, "invoice", $$v)
                                    },
                                    expression: "user.invoice"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("permissions.invoice")))]
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: { id: "newthirdPartyKey" },
                                  model: {
                                    value: _vm.user.thirdPartyKey,
                                    callback: function($$v) {
                                      _vm.$set(_vm.user, "thirdPartyKey", $$v)
                                    },
                                    expression: "user.thirdPartyKey"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("permissions.thirdParty")) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: { id: "newteam" },
                                  model: {
                                    value: _vm.user.team,
                                    callback: function($$v) {
                                      _vm.$set(_vm.user, "team", $$v)
                                    },
                                    expression: "user.team"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("permissions.team")))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { staticClass: "pt-2", attrs: { cols: "12" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "right",
                              attrs: { variant: "primary" },
                              on: { click: _vm.addUser }
                            },
                            [_vm._v(_vm._s(_vm.$t("buttons.add")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("hr"),
                  _c(
                    "div",
                    [
                      _c("label", [_vm._v(_vm._s(_vm.$t("user.users")) + ":")]),
                      _c(
                        "b-list-group",
                        _vm._l(_vm.users, function(user) {
                          return _c("b-list-group-item", { key: user.userId }, [
                            _vm._v(_vm._s(user.user) + " "),
                            _c(
                              "span",
                              {
                                staticClass: "badge badge-default badge-danger",
                                on: {
                                  click: function($event) {
                                    return _vm.removeUser(
                                      user.teamId,
                                      user.userId
                                    )
                                  }
                                }
                              },
                              [_vm._v("✖")]
                            )
                          ])
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }