var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        { attrs: { id: "showedit", "hide-footer": "" } },
        [_c("edit-user", { attrs: { user: _vm.user } })],
        1
      ),
      _c(
        "b-card",
        { attrs: { header: _vm.$t("user.accountDetails") } },
        [
          _c(
            "b-card-text",
            [
              _vm.errors.length > 0
                ? _c("div", { staticClass: "alert alert-danger" }, [
                    _c(
                      "ul",
                      _vm._l(_vm.errors, function(error, index) {
                        return _c("li", { key: index }, [
                          _vm._v(" " + _vm._s(error) + " ")
                        ])
                      }),
                      0
                    )
                  ])
                : _vm._e(),
              _c("dl", { staticClass: "row" }, [
                _c("dt", { staticClass: "col-3" }, [
                  _vm._v(_vm._s(_vm.$t("user.username")))
                ]),
                _c("dd", { staticClass: "col-9" }, [
                  _vm._v(_vm._s(_vm.user.username))
                ]),
                _c("dt", { staticClass: "col-3" }, [
                  _vm._v(_vm._s(_vm.$t("user.email")))
                ]),
                _c("dd", { staticClass: "col-9" }, [
                  _vm._v(_vm._s(_vm.user.email))
                ]),
                _c("dt", { staticClass: "col-3" }, [
                  _vm._v(_vm._s(_vm.$t("user.emailVerified")))
                ]),
                _c("dd", { staticClass: "col-9" }, [
                  _vm._v(_vm._s(_vm.user.verified))
                ]),
                _c("dt", { staticClass: "col-3" }, [
                  _vm._v(_vm._s(_vm.$t("user.created")))
                ]),
                _c("dd", { staticClass: "col-9" }, [
                  _vm._v(_vm._s(_vm.user.createdOn))
                ]),
                _c("dt", { staticClass: "col-3" }, [
                  _vm._v(_vm._s(_vm.$t("user.lastLogin")))
                ]),
                _c("dd", { staticClass: "col-9" }, [
                  _vm._v(_vm._s(_vm.user.lastLogin))
                ]),
                _c("dt", { staticClass: "col-3" }, [
                  _vm._v(_vm._s(_vm.$t("user.lastModified")))
                ]),
                _c("dd", { staticClass: "col-9" }, [
                  _vm._v(_vm._s(_vm.user.lastModified))
                ])
              ]),
              _c(
                "b-button",
                {
                  attrs: { variant: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.$bvModal.show("showedit")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("buttons.update")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }