import { render, staticRenderFns } from "./Core.vue?vue&type=template&id=35ecddad&"
import script from "./Core.vue?vue&type=script&lang=js&"
export * from "./Core.vue?vue&type=script&lang=js&"
import style0 from "./Core.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/srv/staging_vue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('35ecddad')) {
      api.createRecord('35ecddad', component.options)
    } else {
      api.reload('35ecddad', component.options)
    }
    module.hot.accept("./Core.vue?vue&type=template&id=35ecddad&", function () {
      api.rerender('35ecddad', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Player/Core.vue"
export default component.exports