var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h4", [
        _vm._v(
          _vm._s(_vm.campaign.name) +
            " " +
            _vm._s(_vm.$t("article.on")) +
            " " +
            _vm._s(_vm.display.name) +
            " " +
            _vm._s(_vm.display.status)
        )
      ]),
      _c(
        "b-aspect",
        {
          staticClass: "mx-auto",
          staticStyle: { width: "50%" },
          attrs: { aspect: _vm.getAspect(_vm.campaign.media) }
        },
        [
          _c("campaign", {
            staticStyle: { height: "100%" },
            attrs: { media: _vm.campaign.media.data }
          })
        ],
        1
      ),
      _c(
        "b-table-simple",
        { attrs: { responsive: "" } },
        [
          _c(
            "b-thead",
            [
              _c(
                "b-tr",
                [
                  _c("b-th", [_vm._v(_vm._s(_vm.$t("dateTime.runDates")))]),
                  _c("b-th", [_vm._v(_vm._s(_vm.$t("dateTime.dailyTimes")))]),
                  _c("b-th", [
                    _vm._v(_vm._s(_vm.$t("dashboard.approval.status")))
                  ]),
                  _c("b-th")
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-tbody",
            _vm._l(_vm.filteredCampaigns, function(booking) {
              return _c(
                "b-tr",
                { key: booking.id },
                [
                  booking.startDate === booking.endDate
                    ? _c("b-td", [_vm._v(_vm._s(booking.startDate))])
                    : booking.endDate >= "2037-01-01"
                    ? _c("b-td", [
                        _vm._v(_vm._s(booking.startDate) + ", no end date")
                      ])
                    : _c("b-td", [
                        _vm._v(
                          _vm._s(booking.startDate) +
                            " to " +
                            _vm._s(booking.endDate)
                        )
                      ]),
                  booking.startTime === "00:00:00" &&
                  booking.endTime === "23:59:59"
                    ? _c("b-td", [_vm._v("All Day")])
                    : _c("b-td", [
                        _vm._v(
                          _vm._s(booking.startTime) +
                            " to " +
                            _vm._s(booking.endTime)
                        )
                      ]),
                  booking.approval === "pending"
                    ? _c(
                        "b-td",
                        [_c("b-badge", [_vm._v("Pending Approval")])],
                        1
                      )
                    : booking.approval === "approved" &&
                      booking.endDate >= _vm.date &&
                      booking.startDate <= _vm.date &&
                      booking.endTime >= _vm.time &&
                      booking.startTime <= _vm.time
                    ? _c(
                        "b-td",
                        [
                          _c("b-badge", { attrs: { variant: "success" } }, [
                            _vm._v("Running")
                          ])
                        ],
                        1
                      )
                    : booking.approval === "approved"
                    ? _c(
                        "b-td",
                        [
                          _c("b-badge", { attrs: { variant: "warning" } }, [
                            _vm._v("Booked")
                          ])
                        ],
                        1
                      )
                    : booking.approval === "denied"
                    ? _c(
                        "b-td",
                        [
                          _c("b-badge", { attrs: { variant: "danger" } }, [
                            _vm._v("Pending Denied")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-td",
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "danger" },
                          on: {
                            click: function($event) {
                              return _vm.removeCampaign(booking)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("buttons.remove")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "b-button",
        {
          attrs: { variant: "primary" },
          on: {
            click: function($event) {
              return _vm.$emit("add")
            }
          }
        },
        [_vm._v(_vm._s(_vm.$t("buttons.add")))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }