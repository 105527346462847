var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "video",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showing,
          expression: "showing"
        }
      ],
      ref: "video",
      staticClass: "video",
      style: _vm.styles
    },
    [_c("source", { attrs: { src: _vm.video.uri } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }