var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "h-100 w-100" }, [
    _c("div", { staticClass: "centered" }, [
      _vm.activated
        ? _c("h4", [
            _vm._v(" " + _vm._s(_vm.$t("user.accountActivated")) + " ")
          ])
        : _c("h4", [
            _vm._v(" " + _vm._s(_vm.$t("user.activationProblem")) + " ")
          ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }