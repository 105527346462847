var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-tabs",
    { attrs: { "content-class": "mt-3" } },
    [
      _c(
        "b-tab",
        {
          attrs: { title: "Overview", active: "" },
          on: {
            click: function() {
              return this$1.$router.push({
                name: "log",
                params: { booking: this$1.booking }
              })
            }
          }
        },
        [_c("router-view", { attrs: { booking: _vm.booking } })],
        1
      ),
      _c(
        "b-tab",
        { attrs: { title: "Complete Log" } },
        [
          _c(
            "b-button",
            {
              attrs: {
                variant: "primary",
                href: _vm.rootUrl + "/api/ext/log/" + _vm.booking.id
              }
            },
            [_vm._v("Download Playback Log (csv format) ")]
          )
        ],
        1
      ),
      _c(
        "b-tab",
        {
          attrs: { title: "Report" },
          on: {
            click: function() {
              return this$1.$router.push({
                name: "report",
                params: { booking: this$1.booking }
              })
            }
          }
        },
        [_c("router-view", { attrs: { booking: _vm.booking } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }