var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "mt-3" },
    [
      _c(
        "b-row",
        { staticClass: "mb-3" },
        [
          _c(
            "b-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c(
                "b-card",
                { attrs: { header: _vm.$t("common.information") } },
                [
                  _c("b-card-text", [
                    _c("dl", [
                      _c("dt", [_vm._v(_vm._s(_vm.$t("common.name")))]),
                      _c("dd", [_vm._v(_vm._s(_vm.display.name))]),
                      _c("dt", [_vm._v(_vm._s(_vm.$t("common.description")))]),
                      _c("dd", [_vm._v(_vm._s(_vm.display.description))]),
                      _c("dt", [_vm._v(_vm._s(_vm.$t("displays.type")))]),
                      _c("dd", [
                        _vm._v(_vm._s(_vm.$t(_vm.computedScreenType)))
                      ]),
                      _c("dt", [_vm._v(_vm._s(_vm.$t("displays.privacy")))]),
                      _c("dd", [
                        _vm.display.private == 1
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.$t("privacy.private")))
                            ])
                          : _c("span", [
                              _vm._v(_vm._s(_vm.$t("privacy.public")))
                            ])
                      ]),
                      _vm.display.private == 0
                        ? _c("dt", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("displays.publicUrl")) + " "
                            )
                          ])
                        : _vm._e(),
                      _vm.display.private == 0
                        ? _c("dd", [_vm._v(_vm._s(_vm.publicUrl))])
                        : _vm._e()
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c(
                "b-card",
                [
                  _c(
                    "b-card-text",
                    [
                      _c("display-gallery", {
                        attrs: { displayId: _vm.display.displayId }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-3" },
        [
          _c(
            "b-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c(
                "b-card",
                { attrs: { header: _vm.$t("common.dimensions") } },
                [
                  _c("b-card-text", [
                    _c("dl", [
                      _c("dt", [
                        _vm._v(_vm._s(_vm.$t("displays.physicalHeight")))
                      ]),
                      _c("dd", [_vm._v(_vm._s(_vm.display.physicalHeight))]),
                      _c("dt", [
                        _vm._v(_vm._s(_vm.$t("displays.physicalWidth")))
                      ]),
                      _c("dd", [_vm._v(_vm._s(_vm.display.physicalWidth))]),
                      _c("dt", [
                        _vm._v(_vm._s(_vm.$t("displays.pixelHeight")))
                      ]),
                      _c("dd", [_vm._v(_vm._s(_vm.display.pixelHeight))]),
                      _c("dt", [_vm._v(_vm._s(_vm.$t("displays.pixelWidth")))]),
                      _c("dd", [_vm._v(_vm._s(_vm.display.pixelWidth))]),
                      _c("dt", [_vm._v(_vm._s(_vm.$t("displays.rotation")))]),
                      _c("dd", [_vm._v(_vm._s(_vm.display.rotate))])
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c(
                "b-card",
                { attrs: { header: _vm.$t("displays.location") } },
                [
                  _c(
                    "b-card-text",
                    [
                      _c(
                        "b-table-simple",
                        { attrs: { small: "", borderless: "" } },
                        [
                          _c(
                            "b-tr",
                            [
                              _c("b-th", [
                                _vm._v(_vm._s(_vm.$t("displays.mobility")))
                              ]),
                              _c("b-th", [
                                _vm._v(_vm._s(_vm.$t("displays.coordinates")))
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-tr",
                            [
                              _c("b-td", [
                                _vm.display.mobile == 1
                                  ? _c("span", [
                                      _vm._v(_vm._s(_vm.$t("displays.mobile")))
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$t("displays.stationary"))
                                      )
                                    ])
                              ]),
                              _c(
                                "b-td",
                                [
                                  _vm._v(
                                    " Lat: " +
                                      _vm._s(_vm.display.latitude) +
                                      ", Lng: " +
                                      _vm._s(_vm.display.longitude) +
                                      " "
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { size: "sm", variant: "link" },
                                      on: { click: _vm.externalMap }
                                    },
                                    [_vm._v("Find on Google")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "map" },
                        [
                          _c(
                            "v-map",
                            {
                              attrs: {
                                zoom: 13,
                                center: [
                                  _vm.display.latitude,
                                  _vm.display.longitude
                                ]
                              }
                            },
                            [
                              _c("v-tilelayer", {
                                attrs: {
                                  options: { maxZoom: 13 },
                                  url: _vm.mapUrl
                                }
                              }),
                              _c("v-marker", {
                                attrs: {
                                  "lat-lng": [
                                    _vm.display.latitude,
                                    _vm.display.longitude
                                  ],
                                  icon: _vm.icon
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-3" },
        [
          _c(
            "b-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c(
                "b-card",
                { attrs: { header: _vm.$t("displays.timezoneCurrency") } },
                [
                  _c("b-card-text", [
                    _c("dl", [
                      _c("dt", [_vm._v(_vm._s(_vm.$t("displays.timezone")))]),
                      _c("dd", [_vm._v(_vm._s(_vm.display.timezone))]),
                      _c("dt", [_vm._v(_vm._s(_vm.$t("displays.currency")))]),
                      _c("dd", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("currency." + _vm.display.currency + "")
                          )
                        )
                      ])
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm.display.tags
            ? _c(
                "b-col",
                { attrs: { cols: "12", lg: "6" } },
                [
                  _c(
                    "b-card",
                    { attrs: { header: _vm.$t("common.tags") } },
                    [
                      _c("b-card-text", [
                        _c("dl", [
                          _c(
                            "dd",
                            _vm._l(_vm.display.tags.data, function(tagData) {
                              return _c("span", { key: tagData.id }, [
                                _vm._v(" " + _vm._s(tagData.tag) + " "),
                                _c("br")
                              ])
                            }),
                            0
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-3" },
        [
          _c(
            "b-col",
            { attrs: { cols: "12", lg: "12" } },
            [
              _c(
                "b-card",
                { attrs: { header: _vm.$t("displays.brightness") } },
                [
                  _c("b-card-text", [
                    _c("dl", [
                      _c("dt", [
                        _vm._v(_vm._s(_vm.$t("displays.brightnessControl")))
                      ]),
                      _c("dd", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "brightnessControl." +
                                  _vm.display.brightnessControl +
                                  ""
                              )
                            ) +
                            " "
                        )
                      ]),
                      _c(
                        "dt",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.display.brightnessControl === "scheduled",
                              expression:
                                "display.brightnessControl === 'scheduled'"
                            }
                          ]
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("displays.brightnessCurve")) +
                              " "
                          )
                        ]
                      ),
                      _vm.display.brightnessControl === "scheduled"
                        ? _c(
                            "dd",
                            [
                              _vm.display.brightnessControl === "scheduled"
                                ? _c("curve", {
                                    attrs: { inputs: false },
                                    model: {
                                      value: _vm.display.brightnessCurve,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.display,
                                          "brightnessCurve",
                                          $$v
                                        )
                                      },
                                      expression: "display.brightnessCurve"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-3" },
        [
          _c(
            "b-col",
            { attrs: { cols: "12", lg: "12" } },
            [
              _c(
                "b-card",
                { attrs: { header: _vm.$t("common.admin") } },
                [
                  _c("b-card-text", [
                    _c("dl", [
                      _c("dt", [_vm._v(_vm._s(_vm.$t("displays.acid")))]),
                      _c("dd", [
                        _c("a", { attrs: { href: _vm.computedAcid } }, [
                          _vm._v(_vm._s(_vm.display.acid))
                        ])
                      ]),
                      _c("dt", [_vm._v(_vm._s(_vm.$t("displays.hwid")))]),
                      _c("dd", [
                        _c("a", { attrs: { href: _vm.computedHwid } }, [
                          _vm._v(_vm._s(_vm.display.hardwareId))
                        ])
                      ]),
                      _c("dt", [_vm._v(_vm._s(_vm.$t("common.approval")))]),
                      _c("dd", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("approval." + _vm.display.approval + "")
                          )
                        )
                      ]),
                      _c("dt", [_vm._v(_vm._s(_vm.$t("displays.demoMode")))]),
                      _c("dd", [
                        _vm._v(
                          _vm._s(_vm.$t("truth." + _vm.display.demoMode + ""))
                        )
                      ])
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }