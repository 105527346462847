var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-td",
    {
      staticClass: "text-center",
      staticStyle: { "vertical-align": "middle" },
      on: { mouseover: _vm.mouseOver, mouseleave: _vm.mouseLeave }
    },
    [
      _vm.loaded
        ? [
            _vm.filteredBookings.length > 0
              ? [
                  _vm.content.cc.campaignId
                    ? [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              size: "sm",
                              variant: _vm.running ? "success" : "warning"
                            },
                            on: {
                              click: function($event) {
                                return _vm.$emit(
                                  "triggerModal",
                                  "manageCampaign",
                                  _vm.content.cc,
                                  _vm.content.dc
                                )
                              }
                            }
                          },
                          [
                            _vm._v("BOOKED "),
                            _vm.filteredBookings.length > 1
                              ? _c("b-badge", { attrs: { variant: "light" } }, [
                                  _vm._v(_vm._s(_vm.filteredBookings.length))
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    : [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              size: "sm",
                              variant: _vm.running ? "success" : "warning"
                            },
                            on: {
                              click: function($event) {
                                return _vm.$emit(
                                  "triggerModal",
                                  "manageSource",
                                  _vm.content.cc,
                                  _vm.content.dc
                                )
                              }
                            }
                          },
                          [
                            _vm._v("CONNECTED "),
                            _vm.filteredBookings.length > 1
                              ? _c("b-badge", { attrs: { variant: "light" } }, [
                                  _vm._v(_vm._s(_vm.filteredBookings.length))
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                ]
              : _vm.showButt
              ? [
                  _vm.content.cc.campaignId
                    ? [
                        _c(
                          "b-button",
                          {
                            attrs: { size: "sm" },
                            on: {
                              click: function($event) {
                                return _vm.$emit(
                                  "triggerModal",
                                  "addCampaign",
                                  _vm.content.cc,
                                  _vm.content.dc
                                )
                              }
                            }
                          },
                          [_vm._v("BOOK")]
                        )
                      ]
                    : [
                        _c(
                          "b-button",
                          {
                            attrs: { size: "sm" },
                            on: {
                              click: function($event) {
                                return _vm.$emit(
                                  "triggerModal",
                                  "addSource",
                                  _vm.content.cc,
                                  _vm.content.dc
                                )
                              }
                            }
                          },
                          [_vm._v("CONNECT")]
                        )
                      ]
                ]
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }