var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { staticClass: "text-center align-middle" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "m-0 p-1" },
                [
                  _c(
                    "b-link",
                    { attrs: { to: { name: "media", params: {} } } },
                    [
                      _c("b-icon", {
                        staticClass: "border rounded-circle p-2 bg-light",
                        attrs: {
                          variant: "dark",
                          icon: "image",
                          "font-scale": "3"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c("b-col", { staticClass: "m-0 p-1" }, [
                _c("b", [_vm._v(_vm._s(_vm.images.total))]),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$tc("dashboard.mediaStats.image", _vm.images.total)
                    ) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-col",
        { staticClass: "text-center align-middle" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "m-0 p-1" },
                [
                  _c(
                    "b-link",
                    { attrs: { to: { name: "media", params: {} } } },
                    [
                      _c("b-icon", {
                        staticClass: "border rounded-circle p-2 bg-light",
                        attrs: {
                          variant: "dark",
                          icon: "film",
                          "font-scale": "3"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c("b-col", { staticClass: "m-0 p-1" }, [
                _c("b", [_vm._v(_vm._s(_vm.videos.total))]),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$tc("dashboard.mediaStats.video", _vm.images.total)
                    ) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-col",
        { staticClass: "text-center align-middle" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "m-0 p-1" },
                [
                  _c("b-icon", {
                    staticClass: "border rounded-circle p-2 bg-light",
                    attrs: {
                      variant: "dark",
                      icon: "soundwave",
                      "font-scale": "3"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c("b-col", { staticClass: "m-0 p-1" }, [
                _c("b", [_vm._v(_vm._s(_vm.audio.total))]),
                _vm._v(" " + _vm._s(_vm.$t("dashboard.mediaStats.audio")) + " ")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-col",
        { staticClass: "text-center align-middle" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "m-0 p-1" },
                [
                  _c("b-icon", {
                    staticClass: "border rounded-circle p-2 bg-light",
                    attrs: { variant: "dark", icon: "hdd", "font-scale": "3" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c("b-col", { staticClass: "m-0 p-1" }, [
                _c("b", [
                  _vm._v(_vm._s(_vm._f("humanFileSize")(_vm.totalUsage)))
                ]),
                _vm._v(" " + _vm._s(_vm.$t("dashboard.mediaStats.used")) + " ")
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }