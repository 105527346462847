var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "deleteuser",
            size: "lg",
            title: _vm.$t("actions.delete")
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary left",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.deleteUser()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("answers.yes")))]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary right",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.$bvModal.hide("deleteuser")
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("answers.no")))]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [_vm._v(" " + _vm._s(_vm.$t("message.userDelete")) + " ")]
      ),
      _c("b-col", { staticClass: "user-nav", attrs: { cols: "12" } }, [
        _c(
          "div",
          { staticClass: "d-flex justify-content-between align-items-center" },
          [
            _c("h1", [_vm._v(_vm._s(_vm.user.username))]),
            _c(
              "div",
              [
                !_vm.user.verified
                  ? _c(
                      "b-button",
                      {
                        staticClass: "ml-2",
                        attrs: { variant: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.verify()
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("buttons.verify")) + " ")]
                    )
                  : _c(
                      "b-button",
                      {
                        staticClass: "ml-2",
                        attrs: { disabled: "", variant: "primary" }
                      },
                      [_vm._v("verified")]
                    ),
                _c(
                  "b-button",
                  {
                    staticClass: "ml-2",
                    attrs: { variant: "danger" },
                    on: {
                      click: function($event) {
                        return _vm.$bvModal.show("deleteuser")
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("actions.delete")) + " ")]
                )
              ],
              1
            )
          ]
        )
      ]),
      _c(
        "b-col",
        { staticClass: "h-100 user-main", attrs: { cols: "12" } },
        [
          _vm.errors.length > 0
            ? _c("div", { staticClass: "mt-2 mb-2 alert alert-danger" }, [
                _c(
                  "ul",
                  _vm._l(_vm.errors, function(error, index) {
                    return _c("li", { key: index }, [
                      _vm._v(" " + _vm._s(error) + " ")
                    ])
                  }),
                  0
                )
              ])
            : _vm._e(),
          _c(
            "transition",
            { attrs: { name: "fade" } },
            [_c("router-view", { attrs: { user: _vm.user } })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }