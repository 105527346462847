var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    [
      _c(
        "b-row",
        { staticClass: "pt-3" },
        [
          _c(
            "b-col",
            { staticClass: "mb-3", attrs: { cols: "6" } },
            [
              _c(
                "b-card",
                { attrs: { header: _vm.$t("common.information") } },
                [
                  _c(
                    "b-card-text",
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.$t("common.name"),
                            "label-for": "teamName"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: { id: "teamName", disabled: !_vm.hasAccess },
                            model: {
                              value: _vm.team.name,
                              callback: function($$v) {
                                _vm.$set(_vm.team, "name", $$v)
                              },
                              expression: "team.name"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.$t("teams.slug"),
                            "label-for": "teamSlug"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: { id: "teamSlug", disabled: !_vm.hasAccess },
                            model: {
                              value: _vm.team.abbrev,
                              callback: function($$v) {
                                _vm.$set(_vm.team, "abbrev", $$v)
                              },
                              expression: "team.abbrev"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.$t("teams.description"),
                            "label-for": "teamDescription"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "teamDescription",
                              disabled: !_vm.hasAccess
                            },
                            model: {
                              value: _vm.team.description,
                              callback: function($$v) {
                                _vm.$set(_vm.team, "description", $$v)
                              },
                              expression: "team.description"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.$t("teams.email"),
                            "label-for": "teamEmail",
                            description:
                              "Email address is required for teams with public displays."
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "teamEmail",
                              disabled: !_vm.hasAccess
                            },
                            model: {
                              value: _vm.team.email,
                              callback: function($$v) {
                                _vm.$set(_vm.team, "email", $$v)
                              },
                              expression: "team.email"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "pt-3" },
                        [
                          !_vm.team.stripeConnected &&
                          (_vm.$auth.user.admin ||
                            _vm.$auth.user.hasAccessInTeam(
                              this.team.teamId,
                              "owner"
                            ) ||
                            _vm.$auth.user.hasAccessInTeam(
                              this.team.teamId,
                              "thirdPartyKey"
                            ))
                            ? _c(
                                "a",
                                {
                                  staticClass: "stripe-connect light-blue",
                                  attrs: { href: _vm.stripeConnectLink }
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("stripe.toConnect")))
                                  ])
                                ]
                              )
                            : _c(
                                "b-button",
                                {
                                  attrs: { variant: "primary" },
                                  on: { click: _vm.fetchDashboard }
                                },
                                [_vm._v("Stripe Dashboard")]
                              )
                        ],
                        1
                      ),
                      _vm.hasAccess
                        ? _c(
                            "b-button",
                            {
                              staticClass: "float-right",
                              attrs: { variant: "primary" },
                              on: { click: _vm.updateTeam }
                            },
                            [_vm._v(_vm._s(_vm.$t("buttons.save")) + " ")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.hasAccess
            ? _c(
                "b-col",
                { staticClass: "mb-3", attrs: { cols: "6" } },
                [
                  _c(
                    "b-card",
                    { attrs: { header: _vm.$t("teams.addUser") } },
                    [
                      _c(
                        "b-card-text",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t("user.user"),
                                "label-for": "user",
                                description:
                                  "Please ensure the user already exists."
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "user",
                                  placeholder: _vm.$t("user.usernameEmail")
                                },
                                model: {
                                  value: _vm.user.user,
                                  callback: function($$v) {
                                    _vm.$set(_vm.user, "user", $$v)
                                  },
                                  expression: "user.user"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                inline: "",
                                label: _vm.$t("permissions.permissions")
                              }
                            },
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        { attrs: { stacked: "" } },
                                        [
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              attrs: { id: "owner" },
                                              model: {
                                                value: _vm.user.owner,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.user,
                                                    "owner",
                                                    $$v
                                                  )
                                                },
                                                expression: "user.owner"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("permissions.owner")
                                                )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              attrs: { id: "display" },
                                              model: {
                                                value: _vm.user.display,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.user,
                                                    "display",
                                                    $$v
                                                  )
                                                },
                                                expression: "user.display"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("permissions.display")
                                                )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              attrs: { id: "pricing" },
                                              model: {
                                                value: _vm.user.pricing,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.user,
                                                    "pricing",
                                                    $$v
                                                  )
                                                },
                                                expression: "user.pricing"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("permissions.pricing")
                                                )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              attrs: { id: "campaign" },
                                              model: {
                                                value: _vm.user.campaign,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.user,
                                                    "campaign",
                                                    $$v
                                                  )
                                                },
                                                expression: "user.campaign"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("permissions.campaign")
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        { attrs: { stacked: "" } },
                                        [
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              attrs: { id: "media" },
                                              model: {
                                                value: _vm.user.media,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.user,
                                                    "media",
                                                    $$v
                                                  )
                                                },
                                                expression: "user.media"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("permissions.media")
                                                )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              attrs: { id: "invoice" },
                                              model: {
                                                value: _vm.user.invoice,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.user,
                                                    "invoice",
                                                    $$v
                                                  )
                                                },
                                                expression: "user.invoice"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("permissions.invoice")
                                                )
                                              )
                                            ]
                                          ),
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              attrs: { id: "thirdPartyKey" },
                                              model: {
                                                value: _vm.user.thirdPartyKey,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.user,
                                                    "thirdPartyKey",
                                                    $$v
                                                  )
                                                },
                                                expression: "user.thirdPartyKey"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "permissions.thirdParty"
                                                  )
                                                ) + " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              attrs: { id: "team" },
                                              model: {
                                                value: _vm.user.team,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.user,
                                                    "team",
                                                    $$v
                                                  )
                                                },
                                                expression: "user.team"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("permissions.team")
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-button",
                            {
                              staticClass: "float-right pointer",
                              attrs: { variant: "primary" },
                              on: { click: _vm.addUser }
                            },
                            [_vm._v(_vm._s(_vm.$t("buttons.add")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-col",
            { staticClass: "mb-3", attrs: { cols: "12" } },
            [
              _c(
                "b-card",
                { attrs: { header: _vm.$t("user.users") } },
                [
                  _c(
                    "b-card-text",
                    [
                      _vm.hasAccess
                        ? _c(
                            "b-table-simple",
                            {
                              attrs: { striped: "", responsive: "", hover: "" }
                            },
                            [
                              _c(
                                "b-thead",
                                [
                                  _c(
                                    "b-tr",
                                    [
                                      _c("b-th"),
                                      _c("b-th", [
                                        _vm._v(
                                          _vm._s(_vm.$t("permissions.display"))
                                        )
                                      ]),
                                      _c("b-th", [
                                        _vm._v(
                                          _vm._s(_vm.$t("permissions.pricing"))
                                        )
                                      ]),
                                      _c("b-th", [
                                        _vm._v(
                                          _vm._s(_vm.$t("permissions.campaign"))
                                        )
                                      ]),
                                      _c("b-th", [
                                        _vm._v(
                                          _vm._s(_vm.$t("permissions.media"))
                                        )
                                      ]),
                                      _c("b-th", [
                                        _vm._v(
                                          _vm._s(_vm.$t("permissions.invoice"))
                                        )
                                      ]),
                                      _c("b-th", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("permissions.thirdParty")
                                          )
                                        )
                                      ]),
                                      _c("b-th", [
                                        _vm._v(
                                          _vm._s(_vm.$t("permissions.team"))
                                        )
                                      ]),
                                      _c("b-th", [
                                        _vm._v(
                                          _vm._s(_vm.$t("permissions.owner"))
                                        )
                                      ]),
                                      _c("b-th")
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-tbody",
                                _vm._l(_vm.users, function(user) {
                                  return _c(
                                    "b-tr",
                                    { key: user.userId },
                                    [
                                      _c("b-td", [_vm._v(_vm._s(user.user))]),
                                      _c("b-td", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                user.teamPermissions
                                                  .displayAccess,
                                              expression:
                                                "user.teamPermissions.displayAccess"
                                            }
                                          ],
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              user.teamPermissions.displayAccess
                                            )
                                              ? _vm._i(
                                                  user.teamPermissions
                                                    .displayAccess,
                                                  null
                                                ) > -1
                                              : user.teamPermissions
                                                  .displayAccess
                                          },
                                          on: {
                                            change: [
                                              function($event) {
                                                var $$a =
                                                    user.teamPermissions
                                                      .displayAccess,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        user.teamPermissions,
                                                        "displayAccess",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        user.teamPermissions,
                                                        "displayAccess",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    user.teamPermissions,
                                                    "displayAccess",
                                                    $$c
                                                  )
                                                }
                                              },
                                              function($event) {
                                                return _vm.updateUser(user)
                                              }
                                            ]
                                          }
                                        })
                                      ]),
                                      _c("b-td", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                user.teamPermissions
                                                  .pricingAccess,
                                              expression:
                                                "user.teamPermissions.pricingAccess"
                                            }
                                          ],
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              user.teamPermissions.pricingAccess
                                            )
                                              ? _vm._i(
                                                  user.teamPermissions
                                                    .pricingAccess,
                                                  null
                                                ) > -1
                                              : user.teamPermissions
                                                  .pricingAccess
                                          },
                                          on: {
                                            change: [
                                              function($event) {
                                                var $$a =
                                                    user.teamPermissions
                                                      .pricingAccess,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        user.teamPermissions,
                                                        "pricingAccess",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        user.teamPermissions,
                                                        "pricingAccess",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    user.teamPermissions,
                                                    "pricingAccess",
                                                    $$c
                                                  )
                                                }
                                              },
                                              function($event) {
                                                return _vm.updateUser(user)
                                              }
                                            ]
                                          }
                                        })
                                      ]),
                                      _c("b-td", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                user.teamPermissions
                                                  .campaignAccess,
                                              expression:
                                                "user.teamPermissions.campaignAccess"
                                            }
                                          ],
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              user.teamPermissions
                                                .campaignAccess
                                            )
                                              ? _vm._i(
                                                  user.teamPermissions
                                                    .campaignAccess,
                                                  null
                                                ) > -1
                                              : user.teamPermissions
                                                  .campaignAccess
                                          },
                                          on: {
                                            change: [
                                              function($event) {
                                                var $$a =
                                                    user.teamPermissions
                                                      .campaignAccess,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        user.teamPermissions,
                                                        "campaignAccess",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        user.teamPermissions,
                                                        "campaignAccess",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    user.teamPermissions,
                                                    "campaignAccess",
                                                    $$c
                                                  )
                                                }
                                              },
                                              function($event) {
                                                return _vm.updateUser(user)
                                              }
                                            ]
                                          }
                                        })
                                      ]),
                                      _c("b-td", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                user.teamPermissions
                                                  .mediaAccess,
                                              expression:
                                                "user.teamPermissions.mediaAccess"
                                            }
                                          ],
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              user.teamPermissions.mediaAccess
                                            )
                                              ? _vm._i(
                                                  user.teamPermissions
                                                    .mediaAccess,
                                                  null
                                                ) > -1
                                              : user.teamPermissions.mediaAccess
                                          },
                                          on: {
                                            change: [
                                              function($event) {
                                                var $$a =
                                                    user.teamPermissions
                                                      .mediaAccess,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        user.teamPermissions,
                                                        "mediaAccess",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        user.teamPermissions,
                                                        "mediaAccess",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    user.teamPermissions,
                                                    "mediaAccess",
                                                    $$c
                                                  )
                                                }
                                              },
                                              function($event) {
                                                return _vm.updateUser(user)
                                              }
                                            ]
                                          }
                                        })
                                      ]),
                                      _c("b-td", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                user.teamPermissions
                                                  .invoiceAccess,
                                              expression:
                                                "user.teamPermissions.invoiceAccess"
                                            }
                                          ],
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              user.teamPermissions.invoiceAccess
                                            )
                                              ? _vm._i(
                                                  user.teamPermissions
                                                    .invoiceAccess,
                                                  null
                                                ) > -1
                                              : user.teamPermissions
                                                  .invoiceAccess
                                          },
                                          on: {
                                            change: [
                                              function($event) {
                                                var $$a =
                                                    user.teamPermissions
                                                      .invoiceAccess,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        user.teamPermissions,
                                                        "invoiceAccess",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        user.teamPermissions,
                                                        "invoiceAccess",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    user.teamPermissions,
                                                    "invoiceAccess",
                                                    $$c
                                                  )
                                                }
                                              },
                                              function($event) {
                                                return _vm.updateUser(user)
                                              }
                                            ]
                                          }
                                        })
                                      ]),
                                      _c("b-td", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                user.teamPermissions
                                                  .thirdPartyKeyAccess,
                                              expression:
                                                "user.teamPermissions.thirdPartyKeyAccess"
                                            }
                                          ],
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              user.teamPermissions
                                                .thirdPartyKeyAccess
                                            )
                                              ? _vm._i(
                                                  user.teamPermissions
                                                    .thirdPartyKeyAccess,
                                                  null
                                                ) > -1
                                              : user.teamPermissions
                                                  .thirdPartyKeyAccess
                                          },
                                          on: {
                                            change: [
                                              function($event) {
                                                var $$a =
                                                    user.teamPermissions
                                                      .thirdPartyKeyAccess,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        user.teamPermissions,
                                                        "thirdPartyKeyAccess",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        user.teamPermissions,
                                                        "thirdPartyKeyAccess",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    user.teamPermissions,
                                                    "thirdPartyKeyAccess",
                                                    $$c
                                                  )
                                                }
                                              },
                                              function($event) {
                                                return _vm.updateUser(user)
                                              }
                                            ]
                                          }
                                        })
                                      ]),
                                      _c("b-td", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                user.teamPermissions.teamAccess,
                                              expression:
                                                "user.teamPermissions.teamAccess"
                                            }
                                          ],
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              user.teamPermissions.teamAccess
                                            )
                                              ? _vm._i(
                                                  user.teamPermissions
                                                    .teamAccess,
                                                  null
                                                ) > -1
                                              : user.teamPermissions.teamAccess
                                          },
                                          on: {
                                            change: [
                                              function($event) {
                                                var $$a =
                                                    user.teamPermissions
                                                      .teamAccess,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        user.teamPermissions,
                                                        "teamAccess",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        user.teamPermissions,
                                                        "teamAccess",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    user.teamPermissions,
                                                    "teamAccess",
                                                    $$c
                                                  )
                                                }
                                              },
                                              function($event) {
                                                return _vm.updateUser(user)
                                              }
                                            ]
                                          }
                                        })
                                      ]),
                                      _c("b-td", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: user.owner,
                                              expression: "user.owner"
                                            }
                                          ],
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(user.owner)
                                              ? _vm._i(user.owner, null) > -1
                                              : user.owner
                                          },
                                          on: {
                                            change: [
                                              function($event) {
                                                var $$a = user.owner,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        user,
                                                        "owner",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        user,
                                                        "owner",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(user, "owner", $$c)
                                                }
                                              },
                                              function($event) {
                                                return _vm.updateUser(user)
                                              }
                                            ]
                                          }
                                        })
                                      ]),
                                      _c("b-td", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "badge badge-default badge-danger",
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.removeUser(
                                                  _vm.teamId,
                                                  user.userId,
                                                  user.user
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("buttons.remove"))
                                            )
                                          ]
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        : _c(
                            "ul",
                            { staticClass: "list-group" },
                            _vm._l(_vm.users, function(user) {
                              return _c(
                                "li",
                                {
                                  key: user.userId,
                                  staticClass:
                                    "list-group-item list-group-item-action justify-content-between"
                                },
                                [_vm._v(" " + _vm._s(user.user) + " ")]
                              )
                            }),
                            0
                          )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.thirdPartyKeyAccess
            ? _c(
                "b-col",
                { staticClass: "mb-3", attrs: { cols: "12" } },
                [
                  _c(
                    "b-card",
                    { attrs: { header: _vm.$t("permissions.thirdParty") } },
                    [
                      _c(
                        "b-card-text",
                        [
                          _vm.keys.length > 0
                            ? _c(
                                "b-table-simple",
                                {
                                  attrs: {
                                    striped: "",
                                    responsive: "",
                                    hover: ""
                                  }
                                },
                                [
                                  _c(
                                    "b-thead",
                                    [
                                      _c(
                                        "b-tr",
                                        [
                                          _c("b-th", [
                                            _vm._v(
                                              _vm._s(_vm.$t("common.type"))
                                            )
                                          ]),
                                          _c("b-th", [
                                            _vm._v(_vm._s(_vm.$t("common.key")))
                                          ]),
                                          _c("b-th")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-tbody",
                                    _vm._l(_vm.keys, function(key) {
                                      return _c(
                                        "b-tr",
                                        { key: key.keyName },
                                        [
                                          _c("b-td", [
                                            _vm._v(_vm._s(key.keyName))
                                          ]),
                                          _c("b-td", [
                                            _vm._v(_vm._s(key.keyValue))
                                          ]),
                                          _c("b-td", [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "badge badge-default badge-danger",
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.deleteKey(
                                                      key.keyId
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("buttons.delete")
                                                  )
                                                )
                                              ]
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "b-form",
                            { attrs: { inline: "" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "mr-2",
                                  attrs: {
                                    label: _vm.$t("common.type"),
                                    "label-for": "keyType"
                                  }
                                },
                                [
                                  _c("b-form-select", {
                                    staticClass: "ml-2",
                                    attrs: {
                                      id: "keyType",
                                      options: _vm.keyTypes
                                    },
                                    model: {
                                      value: _vm.newKey.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.newKey, "name", $$v)
                                      },
                                      expression: "newKey.name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "mr-2",
                                  attrs: {
                                    label: _vm.$t("common.key"),
                                    "label-for": "key"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    staticClass: "ml-2",
                                    attrs: { id: "key" },
                                    model: {
                                      value: _vm.newKey.key,
                                      callback: function($$v) {
                                        _vm.$set(_vm.newKey, "key", $$v)
                                      },
                                      expression: "newKey.key"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-button",
                                {
                                  attrs: { variant: "primary" },
                                  on: { click: _vm.addKey }
                                },
                                [_vm._v(_vm._s(_vm.$t("buttons.addKey")))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.showDisplays
            ? _c(
                "b-col",
                { staticClass: "mb-3", attrs: { xs: "12", lg: "6" } },
                [
                  _c(
                    "b-card",
                    { attrs: { header: _vm.$t("displays.displays") } },
                    [
                      _c("b-card-text", [
                        _vm.displays.length > 0
                          ? _c(
                              "div",
                              _vm._l(_vm.displays, function(display) {
                                return _c(
                                  "div",
                                  { key: display.displayId },
                                  [
                                    _c(
                                      "b-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "display",
                                            params: {
                                              displayId: display.displayId
                                            }
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(display.name))]
                                    )
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          : _vm.team.displays
                          ? _c(
                              "div",
                              _vm._l(_vm.team.displays, function(display) {
                                return _c(
                                  "div",
                                  { key: display.displayId },
                                  [
                                    _c(
                                      "b-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "display",
                                            params: {
                                              displayId: display.displayId
                                            }
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(display.name))]
                                    )
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          : _c("div", [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("message.noDisplay")))
                              ])
                            ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.showCampaigns
            ? _c(
                "b-col",
                { staticClass: "mb-3", attrs: { xs: "12", lg: "6" } },
                [
                  _c(
                    "b-card",
                    { attrs: { header: _vm.$t("campaigns.campaigns") } },
                    [
                      _c("b-card-text", [
                        _vm.campaigns.length > 0
                          ? _c(
                              "div",
                              _vm._l(_vm.filteredCampaigns, function(campaign) {
                                return _c(
                                  "div",
                                  { key: campaign.campaignId },
                                  [
                                    _c(
                                      "b-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "campaign",
                                            params: {
                                              campaignId: campaign.campaignId
                                            }
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(campaign.name))]
                                    )
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          : _vm.team.campaigns
                          ? _c(
                              "div",
                              _vm._l(_vm.team.campaigns, function(campaign) {
                                return _c(
                                  "div",
                                  { key: campaign.campaignId },
                                  [
                                    _c(
                                      "b-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "campaign",
                                            params: {
                                              campaignId: campaign.campaignId
                                            }
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(campaign.name))]
                                    )
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          : _c("div", [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("message.noCampaigns")))
                              ])
                            ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }