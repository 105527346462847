var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _vm.errors.length > 0
        ? _c(
            "b-col",
            { staticClass: "alert alert-danger", attrs: { cols: "12" } },
            [
              _c(
                "ul",
                _vm._l(_vm.errors, function(error, index) {
                  return _c("li", { key: index }, [
                    _vm._v(" " + _vm._s(error) + " ")
                  ])
                }),
                0
              )
            ]
          )
        : _vm._e(),
      _c("b-col", { attrs: { cols: "12" } }, [
        _c("h4", [
          _vm._v(
            _vm._s(_vm.$t("common.schedule")) +
              " " +
              _vm._s(this.campaign.name) +
              " " +
              _vm._s(_vm.$t("article.on")) +
              " " +
              _vm._s(this.display.name) +
              " "
          )
        ])
      ]),
      _c(
        "b-col",
        { attrs: { md: "6" } },
        [
          _c(
            "b-card",
            { staticClass: "mb-3", attrs: { header: "Booking Duration" } },
            [
              _c(
                "b-card-text",
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.$t("dateTime.sDate"),
                        "label-for": "startDate"
                      }
                    },
                    [
                      _c("flatpickr", {
                        attrs: {
                          config: _vm.flatpickrConfig,
                          options: _vm.startDateOptions,
                          placeholder: _vm.$t("dateTime.date"),
                          id: "startDate"
                        },
                        model: {
                          value: _vm.bookingData.startDate,
                          callback: function($$v) {
                            _vm.$set(_vm.bookingData, "startDate", $$v)
                          },
                          expression: "bookingData.startDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.$t("dateTime.eDate"),
                        "label-for": "endDate"
                      }
                    },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("flatpickr", {
                            attrs: {
                              disabled: _vm.forever,
                              config: _vm.flatpickrConfig,
                              options: _vm.endDateOptions,
                              placeholder: _vm.$t("dateTime.date"),
                              id: "endDate"
                            },
                            model: {
                              value: _vm.bookingData.endDate,
                              callback: function($$v) {
                                _vm.$set(_vm.bookingData, "endDate", $$v)
                              },
                              expression: "bookingData.endDate"
                            }
                          }),
                          _c(
                            "b-input-group-append",
                            { attrs: { id: "foreverBox", "is-text": "" } },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: {
                                    id: "forever",
                                    name: "forever",
                                    value: true,
                                    "unchecked-value": false
                                  },
                                  model: {
                                    value: _vm.forever,
                                    callback: function($$v) {
                                      _vm.forever = $$v
                                    },
                                    expression: "forever"
                                  }
                                },
                                [_c("b-icon-arrow-repeat")],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-popover",
                    {
                      attrs: { target: "foreverBox", triggers: "hover focus" }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("popover.forever")) + " ")]
                  ),
                  _c("br"),
                  _c("p", { staticClass: "warning" }, [
                    _vm._v(_vm._s(_vm.dayOfWeekStatus))
                  ]),
                  _c(
                    "b-button-group",
                    { staticClass: "pb-3", attrs: { size: "sm" } },
                    _vm._l(_vm.buttons, function(btn, idx) {
                      return _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: btn.visible,
                              expression: "btn.visible"
                            }
                          ],
                          key: idx,
                          attrs: {
                            pressed: btn.state,
                            disabled: btn.disable,
                            variant: btn.variant
                          },
                          on: {
                            "update:pressed": function($event) {
                              return _vm.$set(btn, "state", $event)
                            },
                            "update:disabled": function($event) {
                              return _vm.$set(btn, "disable", $event)
                            },
                            mouseover: function($event) {
                              return _vm.hover(true, btn)
                            },
                            mouseleave: function($event) {
                              return _vm.hover(false, btn)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(btn.value) + " ")]
                      )
                    }),
                    1
                  ),
                  _c("p", [
                    _c("small", [
                      _vm._v(
                        _vm._s(_vm.dayCount ? _vm.dayCount : "No") +
                          " days selected."
                      )
                    ])
                  ]),
                  _c(
                    "b-form-group",
                    [
                      _vm._v(" " + _vm._s(_vm.$t("dateTime.weekSkip")) + " "),
                      _c(
                        "b-dropdown",
                        {
                          attrs: {
                            size: "sm",
                            variant: "outline-primary",
                            text: _vm.bookingData.skippedWeeks
                          }
                        },
                        [
                          _c(
                            "b-dropdown-item",
                            {
                              on: {
                                click: function($event) {
                                  _vm.bookingData.skippedWeeks = "Do Not Skip"
                                }
                              }
                            },
                            [_vm._v("Do Not Skip")]
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              on: {
                                click: function($event) {
                                  _vm.bookingData.skippedWeeks = "Second Week"
                                }
                              }
                            },
                            [_vm._v("Second Week")]
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              on: {
                                click: function($event) {
                                  _vm.bookingData.skippedWeeks = "Third Week"
                                }
                              }
                            },
                            [_vm._v("Third Week")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-col",
        { attrs: { md: "6" } },
        [
          _c(
            "b-card",
            { staticClass: "mb-3", attrs: { header: "Daily Run Times" } },
            [
              _c(
                "b-card-text",
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.$t("dateTime.sTime"),
                        "label-for": "startTime"
                      }
                    },
                    [
                      _c(
                        "b-dropdown",
                        {
                          ref: "startDropdown",
                          staticClass: "w-100 text-left",
                          attrs: {
                            "no-caret": "",
                            id: "startTime",
                            variant: "outline-secondary"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "button-content",
                              fn: function() {
                                return [
                                  _c("div", { staticClass: "text-left" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.bookingData.startTime) +
                                        " "
                                    )
                                  ])
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        _vm._l(4, function(n) {
                          return _c(
                            "b-button-group",
                            {
                              key: n.id,
                              staticClass: "ml-2 mr-2",
                              attrs: { size: "sm" }
                            },
                            _vm._l(6, function(i) {
                              return _c(
                                "b-button",
                                {
                                  key: i.id,
                                  staticClass: "timebutton",
                                  on: {
                                    click: function($event) {
                                      _vm.bookingData.startTime =
                                        ((n - 1) * 6 + (i - 1))
                                          .toString()
                                          .padStart(2, "0") + ":00:00"
                                      _vm.$refs.startDropdown.hide(true)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s((n - 1) * 6 + (i - 1)) + ":00"
                                  )
                                ]
                              )
                            }),
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.$t("dateTime.eTime"),
                        "label-for": "endTime"
                      }
                    },
                    [
                      _c(
                        "b-dropdown",
                        {
                          ref: "endDropdown",
                          staticClass: "w-100",
                          attrs: {
                            "no-caret": "",
                            id: "endTime",
                            variant: "outline-secondary"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "button-content",
                              fn: function() {
                                return [
                                  _c("div", { staticClass: "text-left" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.bookingData.endTime) +
                                        " "
                                    )
                                  ])
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        _vm._l(4, function(m) {
                          return _c(
                            "b-button-group",
                            {
                              key: m.id,
                              staticClass: "ml-2 mr-2",
                              attrs: { size: "sm" }
                            },
                            _vm._l(6, function(o) {
                              return _c(
                                "b-button",
                                {
                                  key: o.id,
                                  staticClass: "timebutton",
                                  on: {
                                    click: function($event) {
                                      _vm.bookingData.endTime =
                                        ((m - 1) * 6 + o)
                                          .toString()
                                          .padStart(2, "0") + ":00:00"
                                      _vm.$refs.endDropdown.hide(true)
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s((m - 1) * 6 + o) + ":00")]
                              )
                            }),
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-col",
        { staticClass: "pb-2", attrs: { cols: "12" } },
        [
          _c("b-card", { attrs: { header: "Display Utilization" } }, [
            _vm.timeRemaining !== -1
              ? _c("div", [
                  _vm.campaignDuration > _vm.display.blockTime
                    ? _c("div", [
                        _c("p", { staticClass: "text-danger" }, [
                          _vm._v(
                            "This campaign is too long for this display with its current settings, even when nothing is booked. "
                          )
                        ]),
                        _c("p", [
                          _c("small", [
                            _vm._v(
                              " Campaign Length: " +
                                _vm._s(_vm.campaignDuration) +
                                " sec "
                            ),
                            _c("br"),
                            _vm._v(
                              " Display Block Limit: " +
                                _vm._s(_vm.display.blockTime) +
                                " sec"
                            )
                          ])
                        ])
                      ])
                    : _c(
                        "div",
                        [
                          _c(
                            "b-progress",
                            {
                              attrs: {
                                max: _vm.display.blockTime,
                                height: "2rem"
                              }
                            },
                            [
                              _c(
                                "b-progress-bar",
                                {
                                  attrs: {
                                    variant: "primary",
                                    value:
                                      _vm.display.blockTime - _vm.timeRemaining
                                  }
                                },
                                [
                                  _c("span", [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.display.blockTime -
                                            _vm.timeRemaining
                                        ) + " sec used"
                                      )
                                    ])
                                  ])
                                ]
                              ),
                              _c(
                                "b-progress-bar",
                                {
                                  attrs: {
                                    animated: "",
                                    variant: "success",
                                    value:
                                      _vm.timeRemaining < _vm.campaignDuration
                                        ? 0
                                        : _vm.campaignDuration
                                  }
                                },
                                [
                                  _c("span", [
                                    _c("strong", [_vm._v("Booking")])
                                  ])
                                ]
                              ),
                              _c(
                                "b-progress-bar",
                                {
                                  attrs: {
                                    variant:
                                      _vm.timeRemaining < _vm.campaignDuration
                                        ? "danger"
                                        : "light",
                                    animated:
                                      _vm.timeRemaining < _vm.campaignDuration
                                        ? true
                                        : false,
                                    value:
                                      _vm.timeRemaining < _vm.campaignDuration
                                        ? _vm.timeRemaining
                                        : _vm.timeRemaining -
                                          _vm.campaignDuration
                                  }
                                },
                                [
                                  _vm.timeRemaining < _vm.campaignDuration
                                    ? _c("span", [
                                        _c("strong", [
                                          _vm._v("Insufficient Space")
                                        ])
                                      ])
                                    : _vm._e()
                                ]
                              )
                            ],
                            1
                          ),
                          _c("small", [
                            _c(
                              "span",
                              {
                                class: {
                                  "text-danger":
                                    _vm.timeRemaining < _vm.campaignDuration
                                }
                              },
                              [
                                _vm._v(
                                  " Total Available Time: " +
                                    _vm._s(_vm.timeRemaining) +
                                    "/" +
                                    _vm._s(_vm.display.blockTime) +
                                    " " +
                                    _vm._s(_vm.$t("dateTime.seconds")) +
                                    " "
                                )
                              ]
                            ),
                            _c("br"),
                            _c(
                              "span",
                              {
                                class: {
                                  "text-danger": _vm.campaignDuration <= 0
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("schedule.campaignDuration")
                                    ) +
                                    ": " +
                                    _vm._s(_vm.campaignDuration) +
                                    " " +
                                    _vm._s(_vm.$t("dateTime.seconds")) +
                                    " "
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      )
                ])
              : _c(
                  "div",
                  { staticClass: "d-flex justify-content-center mb-3" },
                  [_c("b-spinner", { attrs: { label: "Loading..." } })],
                  1
                )
          ])
        ],
        1
      ),
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          !_vm.selectDisabled
            ? _c(
                "b-button",
                {
                  staticClass: "float-left btn btn-primary",
                  attrs: { variant: "primary" },
                  on: { click: _vm.back }
                },
                [_vm._v(" " + _vm._s(_vm.$t("buttons.back")))]
              )
            : _vm._e(),
          _c(
            "b-button",
            {
              staticClass: "float-right btn btn-primary",
              attrs: {
                id: "schedule",
                variant: "primary",
                disabled: !_vm.schedulable
              },
              on: {
                click: function($event) {
                  return _vm.schedule()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("buttons.schedule")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }